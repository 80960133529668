import React from 'react'
import styled, {keyframes} from 'styled-components'
import PropTypes from 'prop-types'
import Select from 'components/form/Select'
import {Row, Cell, Column, Flex, Div} from 'components/layout/styled'
import DefaultInputStyle from 'components/form/DefaultInputStyle'
import withInputWrapper from 'components/form/withInputWrapper'

const Operator = styled(Column).attrs(() => ({alignItems: 'center', justifyContent: 'center'}))`
  height: 40px;
  width: 40px;
  border: 1px solid ${props => props.theme.colors.grey5};
  border-radius: 4px;
  font-size: 25px;
  line-height: 1;
  color: ${props => props.disabled ? props.theme.colors.grey6 : props.theme.colors.primary};
  font-weight: 700;
  box-sizing: content-box;
  cursor: pointer;
  background-color: ${props => props.disabled ? props.theme.colors.grey2 : props.theme.colors.white};
  ${props => props.disabled && 'pointer-events: none;'};
  user-select: none;
  &:hover {
    border: 1px solid ${props => props.theme.colors.grey6};
  }
`

const OperatorCharacter = styled.div`
  height: 30px;
  line-height: 28px;
  padding: 5px 1px;
  font-size: 18px;
`

const Tag = styled.div`
  height: 30px;
  line-height: 32px;
  background-color: ${props => props.notFound ? props.theme.colors.alertRed : props.theme.colors.grey7};
  color: ${props => props.theme.colors.white};
  font-size: 13px;
  padding: 0 15px;
  font-weight: 500;
  border-radius: 50px;
`

const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`

const Cursor = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${props => props.theme.colors.grey8};
  animation: ${blink} .75s step-end infinite;
`

const FormulaArea = styled(DefaultInputStyle).attrs(() => ({as: Column}))`
  min-height: 80px;
  margin-top: 10px;
  height: auto;
  cursor: text;
  ${Cursor} + ${Div} {
    padding-left: 4px !important;
  }
  ${Cursor} + ${OperatorCharacter} {
    padding-left: 0px !important;
  }
`

const Constant = React.forwardRef((props, ref) => (
  <Div ref={ref} p={5}>
    <Tag {...props} />
  </Div>
))

const halfBetween = (a, b) => Math.min(a, b) + ((Math.max(a, b) - Math.min(a, b)) / 2)

class FormulaInput extends React.Component {
  select
  area
  range
  characters = []

  state = {
    formula: this.props.input?.value || this.props.value || null,
    cursorPosition: 0,
    focused: false,
    error: false,
  }

  throwError = () => {
    this.setState({error: true},
      () => setTimeout(() => this.setState({error: false}), 200)
    )
  }

  addAtCaret = (val, callback) => {
    const formula = this.state.formula?.split(' ') || []
    if (val === 'R' && formula[this.state.cursorPosition - 1] !== 'O') {
      this.throwError()
      return
    }
    if (!this.state.formula) {
      formula.push(val)
    } else if (val.match(/^([0-9\\.])$/g) && formula[this.state.cursorPosition - 1]?.match(/^(\d*(\.\d*)?)$/)) {
      formula[this.state.cursorPosition - 1] = `${formula[this.state.cursorPosition - 1]}${val}`
    } else if (val === 'R' && formula[this.state.cursorPosition - 1] === 'O') {
      formula[this.state.cursorPosition - 1] = `${formula[this.state.cursorPosition - 1]}${val}`
      formula.splice(this.state.cursorPosition, 0, '(')
      formula.splice(this.state.cursorPosition + 1, 0, ',')
      formula.splice(this.state.cursorPosition + 2, 0, ')')
    } else {
      formula.splice(this.state.cursorPosition, 0, val)
    }
    this.setState({formula: formula.join(' ')}, callback)
  }

  renderOperators = (operators) => {
    return operators.map((operator, i) => (
      <Cell px={5} key={i}>
        <Operator
          disabled={this.props.disabled} onMouseDown={e => {
            if (this.props.disabled) return
            e.preventDefault()
            this.addAtCaret(operator)
          }}>
          {operator}
        </Operator>
      </Cell>
    ))
  }

  increaseCursorPosition = () => this.setState((state) => {
    if (state.cursorPosition < this.state.formula.split(' ').length) return ({cursorPosition: state.cursorPosition + 1})
  })

  decreaseCursorPosition = () => this.setState((state) => {
    if (state.cursorPosition > 0) return ({cursorPosition: state.cursorPosition - 1})
  })

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.input?.value !== this.props.input?.value) {
      if (!this.props.input.value.length) {
        this.setState({formula: null})
        return
      }
      this.setState({formula: this.props.input.value})
    }
    if (prevProps.value !== this.props.value) {
      if (!this.props.value.length) {
        this.setState({formula: null})
        return
      }
      this.setState({formula: this.props.value})
    }
    if (!prevState.focused && this.state.focused) {
      if (this.props.input) this.props.input.onFocus()
    }
    if (prevState.focused && !this.state.focused) {
      if (this.props.input) this.props.input.onBlur()
    }
    if (prevState.formula !== this.state.formula) {
      if (this.props.input) this.props.input.onChange(this.state.formula)
      if (this.props.additionalOnChange) this.props.additionalOnChange(this.state.formula)
    }
    if (prevState.formula?.split(' ').length < this.state.formula?.split(' ').length) {
      this.increaseCursorPosition()
    }
    if (!prevState.formula && this.state.formula) {
      this.increaseCursorPosition()
    }
  }

  calculateCursorPosition = ({clientX, clientY}) => {
    if (!this.state.formula?.split(' ').length) {
      this.setState({cursorPosition: 0})
      return
    }
    const points = []
    const rows = []
    let rowIndex = 0
    this.characters.forEach((character, index) => {
      const {right, top} = character.getBoundingClientRect()
      if (!rows.includes(top)) {
        rows.push(top)
        points.push([])
      }
      if (index === 0) points[rows.length - 1].push(character.getBoundingClientRect().left)
      points[rows.length - 1].push(right)
    })
    rows.forEach((rowTop, index) => {
      if (clientY > rowTop) {
        rowIndex = index
      }
    })
    if (!points.length) return
    const lastIndex = points[rowIndex].length - 1
    const pointIndex = clientX < halfBetween(points[rowIndex][lastIndex - 1], points[rowIndex][lastIndex])
      ? points[rowIndex].findIndex((point, i) => (halfBetween(point, points[rowIndex][i + 1])) >= clientX)
      : points[rowIndex].length - 1
    let count = pointIndex
    points.slice(0, rowIndex).forEach(row => row.forEach(() => count++))
    this.setState({cursorPosition: count})
  }

  deleteAtIndex = (index) => {
    if (!this.state.formula) return
    if (this.state.formula.split(' ').length === 1 && index === 0) {
      this.setState({formula: null}, () => {
        this.characters = []
        this.forceUpdate()
      })
      return
    }
    this.setState((state) => ({formula: state.formula.split(' ').filter((val, i) => i !== index).join(' ')}), () => {
      this.characters = []
      this.forceUpdate()
    })
  }

  handleArrowNavigation = (keyCode) => {
    if (keyCode === 37) this.decreaseCursorPosition()
    if (keyCode === 39) this.increaseCursorPosition()
  }

  handleBackspace = () => {
    if (this.state.formula.split(' ')[this.state.cursorPosition - 1].match(/^(\d*(\.\d*)?)$/)
      && this.state.formula.split(' ')[this.state.cursorPosition - 1].length > 1) {
      const formulaArray = this.state.formula.split(' ')
      const number = formulaArray[this.state.cursorPosition - 1]
      formulaArray.splice(this.state.cursorPosition - 1, 1, number.slice(0, number.length - 1))
      this.setState(state => ({formula: formulaArray.join(' ')}))
    } else {
      this.deleteAtIndex(this.state.cursorPosition - 1)
      this.decreaseCursorPosition()
    }
  }

  handleDelete = () => {
    this.deleteAtIndex(this.state.cursorPosition)
  }

  handleKeyDown = (e) => {
    const keyCode = e.which || e.keyCode
    if (keyCode >= 37 && keyCode <= 40) {
      e.preventDefault()
      this.handleArrowNavigation(keyCode)
      return
    }
    if (keyCode === 8) {
      e.preventDefault()
      this.handleBackspace()
      return
    }
    if (keyCode === 46) {
      e.preventDefault()
      this.handleDelete()
      return
    }
    if (e.key.match(/^([0-9\\.\\o\\r\\O\\R\\+\\-\\*\\/\\(\\)])$/g)) {
      e.preventDefault()
      this.addAtCaret(e.key.toUpperCase())
      return
    }
    if ([13, 16, 17, 18, 13, 20, 27, 46, 91, 224].includes(keyCode)) return
    this.throwError()
  }

  renderCursor = (index) => this.state.focused && this.state.cursorPosition === (index + 1) && <Cursor />

  render() {
    return (
      <Column>
        <Row mx={-5}>
          <Cell style={{flex: 1}} px={5}>
            <Select
              disabled={this.props.disabled}
              placeHolder="Select KPI"
              ref={node => { this.select = node }}
              items={this.props.items}
              additionalOnChange={value => value != null
                && this.addAtCaret(value, () => { this.select.select(); this.area.focus() })
              }
            />
          </Cell>
          {this.renderOperators(['+', '-', '*', '/', '(', ')'])}
        </Row>
        <Row>
          <Cell width={1} style={{position: 'relative'}}>
            <FormulaArea
              error={this.state.error || this.props.error}
              disabled={this.props.disabled}
              ref={node => { this.area = node }}
              tabIndex={this.props.disabled ? -1 : 0}
              onFocus={() => this.setState({focused: true})}
              onBlur={() => this.setState({focused: false})}
              active={this.state.focused}
              onClick={this.calculateCursorPosition}
              onKeyDown={this.handleKeyDown}
              py={10}
            >
              <Flex style={{minHeight: 40}} alignItems="center" flexWrap="wrap">
                {this.renderCursor(-1)}
                {this.state.formula?.split(' ').map((val, index) => {
                  const item = this.props.items?.find(item => item.id === val)
                  const otherKpi = this.props.allKpis?.find(kpi => kpi.id === val)
                  if (item) {
                    return <React.Fragment key={index}>
                      <Constant ref={node => { this.characters[index] = node }}>
                        {item.name}
                      </Constant>
                      {this.renderCursor(index)}
                    </React.Fragment>
                  } else if (['+', '-', '*', '/', '(', ')', 'O', 'OR', ','].includes(val)) {
                    return <React.Fragment key={index}>
                      <OperatorCharacter ref={node => { this.characters[index] = node }}>
                        {val}
                      </OperatorCharacter>
                      {this.renderCursor(index)}
                    </React.Fragment>
                  } else if (otherKpi) {
                    return <React.Fragment key={index}>
                      <Constant notFound ref={node => { this.characters[index] = node }}>
                        {otherKpi.name}
                      </Constant>
                      {this.renderCursor(index)}
                    </React.Fragment>
                  } else if (val.match(/^(\d*(\.\d*)?)$/)) {
                    return <React.Fragment key={index}>
                      <Constant ref={node => { this.characters[index] = node }}>
                        {val}
                      </Constant>
                      {this.renderCursor(index)}
                    </React.Fragment>
                  }
                })}
              </Flex>
            </FormulaArea>
          </Cell>
        </Row>
      </Column>
    )
  }
}

FormulaInput.propTypes = {
  items: PropTypes.array,
  allKpis: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  input: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  additionalOnChange: PropTypes.func,
}

export default withInputWrapper(FormulaInput)
