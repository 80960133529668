import React, {isValidElement} from 'react'
import PropTypes from 'prop-types'
import {Transition} from 'react-transition-group'
import {Form, Field} from 'react-final-form'
import styled, {css} from 'styled-components'
import {H3, Text, LinkText} from 'components/typography'
import {Column, Flex, Div} from 'components/layout/styled'
import CheckList from 'components/form/CheckList'
import Select from 'components/form/Select'
import Divider from 'components/layout/Divider'
import Value from 'components/formatters/Value'
import ArrowButton from 'components/buttons/ArrowButton'
import {PrimaryButton, WhiteButton} from 'components/buttons/Button'
import Dropdown from 'components/layout/Dropdown'
import {SearchInput} from 'components/form/Input'
import Loader from 'components/loaders/Loader'
import DatePickerField from 'components/form/datePicker/DatePickerField'
import SortIcon from 'static/svg/ico/ico_sort_arrow.svg'
import {rgba} from 'utils/colors'
import ThreeDotsButton from 'components/buttons/ThreeDotsButton'
import {omitProps} from 'utils/props'
import StickerIcon from 'static/svg/ico/tableSticker.svg'
import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'

const StyledStickerIcon = styled(StickerIcon)`
  position: absolute;
  top: -2px;
  left: -3px;
`

const IconHolder = styled.div`
  position: absolute;
  right: 0px;
  top: 10px;
  transform: translate(100%, 0);
  padding-left: 5px;
`

const StyledPngIcon = styled.div`
  // dont ever change this height and width unless talking to Fiko
  height: 17px;
  width: 17px;
  // dont ever change this height and width unless talking to Fiko
`

const StyledSortIcon = styled(omitProps(['ascending'], SortIcon))`
  height: 14px;
  margin-right: 5px;
  transform: rotate(${props => props.ascending ? 180 : 0}deg);
`

const DropdownHeadline = styled(Column).attrs(() => ({justifyContent: 'center'}))`
  cursor: pointer;
  height: 40px;
  position: relative;
  ${StyledPngIcon} {
     background: url('/static/rasterIcons/tab_default.gif');
  }
  &.filtered {
    ${StyledPngIcon} {
      background: url('/static/rasterIcons/tab_filter.gif');
    }
  }
  &.ascending {
    ${StyledPngIcon} {
      background: url('/static/rasterIcons/tab_sortup.gif');
    }
  }
  &.descending {
    ${StyledPngIcon} {
      background: url('/static/rasterIcons/tab_sortdown.gif');
    }
  }
  &.filtered.ascending {
    ${StyledPngIcon} {
      background: url('/static/rasterIcons/tab_filter_sortup.gif');
    }
  }
  &.filtered.descending {
    ${StyledPngIcon} {
      background: url('/static/rasterIcons/tab_filter_sortdown.gif');
    }
  }
  &:hover, &.active {
    ${H3} {
      font-weight: 500;
    }
    ${StyledPngIcon} {
       background: url('/static/rasterIcons/tab_default_hover.gif');
    }
    &.filtered {
      ${StyledPngIcon} {
        background: url('/static/rasterIcons/tab_filter_hover.gif');
      }
    }
    &.ascending {
      ${StyledPngIcon} {
        background: url('/static/rasterIcons/tab_sortup_hover.gif');
      }
    }
    &.descending {
      ${StyledPngIcon} {
        background: url('/static/rasterIcons/tab_sortdown_hover.gif');
      }
    }
    &.filtered.ascending {
    ${StyledPngIcon} {
      background: url('/static/rasterIcons/tab_filter_sortup_hover.gif');
    }
    }
    &.filtered.descending {
      ${StyledPngIcon} {
        background: url('/static/rasterIcons/tab_filter_sortdown_hover.gif');
      }
    }
  }
`

const DropdownContent = ({options, deletePopup}) => (
  <Form
    initialValues={{
      ...options.initialValues,
      checkList: options.initialValues.checkList?.reduce((acc, item) =>
        !acc.find(accItem => accItem === item) ? [...acc, item] : acc, []),
    }}
    subscription={{}}
    onSubmit={(values) => {
      options.onSubmit(values)
      deletePopup()
    }}
    render={({handleSubmit, form: {change}}) => (
      <form onSubmit={handleSubmit}>
        {options.ascendicity && <>
          <Field
            name="ascendicity"
            render={({input}) => (
              <Column my={15}>
                <LinkText
                  disableSelect
                  hoverColor="primary"
                  height="30px"
                  color={input.value === 'ascending' ? 'primary' : 'grey6'}
                  fontWeight={input.value === 'ascending' ? 700 : 400}
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    input.onChange('ascending')
                    handleSubmit()
                  }}
                >
                  <StyledSortIcon ascending />
                  <span>ascending</span>
                </LinkText>
                <LinkText
                  disableSelect
                  height="30px"
                  hoverColor="primary"
                  display="flex"
                  alignItems="center"
                  color={input.value === 'descending' ? 'primary' : 'grey6'}
                  fontWeight={input.value === 'descending' ? 700 : 400}
                  onClick={() => {
                    input.onChange('descending')
                    handleSubmit()
                  }}
                >
                  <StyledSortIcon />
                  <span>descending</span>
                </LinkText>
              </Column>
            )}
          />
          {(options.search || options.datePicker || options.items) && <Divider />}
        </>}
        {options.search && <>
          <Field
            name="search"
            component={SearchInput}
            placeholder="Search"
            small
            my={20}
          />
          <Divider />
        </>}
        {options.datePicker && <>
          <DatePickerField
            name="date_from"
            withWrapper
            label="Date from"
            small
            mt={20}
          />
          <DatePickerField
            name="date_to"
            withWrapper
            label="Date to"
            small
            mb={20}
          />
          <Divider />
        </>}
        {options.items && <>
          <Column style={{maxHeight: 250, overflow: 'auto'}}>
            <Field
              name="checkList"
              component={CheckList}
              items={options.items.reduce((acc, item) =>
                !acc.find(accItem => accItem.value === item.value) ? [...acc, item] : acc, [])}
              nowrap
              py={20}
            />
          </Column>
          <Divider />
        </>}
        {options.selectItems && <>
          <Field
            my={20}
            name="select"
            small
            label="Choose view"
            withWrapper
            component={Select}
            items={options.selectItems}
          />
          <Divider />
        </>}
        {(options.search || options.datePicker || options.items || options.selectItems)
        && <Flex py={20} justifyContent="flex-end">
          <WhiteButton
            small
            mr={10}
            type="button"
            onClick={() => {
              options.search && change('search', '')
              options.items && change('checkList', options.items.map(item => item.value))
              options.ascendicity && change('ascendicity', null)
              options.datePicker && change('date_from', null)
              options.datePicker && change('date_to', null)
              options.selectItems && change('select', options.defaultSelectValue)
              handleSubmit()
            }}
          >
            Reset
          </WhiteButton>
          <PrimaryButton small type="submit">Apply</PrimaryButton>
        </Flex>}
      </form>
    )}
  />
)

DropdownContent.propTypes = {
  options: PropTypes.object,
  deletePopup: PropTypes.func,
}

const ExpandablePart = styled.div`
  height: 0px;
  overflow: hidden;
`

const ExpandableTableRow = styled.tr`
  ${props => (props.state === 'entering' || props.state === 'entered') && css`
    ${ExpandablePart} {
      height: ${props => props.expandedBy}px;
    }
  `}
  ${ExpandablePart} {
    transition: height ${props => props.expandedBy / 2}ms ease;
  }
`

const BorderPart = styled.div`
  border-bottom: solid 1px ${props => props.theme.colors.grey4};
  border-left: solid 5px ${props => props.theme.colors.primary};
  box-shadow: inset 0 0 10px 0 ${props => rgba(props.theme.colors.grey5, 0.1)};
  ${props => props.alternateRows && css`border-top: solid 1px ${props => props.theme.colors.grey4};`}
`

const ExpandableCell = styled.td`
  padding: 0 !important;
  vertical-align: top;
  background-color: ${props => props.theme.colors.grey2};
`

class ExpandableRow extends React.Component {
  expandable = {}

  state = {
    expandedBy: 0,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.expandedBy !== prevState.expandedBy) {
      this.props.onUpdateExpanded(this.props.index, this.state.expandedBy)
    }
    if (this.props.isOpen && !isEqual(this.props.expandable, prevProps.expandable)) {
      if (this.state.expandedBy !== this.expandable.clientHeight + 1) {
        this.setState({expandedBy: this.expandable.clientHeight + 1})
      }
    }
  }

  render() {
    const {colSpan, expandable, isOpen, alternateRows} = this.props
    return (
      <Transition
        mountOnEnter
        unmountOnExit
        timeout={this.state.expandedBy / 2}
        onEntering={() => this.setState({expandedBy: this.expandable.clientHeight + 1})}
        onExited={() => this.setState({expandedBy: 0})}
        in={isOpen}
      >
        {(state) => (
          <ExpandableTableRow state={state} expandedBy={this.state.expandedBy}>
            <ExpandableCell colSpan={colSpan}>
              <ExpandablePart>
                <BorderPart
                  alternateRows={alternateRows}
                  ref={node => {
                    this.expandable = node
                  }}>
                  {expandable}
                </BorderPart>
              </ExpandablePart>
            </ExpandableCell>
          </ExpandableTableRow>
        )}
      </Transition>
    )
  }
}

ExpandableRow.propTypes = {
  alternateRows: PropTypes.bool,
  colSpan: PropTypes.number,
  expandable: PropTypes.any,
  isOpen: PropTypes.bool,
  onUpdateExpanded: PropTypes.func,
  index: PropTypes.number,
}

const TableCell = styled('td')`
  box-sizing: border-box;
  &:first-child {
    padding-left: 30px;
    position: sticky;
    z-index: 2;
    left: 0;
  }
  &:last-child {
    padding-right: 30px;
  }
`

const HeaderRow = styled.tr`
  ${TableCell} {
    &:first-child {
      z-index: 3;
    }
    z-index: 1;
    height: ${props => props.headerHeight}px;
    position: sticky;
    top: 0;
    background-color: ${props => props.theme.colors.grey1};
    border-bottom: solid 1px ${props => props.theme.colors.grey4};
    box-shadow: 0px 1px 0px 0px ${props => props.theme.colors.grey4};
  }
`

const StyledTable = styled(omitProps(['horizontallyScrolled'], 'table'))`
  border-collapse: collapse;
  min-width: 100%;
  ${TableCell} {
    &:first-child {
      ${props => props.horizontallyScrolled && css`
        box-shadow: 1px 0px 0px 0px ${props.theme.colors.grey4};
      `}
    }
    padding: 10px ${props => props.cellPadding}px;
  }
  ${HeaderRow} {
    ${TableCell} {
      &:first-child {
        z-index: 10;
        ${props => props.horizontallyScrolled && css`
          box-shadow: 1px 1px 0px 0px ${props.theme.colors.grey4};
        `}
      }
    }
  }
`

const FooterRow = styled.tr`
  ${TableCell} {
    height: 60px;
    background-color: ${props => props.theme.colors.grey1};
  }
`

const TableRow = styled.tr`
  ${props => props.onClick && css`
    &:hover {
      cursor: pointer;
       ${TableCell}:not(:first-child) {
          p {
            color: ${props.theme.colors.primary};
            font-weight: 700;
          }
       }
    }
  `}
  ${TableCell} {
    height: ${props => props.rowHeight}px;
    background-color: ${props => props.theme.colors[props.color] || props.theme.colors.white};
    border-bottom: solid 1px ${props => props.theme.colors.grey4};
    ${props => props.alternateRows ? css`
      ${!(props.footer && props.last) && css`
        border-bottom: none;
      `}
      ${props.odd && css`
        background-color: ${props => props.theme.colors.grey1};
      `}
    ` : css`
      @-moz-document url-prefix() {
        &:first-child {
          box-shadow: 0px -1px 0px 0px ${props => props.theme.colors.grey4};
        }
      }
      ${!props.footer && props.last && css`
        border-bottom: none;
      `}
    `}
  }
`

const Header = React.memo(({headerHeight, columns, disableUpperCase, hasExpandableRow}) => (
  <thead>
    <HeaderRow headerHeight={headerHeight}>
      {columns.map((value, i) =>
        <TableCell
          as="th"
          style={{
            textAlign: value.align || (i === 0 ? 'left' : 'right'),
            whiteSpace: value.noWrap ? 'nowrap' : 'normal',
            ...(value.paddingRight != null ? {paddingRight: value.paddingRight} : {}),
            ...(value.paddingLeft != null ? {paddingLeft: value.paddingLeft} : {}),
          }}
          key={typeof value === 'string' ? value : (value.key || value.value)}
        >
          {value.dropdownOptions
            ? <Dropdown
              watchOutsideClick
              offset={0}
              target={({createPopUp, deletePopUp, targetRef, isOpen}) => {
                const ordered = value.dropdownOptions.initialValues?.ascendicity
                const filtered = (value.dropdownOptions.initialValues.checkList?.length
                  !== value.dropdownOptions.items?.length)
                  || (value.dropdownOptions.initialValues.search?.length > 0)
                  || (value.dropdownOptions.initialValues.date_to)
                  || (value.dropdownOptions.initialValues.date_from)
                return (
                  <Flex justifyContent={(value.align === 'left' || i === 0) ? 'flex-stat' : 'flex-end'}>
                    <DropdownHeadline
                      className={`${isOpen ? 'active' : ''} ${filtered ? 'filtered' : ''} ${ordered || ''}`}
                      onClick={isOpen ? deletePopUp : createPopUp}
                      ref={targetRef}
                    >
                      {isValidElement(value.value)
                        ? value.value
                        : <>
                          <H3 color="primary" fontSize={10} upperCase={!disableUpperCase} fontWeight={ordered ? 500 : 400}>
                            {value.value}
                          </H3>
                          <H3
                            style={{opacity: 0, userSelect: 'none', pointerEvents: 'none', height: 0}}
                            fontSize={10}
                            upperCase={!disableUpperCase}
                            fontWeight={500}
                          >
                            {value.value}
                          </H3>
                        </>
                      }
                      <IconHolder>
                        <StyledPngIcon />
                      </IconHolder>
                    </DropdownHeadline>
                  </Flex>
                )
              }}
            >
              {({deletePopUp}) => (
                <Column onScroll={e => e.stopPropagation()} width={280} px={20} py={10}>
                  <DropdownContent deletePopup={deletePopUp} options={value.dropdownOptions} />
                </Column>
              )}
            </Dropdown>
            : isValidElement(value.value)
              ? value.value
              : <H3 fontWeight={400} color="primary" fontSize={10} upperCase={!disableUpperCase}>
                {typeof value === 'string' ? value : value.value}
              </H3>}
        </TableCell>,
      )}
      {hasExpandableRow && (
        <TableCell as="th" style={{paddingRight: 0, paddingLeft: 0, width: 1}} />
      )}
    </HeaderRow>
  </thead>
))

Header.propTypes = {
  headerHeight: PropTypes.number,
  columns: PropTypes.array,
  hasExpandableRow: PropTypes.bool,
  disableUpperCase: PropTypes.bool,
}

const CellContent = React.memo(({cell, first, footer}) => (
  <>
    <Text
      height={0}
      style={{...cell.style, color: 'transparent', userSelect: 'none', pointerEvents: 'none'}}
      narrow
      fontWeight={700}
    >
      <Value
        value={cell.value}
        type={cell.type}
        currency={cell.currency}
      />
    </Text>
    <Text
      narrow
      style={cell.style}
      fontWeight={(first || footer) ? 700 : 400}
    >
      <Value
        value={cell.value}
        type={cell.type}
        currency={cell.currency}
      />
    </Text>
  </>
))

CellContent.propTypes = {
  cell: PropTypes.object,
  first: PropTypes.bool,
  footer: PropTypes.bool,
}

const Row = React.memo(React.forwardRef((
  {
    row,
    index,
    alternateRows,
    isOpen,
    footer,
    rowHeight,
    isLast,
    horizontalLength,
    toggleOpen,
    onUpdateExpanded,
    siblingExpandable,
  },
  ref
) => (
  <>
    <TableRow
      ref={ref}
      odd={index % 2 !== 0}
      last={isLast}
      alternateRows={alternateRows}
      color={row.color}
      footer={footer}
      rowHeight={rowHeight}
      onClick={row.onClick}
    >
      {row.values.map?.((cell, i) => (
        <TableCell
          onClick={cell.onClick}
          style={{
            textAlign: cell.align || (i === 0 ? 'left' : 'right'),
            whiteSpace: cell.noWrap ? 'nowrap' : 'normal',
            cursor: cell.onClick ? 'pointer' : 'normal',
            ...(cell.paddingRight != null ? {paddingRight: cell.paddingRight} : {}),
            ...(cell.paddingLeft != null ? {paddingLeft: cell.paddingLeft} : {}),
            ...(cell.width != null ? {width: cell.width === 'string' ? cell.width : `${cell.width}px`} : {}),
          }}
          key={cell.key}
        >
          {isValidElement(cell.value)
            ? cell.value
            : <CellContent first={i === 0} cell={cell} />
          }
        </TableCell>
      ))}
      {row.expandable && (
        <TableCell style={{paddingRight: 10, paddingLeft: 0, width: 1}}>
          <ArrowButton
            onClick={() => toggleOpen(row.key)}
            direction={isOpen ? 'up' : 'down'}
            size="medium"
            color="primary"
            buttonColor="grey4"
          />
        </TableCell>
      )}
      {siblingExpandable && <TableCell style={{paddingRight: 10, paddingLeft: 0, width: 1}} />}
    </TableRow>
    {row.expandable
    && <ExpandableRow
      index={index}
      colSpan={horizontalLength}
      alternateRows={alternateRows}
      expandable={row.expandable}
      onUpdateExpanded={onUpdateExpanded}
      isOpen={isOpen}
    />}
  </>
)))

Row.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  alternateRows: PropTypes.bool,
  isOpen: PropTypes.bool,
  footer: PropTypes.bool,
  rowHeight: PropTypes.number,
  isLast: PropTypes.bool,
  horizontalLength: PropTypes.number,
  toggleOpen: PropTypes.func,
}

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${props => props.theme.colors.grey1}
`

const TableInnerWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;
  font-size: 13px;
`

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

const EmptyTd = styled.td`
  height: 50px;
  padding: 10px 20px;
  box-sizing: border-box;
`

class Table extends React.Component {
  state = {
    expandedKeys: [],
    scrollLeft: 0,
    tableScrollTop: 0,
    tableHeight: 0,
  }

  columnWidths = {}

  rowHeightsCache = new Array(this.props.rows?.length || 0).fill(60)
  expandedRowsHeightsCache = new Array(this.props.rows?.length || 0).fill(60)

  tableWrapper = this.props.tableWrapperRef ?? React.createRef()

  isOpen = (rowKey) => this.state.expandedKeys.find(key => key === rowKey) !== undefined

  toggleOpen = (rowKey) => {
    if (this.isOpen(rowKey)) {
      this.setState({
        expandedKeys: this.state.expandedKeys.filter(key => key !== rowKey),
      })
    } else {
      this.setState({expandedKeys: [...this.state.expandedKeys, rowKey]})
    }
  }

  updateTableHeight = () => {
    setTimeout(() => this.setState({tableHeight: this.tableWrapper.current.clientHeight}), 200)
  }

  onUpdateExpanded = (index, expandedBy) => {
    if (this.expandedRowsHeightsCache[index] !== expandedBy) {
      this.expandedRowsHeightsCache[index] = expandedBy
      this.updateTableHeight()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.boxHeight !== prevProps.boxHeight) {
      this.columnWidths = {}
    }
    if (this.props.boxWidth !== prevProps.boxWidth) {
      this.columnWidths = {}
    }
    if (this.state.tableHeight !== this.tableWrapper.current.clientHeight) {
      this.setState({tableHeight: this.tableWrapper.current.clientHeight})
    }
    if (prevProps.rows !== this.props.rows) {
      const newArray = new Array(this.props.rows.length).fill(60)
      this.rowHeightsCache.forEach((x, i) => { newArray[i] = x })
      this.rowHeightsCache = newArray
      const newExpandedArray = new Array(this.props.rows.length).fill(0)
      this.expandedRowsHeightsCache.forEach((x, i) => { newExpandedArray[i] = x })
      this.expandedRowsHeightsCache = newExpandedArray
      this.forceUpdate()
    }
  }

  updateScrollDebounced = throttle((scrollLeft) => {
    if (this.state.scrollLeft !== scrollLeft) {
      setImmediate(() => this.setState({scrollLeft}))
    }
  }, 150)

  updateScroll = (event) => this.updateScrollDebounced(event.target.scrollLeft)

  componentDidMount() {
    this.rowHeightsCache = new Array(this.props.rows?.length).fill(60)
    this.expandedRowsHeightsCache = new Array(this.props.rows?.length).fill(0)
    this.setState({tableHeight: this.tableWrapper.current.clientHeight})
    if (this.tableWrapper.current) {
      this.tableWrapper.current.addEventListener('scroll', this.updateScroll)
    }
    if (this.props.onOptionsClick) {
      setTimeout(() => {
        this.tableWrapper = {...this.tableWrapper}
        this.forceUpdate()
      }, 100)
    }
  }

  componentWillUnmount() {
    if (this.tableWrapper.current) {
      this.tableWrapper.current.removeEventListener('scroll', this.updateScroll)
    }
  }

  onScrollDebounced = throttle((tableScrollTop) => {
    if (this.state.tableScrollTop !== tableScrollTop) {
      setImmediate(() => this.setState({tableScrollTop}))
    }
  }, 150)

  onScroll = (event) => event.target && this.onScrollDebounced(event.target.scrollTop)

  isInView = (index) => {
    let rowTop = this.rowHeightsCache.slice(0, index).reduce((acc, cur) => acc + cur, 0)
    rowTop += this.expandedRowsHeightsCache.slice(0, index).reduce((acc, cur) => acc + cur, 0)
    const rowBottom = rowTop + this.rowHeightsCache[index] + this.expandedRowsHeightsCache[index]
    const start = this.state.tableScrollTop
    const end = start + this.state.tableHeight - 60
    return rowBottom > start && rowTop < end
  }

  render() {
    const {columns, footer, rowHeight, headerHeight, cellPadding,
      rows, alternateRows, loading, emptyText, onOptionsClick, paddingLines} = this.props
    const {scrollLeft} = this.state
    const firstVisible = rows?.findIndex((row, i) => this.isInView(i)) || 0
    const lastVisible = firstVisible + (Math.max(0,
      rows?.slice(firstVisible).findIndex((row, i) => !this.isInView(i + firstVisible))
    ) || rows?.length) || 0
    const hasExpandableRow = rows?.find(row => row.expandable) !== null
    return (
      <TableWrapper>
        {onOptionsClick && <Div position="absolute" top={0} right={-7} style={{transform: 'translate(0, -100%)'}}>
          <StyledStickerIcon />
          <ThreeDotsButton height={15} onClick={onOptionsClick} />
        </Div>}
        <TableInnerWrapper id="innerWrapper" onScroll={this.onScroll} ref={this.tableWrapper}>
          <StyledTable
            horizontallyScrolled={scrollLeft > 0}
            cellPadding={cellPadding}
          >
            <Header
              columns={columns}
              headerHeight={headerHeight}
              hasExpandableRow={hasExpandableRow}
            />
            <tbody>
              {rows?.length
                ? rows.map((row, i) => (i > firstVisible - paddingLines || i < lastVisible + paddingLines)
                  ? <Row
                    ref={node => {
                      if (node && node.sectionRowIndex >= 0
                        && this.rowHeightsCache[node.sectionRowIndex] !== node.clientHeight) {
                        this.rowHeightsCache[i] = node.clientHeight
                      }
                    }}
                    onUpdateExpanded={this.onUpdateExpanded}
                    key={row.key}
                    index={i}
                    row={row}
                    isOpen={this.isOpen(row.key)}
                    alternateRows={alternateRows}
                    footer={!!footer}
                    rowHeight={rowHeight}
                    horizontalLength={row.expandable ? columns.length + 1 : columns.length}
                    isLast={i === (rows.length - 1)}
                    toggleOpen={this.toggleOpen}
                    siblingExpandable={!row.expandable && hasExpandableRow}
                  />
                  : <tr key={row.key}>
                    {columns.map(column => (<td
                      key={column.key || column.value || column}
                      ref={node => {
                        if (node?.clientWidth > (this.columnWidths[column.key || column.value || column] || 0)) {
                          this.columnWidths[column.key || column.value || column] = node.clientWidth
                        }
                      }}
                      style={{
                        height: (this.rowHeightsCache[i] || this.rowHeightsCache[0] || 60) - 50,
                      }}
                    >
                      <div style={{width: (this.columnWidths[column.key || column.value || column] || 50) - 50}} />
                    </td>))}
                  </tr>,
                ) : <>
                  {emptyText && <tr><EmptyTd colSpan={columns.length}>{emptyText}</EmptyTd></tr>}
                </>
              }
              {loading && <tr>
                <td style={{position: 'relative'}} colSpan={columns.length}>
                  <StyledLoader
                    style={this.tableWrapper.current
                      ? {
                        left: this.tableWrapper.current && ((this.tableWrapper.current.clientWidth / 2) + scrollLeft),
                        transition: 'left 200ms',
                      }
                      : {}}
                    noDelay
                  />
                </td>
              </tr>}
            </tbody>
            {footer && <tfoot>
              <FooterRow>
                {footer.map((footerCell, i) =>
                  <TableCell
                    key={footerCell.key}
                    style={{
                      textAlign: footerCell.align || (i === 0 ? 'left' : 'right'),
                      whiteSpace: footerCell.noWrap ? 'nowrap' : 'normal',
                    }}>
                    <CellContent cell={footerCell} footer />
                  </TableCell>,
                )}
              </FooterRow>
            </tfoot>}
          </StyledTable>
        </TableInnerWrapper>
      </TableWrapper>
    )
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  footer: PropTypes.array,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  alternateRows: PropTypes.bool,
  cellPadding: PropTypes.number,
  loading: PropTypes.bool,
  tableWrapperRef: PropTypes.any,
  emptyText: PropTypes.node,
  onOptionsClick: PropTypes.func,
  boxHeight: PropTypes.number,
  boxWidth: PropTypes.number,
  paddingLines: PropTypes.number,
}

Table.defaultProps = {
  rowHeight: 50,
  headerHeight: 60,
  cellPadding: 25,
  paddingLines: 10,
}

export default Table
