import styled, {css} from 'styled-components'
import {Div} from 'components/layout/styled'
import {space} from 'styled-system'

export const ProfilePic = styled.img`
  height: ${props => props.size || 130}px;
  width: ${props => props.size || 130}px;
  border-radius: 50%;
  ${props => props.square && css`border-radius: 4px;`};
  ${props => props.squareTop && css`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `};
  ${space}
`

ProfilePic.defaultProps = {
  src: '/static/img/profile_default.jpg',
}

export const Logo = styled(ProfilePic)``

Logo.defaultProps = {
  src: '/static/img/logo-placeholder.jpg',
}

export const ProfilePicUploadInfo = styled(Div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%,-50%,0);
  text-align: center;
  opacity: 0;
  transition: opacity .25s ease;
  svg {
    height: 2em;
    display: inline-block;
    path {
      fill: ${props => props.theme.colors.grey8}
    }
  }
`

export const ProfilePicWrapper = styled(Div)`
  position: relative;
  cursor: pointer;
  display: inline-block;
  &:hover {
    ${ProfilePicUploadInfo} {
      opacity: 1;
    }
  }
`
