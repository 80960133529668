import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {LinkText} from 'components/typography'
import DeleteIcon from 'static/svg/ico/ico_ui_delete.svg'
import {Flex} from 'components/layout/styled'
import EditIcon from 'static/svg/ico/ico_ui_edit.svg'
import DownloadIcon from 'static/svg/ico/ico_ui_download.svg'

import FilterIcon from 'static/svg/ico/ico_ui_filter.svg'

const StyledIcon = styled(EditIcon)`
  height: 16px;
  margin-right: 5px;
  path {
    fill: ${props => props.theme.colors.grey7};
  }
`

export const LinkButton = ({icon, text, main, ...props}) => (
  <LinkText
    disableSelect
    color={main ? 'primary' : 'grey7'}
    hoverColor={main ? 'primaryDarker' : 'primary'}
    height="30px"
    fontWeight={main ? 700 : 400}
    display="flex"
    alignItems="center"
    {...props}
  >
    <Flex alignItems="center">
      <StyledIcon as={icon} />
      <span>{text}</span>
    </Flex>
  </LinkText>
)

LinkButton.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  main: PropTypes.bool,
}

export const EditLinkButton = (props) => <LinkButton text="Edit" icon={EditIcon} {...props} />
export const DownloadLinkButton = (props) => <LinkButton text="Download" icon={DownloadIcon} {...props} />
export const DeleteLinkButton = (props) => <LinkButton text="Delete" icon={DeleteIcon} {...props} />
export const RemoveLinkButton = (props) => <LinkButton text="Remove" icon={DeleteIcon} {...props} />
export const FilterLinkButton = (props) => <LinkButton text="Filter" icon={FilterIcon} {...props} />
