export const shorten = (x) => {
  if (x < 0) {
    const res = shorten(-x)
    res[0] *= -1
    return res
  }

  if (x < 1000) {
    return [x, null]
  }
  if (x < 100000) {
    return [x / 1000, 'k']
  }
  if (x < 1000000000) {
    return [x / 1000000, 'm']
  }
  if (x < 1000000000000) {
    return [x / 1000000000, 'b']
  }
  return [x / 1000000000000, 'T']
}

export const formatSize = (size) => {
  if (size > 1000000000) {
    return `${Math.round((size / 1000000000) * 100) / 100} GB`
  }
  if (size > 1000000) {
    return `${Math.round((size / 1000000) * 100) / 100} MB`
  }
  if (size > 1000) {
    return `${Math.round((size / 1000) * 100) / 100} KB`
  }
  return `${size} B`
}

export const removeHttp = (url) => {
  if (!url) return null
  return url.replace(/^https?:\/\//, '')
}

export const formatGraphQlError = (error) => {
  return error?.split?.(':', 2)?.[1] || ''
}

export const strapiOrGraphQlError = (error) =>
  error?.graphQLErrors?.[0]?.extensions?.exception?.data?.message?.[0]?.messages[0]?.message
  || formatGraphQlError(error?.message)

export const cutZeros = /^(.*?)(\.?0*)$/
export const cutOffZeros = (string) => {
  const groups = string.match(cutZeros)
  if (groups.length > 2) {
    return groups[1]
  }
  return string
}

export const formatBooleanYesNo = (value) => {
  if ((value === 'true') || (typeof value === 'boolean' && value)) {
    return 'yes'
  }
  if ((value === 'false') || (typeof value === 'boolean' && !value)) {
    return 'no'
  }
  return value
}
