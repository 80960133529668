import React from 'react'
import PropTypes from 'prop-types'
import WrapperWithOptions from 'components/layout/WrapperWithOptions'
import {Box, Column} from 'components/layout/styled'
import {Text} from 'components/typography'
import styled from 'styled-components'

export const Img = styled.img`
  width: 100%;
  padding-bottom: 100%;
  background: no-repeat url("${props => props.bgImg}");
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

Img.defaultProps = {
  bgImg: '/static/img/profile_default.jpg',
}

const UserCard = ({
  id,
  title,
  subtitle,
  additionalText,
  picture,
  menuContent,
  clickable,
  children,
}) => {
  const box = <Box p={0} hoverable style={{...(clickable ? {cursor: 'pointer'} : {}), width: '100%'}}>
    <WrapperWithOptions
      height="100%"
      menuContent={menuContent}
    >
      <Column alignItems="stretch" flex={1}>
        <Img bgImg={picture} />
        <Column alignItems="center" p={15} flex={1}>
          <Text
            fontSize={17}
            fontWeight={700}
            ellipses
            textAlign="center"
            lineHeight={1.2}
            mt={5}
          >
            {title}
          </Text>
          <Text
            lineHeight={1.4}
            fontWeight={500}
            textAlign="center"
            ellipses
            color="grey7"
          >
            {subtitle}
          </Text>
          <Text color="grey6" fontSize={12} mb={10} textAlign="center">{additionalText}</Text>
          {children}
        </Column>

      </Column>
    </WrapperWithOptions>
  </Box>
  return box
}

UserCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  additionalText: PropTypes.string,
  picture: PropTypes.string,
  menuContent: PropTypes.node,
  clickable: PropTypes.bool,
  children: PropTypes.node,
}

export default UserCard
