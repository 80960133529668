import styled from 'styled-components'
import {Div} from '../layout/styled'

const VerticalDivider = styled(Div)`
  width: 1px;
`

VerticalDivider.defaultProps = {
  bg: 'grey8',
  mx: 15,
}

export default VerticalDivider
