import {Flex, Column} from 'components/layout/styled'
import styled from 'styled-components'

const BorderFlex = styled.div.attrs(({...props}) => ({noShrink: true, as: props.column ? Column : Flex}))`
  background-color: ${props => props.theme.colors[props.backgroundColor] || props.theme.colors['grey1']};
  ${props => !props.noBorder && `border-${props.top ? 'top' : 'bottom'}: 1px solid ${props.theme.colors['grey4']};`}
`

BorderFlex.defaultProps = {
  height: 70,
}

export default BorderFlex
