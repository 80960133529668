import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {layout, space, typography, flexbox, color} from 'styled-system'
import history from 'utils/history'
import {Div, Flex} from 'components/layout/styled'
import {omitProps} from 'utils/props'
import Divider from 'components/layout/Divider'

const blackList = ['color', 'narrow', 'inline', 'fill', 'disableSelect', 'crossedOut',
  'space', 'fontSize', 'textAlign', 'lineHeight', 'fontWeight', 'nowrap', 'ellipses', 'upperCase', 'hoverUnderline']

const Typography = styled(omitProps(blackList, 'p'))`

  /* Default values */

  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: ${props => props.theme.colors.grey9};
  text-decoration: none;

  /* Adjustments */

  ${space}
  ${color}
  ${flexbox}
  ${typography}
  ${layout}
  ${props => props.upperCase && 'text-transform: uppercase;'}
  ${props => props.color && `color: ${props.theme.colors[props.color]};`}
  ${props => props.narrow && 'font-family: "Gotham";'}
  ${props => props.inline && 'display: inline;'}
  ${props => props.disableSelect && 'user-select: none;'}
  ${props => props.ellipses && css`
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;`}
  ${props => props.nowrap && 'white-space: nowrap;'}
  ${props => props.crossedOut && 'text-decoration: line-through;'}
  
  ${props => props.hoverUnderline && css`
    &:hover {
      text-decoration: underline;
    }
  `}
`

export const Text = Typography

// default for Text

export const Inline = styled.span`
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  ${props => props.color && `color: ${props.theme.colors[props.color]};`}
  ${space}
  ${typography}
`

// wrap some text into this for change style inside another text component

export const LinkText = styled(({to, hoverColor, hoverUnderline, hoverBold, ...props}) => <Typography
  as="a"
  href={to}
  target={props.target}
  onClick={(event) => {
    if (event.metaKey || event.ctrlKey) {
      return
    }
    if (!props.target && to) {
      history.push(to)
      event.preventDefault()
    }
  }}
  {...props}
/>)`
  outline: none;
  cursor: pointer;
  fill: ${props => props.theme.colors[props.color || 'grey7']} !important;
  &:hover {
    color: ${props => props.theme.colors[props.color]};
  }
  ${props => props.hoverColor && css`
    &:hover {
      color: ${props.theme.colors[props.hoverColor]} !important;
      * {
        color: ${props.theme.colors[props.hoverColor]};
        fill: ${props.theme.colors[props.hoverColor]};
      }
    }
  `}
  ${props => props.hoverUnderline && css`
    &:hover {
      text-decoration: underline;
    }
  `}
  ${props => props.hoverBold && css`
    &:hover {
      font-weight: 700;
    }
  `}
  ${layout}
  ${flexbox}
`

// default for links

export const SvgText = styled((props) => <Typography as="text" {...props} />)`
  ${props => `fill: ${props.theme.colors[props.fill] || props.fill || props.theme.colors.grey8};`}
`

// default for Text in SVGs

export const Paragraph = styled((props) => <Typography
  fontSize={15}
  color="grey8"
  {...props}
/>)``

// paragraph of long text

export const PageHeadline = styled(({asText, ...props}) => <Typography
  as={asText || 'h1'}
  fontSize={24}
  fontWeight={700}
  {...props}
/>)``

// PageHeadline is the main headline of the page

export const Headline = styled(({asText, ...props}) => <Typography
  as={asText || 'h2'}
  color="primary"
  {...props}
/>)`
  user-select: none;
`

Headline.defaultProps = {
  fontSize: 14,
  fontWeight: 500,
}

// Headline is the title of a box

export const Title = styled((props) => <Typography
  as="h3"
  fontSize={16}
  fontWeight={700}
  color="primary"
  {...props}
/>)``

const Underlined = styled(Div)`
  position: relative;
  padding-bottom: 8px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${props => props.theme.colors.grey3};
    width: 7em;
    height: 3px;
  }
`

export const UnderLineTitle = ({mb, children, ...props}) => <Underlined mb={mb}>
  <Title upperCase {...props}>{children}</Title>
</Underlined>
UnderLineTitle.defaultProps = {
  fontSize: 18,
  color: 'grey9',
  mb: 20,
}
UnderLineTitle.propTypes = {
  children: PropTypes.any,
  mb: PropTypes.any,
}

// Title is used for main names of things

export const SmallTitle = styled(({asText, ...props}) => <Typography
  as={asText || 'h3'}
  fontSize={14}
  fontWeight={500}
  color="grey9"
  {...props}
/>)``

// Small title is used for main names of things

export const Highlight = styled((props) => <Typography
  fontWeight={700}
  color="primary"
  {...props}
/>)``

// Highlight is used for important texts

export const LargeValue = styled((props) => <Typography
  fontSize={30}
  fontWeight={800}
  lineHeight={1}
  {...props}
/>)``

// Large value is used in boxes ect. for main values

export const MediumValue = styled((props) => <Typography
  fontSize={22}
  fontWeight={700}
  {...props}
/>)``

// Medium value style used for medium sized values mostly used in different graphs

export const SmallValue = styled((props) => <Typography
  fontSize={14}
  fontWeight={500}
  color="primary"
  narrow
  {...props}
/>)``

// Small value style used for values in tables ect.

export const Note = styled((props) => <Typography
  fontSize={11}
  color="grey6"
  {...props}
/>)``

// small note

export const Info = styled((props) => <Typography
  fontSize={13}
  fontWeight={700}
  color="grey6"
  {...props}
/>)``

// additional information primarily used in boxes for dates ect.

export const BorderedHeadline = ({children, pt}) => {
  return <>
    <Flex pt={pt}>
      {
        typeof children === 'string'
          ? <Title>{children}</Title>
          : children
      }
    </Flex>
    <Divider m="10px 0 30px 0" />
  </>
}
BorderedHeadline.defaultProps = {
  pt: 20,
}
BorderedHeadline.propTypes = {
  children: PropTypes.any,
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
}

export const Button = styled.button`
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  background: none;
  ${space}
`
Button.defaultProps = {
  p: 0,
}

export const H1 = styled((props) => <H0 as="h1" {...props} />)``
export const H2 = styled((props) => <H0 as="h2" {...props} />)``
export const H3 = styled((props) => <H0 as="h3" {...props} />)``
export const H4 = styled((props) => <H0 as="h4" {...props} />)``
export const H5 = styled((props) => <H0 as="h5" {...props} />)``

const H0 = styled(Typography)``
H0.defaultProps = {
  fontWeight: 700,
}

export const Ul = styled((props) => <Typography as="ul" {...props} />)`
  padding-left: 1.2em;
  li {
    margin-bottom: .3em;
  }
`
