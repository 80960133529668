import React from 'react'
import {Cell, Column, Flex, Row} from 'components/layout/styled'
import {Text} from 'components/typography'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import withInputWrapper from 'components/form/withInputWrapper'

const RadioButton = styled(Flex)`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.grey6};
  margin-right: 5px;
`
const RadioCheck = styled.div`
  height: 8px;
  width: 8px;
  animation: bounce 200ms ease-in-out;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  @keyframes bounce {
    0% {
      height: 0;
      width: 0;
    }
    70% {
      height: 10px;
      width: 10px;
    }
    100% {
      height: 8px;
      width: 8px;
    }
  }
`

const RadioWrapper = styled(Flex)`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  :hover {
    ${RadioButton} {
      border: 2px solid ${props => props.theme.colors.primary};
    }
  }
  ${props => props.disabled && css`
    ${RadioButton} {
      border: 2px solid ${props.theme.colors.grey6} !important;
    }
    ${RadioCheck} {
      background-color: ${props.theme.colors.grey6} !important;
    }
  `}
`

const RadioList = ({input, meta, items, row, defaultValue, disabled, columnWidth, ...props}) => {
  const renderButton = (item) => <RadioWrapper
    disabled={disabled}
    alignItems="center"
    py={5}
    pr={15}
    onClick={disabled ? () => null : () => input.onChange(item.value || item.id)}
    key={item.value || item.id}
  >
    <RadioButton
      selected={input.value === item.value || input.value === item.id}
      alignItems="center"
      justifyContent="center"
    >
      {(input.value === item.value || input.value === item.id) && <RadioCheck />}
    </RadioButton>
    <Text color={disabled ? 'grey6' : 'grey8'}>{item.text}</Text>
  </RadioWrapper>

  if (columnWidth) {
    return <Row
      {...props}
      flexDirection={row ? 'row' : 'column'}
      flexWrap={row ? 'wrap' : 'nowrap'}
    >
      {items.map(item => <Cell key={`cell.${item.value || item.id}`} width={columnWidth}>{renderButton(item)}</Cell>)}
    </Row>
  }
  return <Column
    {...props}
    flexDirection={row ? 'row' : 'column'}
    flexWrap={row ? 'wrap' : 'nowrap'}
  >
    {items.map(item => renderButton(item))}
  </Column>
}

RadioList.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  columnWidth: PropTypes.number,
}

export default withInputWrapper(RadioList)
