import {css} from 'styled-components'
import {colors} from './colors'

const hollowInputStyle = css`
  border: none;
  outline: none;
  &:focus, :active {
    outline: none;
  }
  background-color: transparent;
  box-shadow: none;
  appearance: none;
  font-size: 14px;
  color: ${props => props.disabled ? props.theme.colors.grey6 : colors.grey8};
  &::placeholder {
    font-size: 14px;
    color: ${colors.grey5};
  }
`

const theme = {
  hollowInputStyle,
  colors,
  button: {
    primary: {
      background: {
        normal: colors.primary,
        hover: colors.primaryDarker,
        active: colors.primaryDarker,
        focus: colors.primary,
        disabled: colors.grey5,
      },
      text: {
        normal: colors.white,
        hover: colors.white,
        active: colors.white,
        focus: colors.white,
        disabled: colors.grey2,
      },
      border: {
        color: {
          normal: colors.transparent,
          hover: colors.transparent,
          active: colors.transparent,
          focus: colors.primaryDarker,
          disabled: colors.transparent,
        },
        width: {
          normal: 0,
          hover: 0,
          active: 0,
          focus: 2,
          disabled: 0,
        },
      },
    },
    white: {
      background: {
        normal: colors.white,
        hover: colors.white,
        active: colors.white,
        focus: colors.white,
        disabled: colors.grey5,
      },
      text: {
        normal: colors.grey7,
        hover: colors.grey8,
        active: colors.grey8,
        focus: colors.grey8,
        disabled: colors.grey2,
      },
      border: {
        color: {
          normal: colors.grey6,
          hover: colors.grey7,
          active: colors.grey7,
          focus: colors.grey7,
          disabled: colors.transparent,
        },
        width: {
          normal: 1,
          hover: 1,
          active: 1,
          focus: 2,
          disabled: 0,
        },
      },
    },
  },
  space: [0, 1, 2, 3, 4, 5],
  breakpoints: ['1300px', '1600px', '1900px', '2200px', '2500px', '3000px'],
  defaultShadow: 'box-shadow: 0 0 7px 0px rgba(0, 0, 60, 0.15);',
  hugeShadow: 'box-shadow: 0 0 30px 0px rgba(10, 10, 40, 0.1);',
  hugeShadowHover: 'box-shadow: 0 0 40px 0px rgba(10, 10, 40, 0.2);',
  hoverShadow: 'box-shadow: 0 0 14px 0px rgba(0, 50, 30, 0.22);',
  darkerShadow: 'box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.25);',
  borderRadius: '8px',
}

export default theme
