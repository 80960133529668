import Loading from 'components/loaders/Loading'

export const LpDetail = (url) => ({
  path: `${url}/lp-database/:lpId`,
  component: Loading(() => import(/* webpackChunkName: "search" */'./containers/LpProfileContainer')),
})

export const LpList = (url) => ({
  path: `${url}/lp-database`,
  component: Loading(() => import(/* webpackChunkName: "search" */'./containers/LpListContainer')),
})
