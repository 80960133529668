import {ApolloClient} from 'apollo-client'
import {ApolloLink, Observable} from 'apollo-link'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createUploadLink} from 'apollo-upload-client'
import {removeToken} from 'utils/localStorage'
import {resolvers, typeDefs, initGlobalState, updateIsConnected} from './global-state'

const cache = new InMemoryCache()

const uri = `${window.location.origin}/graphql`

const uploadLink = createUploadLink({
  uri,
  mode: 'same-origin',
  cache: 'no-cache',
  fetch,
  credentials: 'same-origin',
})

const networkStatusLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let sub

    try {
      const token = window.localStorage?.getItem('token')
      if (token) {
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        })
      }
      sub = forward(operation).subscribe({
        next(result) {
          observer.next(result)
        },
        error(networkError) {
          updateIsConnected(cache, false)
          observer.error(networkError)
        },
        complete() {
          updateIsConnected(cache, true)
          observer.complete(observer)
        },
      })
    } catch (e) {
      console.error(e)
      observer.error(e)
    }

    return () => {
      if (sub) sub.unsubscribe()
    }
  }))


const oldTokenLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let sub

    try {
      sub = forward(operation).subscribe({
        next(result) {
          if (result.errors?.find(e => e.message ==='Invalid token.')) {
            removeToken()
            window.location.reload()
          }
          observer.next(result)
        },
        error(networkError) {
          observer.error(networkError)
        },
        complete() {
          observer.complete(observer)
        },
      })
    } catch (e) {
      observer.error(e)
    }

    return () => {
      if (sub) sub.unsubscribe()
    }
  }))

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    oldTokenLink,
    networkStatusLink,
    uploadLink,
  ]),
  resolvers,
  typeDefs,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-and-network',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

initGlobalState(cache)

client.onResetStore(() => initGlobalState(cache))

export default client
