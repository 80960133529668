import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class AddToBottom extends React.Component {
  static zIndex = 0
  parentElement = null
  el = document.createElement('div')
  zIndex = null
  componentDidMount() {
    this.zIndex = AddToBottom.zIndex++
    this.el.classList.add('pop-ups')
    this.parentElement = document.body
    this.parentElement.appendChild(this.el)
    if (this.el.firstElementChild?.firstElementChild?.style) {
      this.el.firstElementChild.firstElementChild.style.zIndex = this.zIndex
    }
  }

  componentWillUnmount() {
    this.parentElement.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(
      <div {...this.props} />,
      this.el,
    )
  }
}

AddToBottom.propTypes = {
  children: PropTypes.any,
}

export default AddToBottom
