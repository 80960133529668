import React from 'react'
import pick from 'lodash/pick'
import omit from 'lodash/omit'

export const omitProps = (blacklist, Component) => React.forwardRef((props, ref) =>
  <Component {...omit(props, blacklist)} ref={ref} />)

const blackList = ['m', 'my', 'mx', 'mt', 'mr', 'mb',
  'ml', 'p', 'py', 'px', 'pt', 'pr', 'pb', 'pl']

export const spacePropsSeparation = (props) => ({
  spaceProps: pick(props, blackList),
  otherProps: omit(props, blackList),
})
