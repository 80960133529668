import gql from 'graphql-tag'

export const GET_NETWORK_STATUS = gql`
  query getNetworkStatus {
    networkStatus @client {
      isConnected
    }
  }
`

export const GET_SERVER_VERSION = gql`
  query getServerVersion {
    serverVersion @client {
      version
    }
  }
`

export const GET_GLOBAL_VARIABLES = gql`
  query getGlobalVariables {
    globalVariables @client {
      unconfirmed
    }
  }
`

export const UPDATE_GLOBAL_VARIABLES = gql`
  mutation updateGlobalVariables ($input: GlobalVariablesInput!) {
    updateGlobalVariables (input: $input) @client {
      unconfirmed
    }
  }
`
