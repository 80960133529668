import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {layout, position, typography, flexbox, color, space} from 'styled-system'

export const Div = styled.div`
  box-sizing: border-box;
  ${props => props.halfVisible && css`
    opacity: .2;
    pointer-events: none;
  `};
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${position}
  ${space}
`
Div.propTypes = {
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  fill: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flexWrap: PropTypes.string,
}

export const Box = styled(Div)`
  border-radius: ${props => props.theme.borderRadius};
  ${props => props.theme.defaultShadow};
  ${props => props.hoverable && css`
    transition: box-shadow .2s ease;
    &:hover {
      ${props.theme.hoverShadow};
    }
  `}
`
Box.defaultProps = {
  my: 12,
  p: 25,
  bg: 'white',
}

export const Flex = styled(Div)``
Flex.defaultProps = {
  display: 'flex',
  flexDirection: 'row',
}

export const Column = styled(Div)``
Column.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
}

export const Container = styled(Column)`
  ${props => props.large && css`min-width: 1040px;`}
  ${props => !props.large && css`width: 1040px;`}
`
Container.defaultProps = {
  m: '0 auto',
  pt: 40,
  pb: 30,
  px: 40,
}

export const Row = styled(Flex)``
Row.defaultProps = {
  flexWrap: 'wrap',
  mx: -12,
}

export const Cell = styled(Div)``
Cell.defaultProps = {
  px: 12,
}
