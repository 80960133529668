import React from 'react'
import ReactDOM from 'react-dom'

let createGlobalStyle

if (__DEV__) {
  createGlobalStyle = ([first, ...buildString], ...params) => {
    let styles = first
    buildString.forEach((str, i) => { styles += `${str}${params[i]}` })
    styles = styles.replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/gm, ' ').replace(/[ \t\n\r]+/gm, ' ')
    class GlobalStyles extends React.PureComponent {
      #element = document.createElement('style')

      componentDidMount() {
        document.head.appendChild(this.#element)
      }

      componentWillUnmount() {
        document.head.removeChild(this.#element)
      }

      render() {
        return ReactDOM.createPortal(
          styles,
          this.#element,
        )
      }
    }
    return GlobalStyles
  }
} else {
  createGlobalStyle = require('styled-components').createGlobalStyle
}

export default createGlobalStyle
