import React from 'react'
import PropTypes from 'prop-types'
import DefaultInputStyle from 'components/form/DefaultInputStyle'
import withInputWrapper from 'components/form/withInputWrapper'
import styled from 'styled-components'
import {omitProps} from 'utils/props'

const TextAreaWrapper = styled(omitProps(['height'], DefaultInputStyle))`
  padding: 0;
  overflow: hidden;
  height: ${props => props.height ? `${props.height}px` : 'auto'};
`

const StyledTextArea = styled.textarea`
  padding: 15px;
  flex: 1;
  ${props => !props.resize && 'resize: none;'}
  ${props => props.theme.hollowInputStyle}
`

const FakeTextAreaWrapper = styled(TextAreaWrapper)`
  visibility: hidden;
  position: absolute;
  left: -9999px;
  ${StyledTextArea} {
    white-space: pre-wrap;
    word-break: break-all;
  }
`

const lineHeight = 16

class TextArea extends React.Component {
  state = {
    value: '',
    focused: this.props.meta?.active,
  }

  visibleArea = {}
  invisibleArea = {}
  textArea = {}
  defaultHeight = this.props.defaultRows * lineHeight + 40

  static getDerivedStateFromProps(props, state) {
    if (!props.input && (props !== state.prevPropsList)) {
      return {value: props.value, prevPropsList: props}
    } return null
  }

  componentDidMount() {
    this.setState({height: this.defaultHeight})
  }

  updateHeight = () => {
    this.props.autoGrow && this.setState({height: Math.max(this.defaultHeight, this.invisibleArea.clientHeight)})
    this.props.resize && this.setState({height: this.textArea.clientHeight})
  }

  areaResize = () => {
    window.addEventListener('mousemove', this.updateHeight)
  }

  areaStopResize = () => {
    window.removeEventListener('mousemove', this.updateHeight)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.focused && this.state.focused) {
      if (this.props.input) this.props.input.onFocus()
    }
    if (prevState.focused && !this.state.focused) {
      if (this.props.input) this.props.input.onBlur()
    }
  }

  getValue() {
    return this.props.input?.value ?? this.state.value
  }

  onChange = (event) => {
    if (this.props.input) {
      this.props.input.onChange(event.target.value)
    } else {
      this.setState({value: event.target.value})
    }

    if (this.props.autoGrow) {
      this.updateHeight()
    }
  }

  render() {
    const numberOfRows = (this.invisibleArea.clientHeight - 40) / lineHeight
    const lastCharIsNewLine = this.state.value?.[this.state.value.length - 1] === '\n'
    return (
      <div style={{position: 'relative'}}>
        <TextAreaWrapper
          disabled={this.props.disabled}
          valid={!!this.props.isValid}
          active={this.state.focused}
          error={this.props.error}
          ref={node => { this.visibleArea = node }}
          height={(lastCharIsNewLine
            && this.props.autoGrow
            && numberOfRows >= this.props.defaultRows)
            ? this.state.height + lineHeight : this.state.height}
        >
          <StyledTextArea
            disabled={this.props.disabled}
            tabIndex={this.props.tabIndex || 0}
            ref={node => { this.textArea = node }}
            value={this.getValue() || ''}
            onChange={this.onChange}
            onFocus={() => this.setState({focused: true})}
            onBlur={() => this.setState({focused: false})}
            rows={1000}
            placeholder={this.props.placeholder}
            resize={this.props.resize}
            onMouseDown={this.props.resize ? this.areaResize : () => null}
            onMouseUp={this.props.resize ? this.areaStopResize : () => null}
            name={this.props.input?.name ?? this.props.name}
          />
        </TextAreaWrapper>
        {this.props.autoGrow
        && <FakeTextAreaWrapper width={this.visibleArea.clientWidth} ref={node => { this.invisibleArea = node }}>
          <StyledTextArea as="div">{this.state.value}</StyledTextArea>
        </FakeTextAreaWrapper>}
      </div>
    )
  }
}

TextArea.propTypes = {
  defaultRows: PropTypes.number,
  tabIndex: PropTypes.number,
  autoGrow: PropTypes.bool,
  resize: PropTypes.bool,
  isValid: PropTypes.any,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
}

TextArea.defaultProps = {
  defaultRows: 5,
  autoGrow: false,
  resize: false,
}

export default withInputWrapper(TextArea)
