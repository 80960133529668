// import Components from 'routes/Components'

export const createSubroutes = (route, objects) =>
  objects
  //  .filter(f => __DEV__ || Components !== f)
    .map(f => {
      let res = f
      if (typeof f === 'function') {
        res = f(route)
      }
      res.path = res.path.replace(/\/\/*/g, '/')
      if ('routes' in res) {
        res.routes = createSubroutes(`${route}/${res.path}`, res.routes)
      }
      return res
    })
    .filter(({routes}) => __DEV__ || !Array.isArray(routes) || routes.length > 0)

export const createSubsubroutes = (route, object) =>
  object.map(f => route2 => f(route2))
