import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Div} from '../layout/styled'

import LinkedInIcon from 'static/svg/social/linkedin.svg'
import TwitterIcon from 'static/svg/social/twitter.svg'

const Ico = styled.div`
  height: ${props => props.size ? (props.size - 10) : 15}px;
  width: ${props => props.size ? (props.size - 10) : 15}px;
  path {
    fill: ${props => props.theme.colors[props.color] || props.theme.colors.grey5};
    transition: fill .2s ease;
  }  
`

const CircleIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${props => props.color || props.theme.colors.grey5};
  transition: border-color .2s ease;
  height: ${props => props.size || 25}px;
  width: ${props => props.size || 25}px;
  &:hover {
    border-color: ${props => props.theme.colors[props.hoverColor] || props.theme.colors.grey7};
    ${Ico} {
      path {
        fill: ${props => props.theme.colors[props.hoverColor] || props.theme.colors.grey7};
      }  
    }
  }
`

const SocialIcon = ({icon: iconType, href, size, color, hoverColor, ...props}) => {
  let icon = LinkedInIcon
  switch (iconType) {
    case 'twitter':
      icon = TwitterIcon
      break
  }
  return <Div {...props}>
    <CircleIcon
      color={color}
      hoverColor={hoverColor}
      size={size}
      href={href}
      target="_blank"
    >
      <Ico as={icon} size={size} color={color} />
    </CircleIcon>
  </Div>
}

SocialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
}

export default SocialIcon
