import React from 'react'
import styled, {keyframes, css} from 'styled-components'
import PropTypes from 'prop-types'
import {Div} from 'components/layout/styled'

const CheckWrapper = styled.div`
  width: ${props => props.width + props.lineWidth}px;
  height: ${props => props.height + props.lineWidth}px;
  transform: rotate(-45deg);
`

const drawHeight = keyframes`
  0% {
    height: 0px;
    border-bottom: none;
    border-left: none;
  }
  1% {
    border-bottom: ${props => props.lineWidth}px solid ${props => props.theme.colors[props.color]};
    border-left: ${props => props.lineWidth}px solid ${props => props.theme.colors[props.color]};
  }
  100% {
    height: ${props => props.height}px;
  }
`

const drawWidth = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: ${props => props.width}px;
  }
`

const Check = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-bottom: ${props => props.lineWidth}px solid ${props => props.theme.colors[props.color]};
  border-left: ${props => props.lineWidth}px solid ${props => props.theme.colors[props.color]};
  ${props => !props.animationOff && css`
    animation: ${drawHeight} ${props => (props.speed * 2 / 3)}ms ease-in ${props => props.delay}ms,
    ${drawWidth} ${props => (props.speed * 1 / 3)}ms ease-out ${props => props.delay + (props.speed * 2 / 3)}ms;
    animation-fill-mode: both;
  `}
`

const AnimatedCheck = ({height, width, color, lineWidth, speed, delay, animationOff, ...props}) => (
  <Div {...props}>
    <CheckWrapper height={height} width={width} lineWidth={lineWidth}>
      <Check
        animationOff={animationOff}
        height={height}
        width={width}
        lineWidth={lineWidth}
        speed={speed}
        delay={delay}
        color={color}
      />
    </CheckWrapper>
  </Div>
)

AnimatedCheck.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  lineWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.number,
  delay: PropTypes.number,
  animationOff: PropTypes.bool,
}

AnimatedCheck.defaultProps = {
  delay: 0,
  speed: 400,
  lineWidth: 2,
  height: 3,
  width: 7,
  color: 'primary',
}

export default AnimatedCheck
