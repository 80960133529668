module.exports = {
  DATE_TIME_FORMAT: 'D MMM YYYY H:mm',
  DATE_FORMAT: 'D MMM YYYY',
  DATE_MONTH_FORMAT: 'MMM YYYY',
  DATE_FORMAT_SLASH: 'DD/MM/YYYY',
  URL_REGEX: /^(http:\/\/|https:\/\/)?([a-z0-9][a-z0-9-]*\.)+[a-z0-9][a-z0-9-]*(:[0-9]{1,5})?(\/.*)?$/,


  CUSTOM_FIELD_TYPES: {
    SHORT_TEXT: 'Short text',
    LONG_TEXT: 'Long text',
    NUMBER: 'Number',
    AMOUNT: 'Amount',
    PERCENTAGE: 'Percentage',
    BOOLEAN: 'Boolean',
    DURATION: 'Duration',
    DATE: 'Date',
    URL: 'URL',
    LIST: 'List',
  },

  COUNTRIES: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua & Deps',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Rep',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo {Democratic Rep}',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and Mcdonald Islands',
    VA: 'Vatican City',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland {Republic}',
    IM: 'Isle of Man',
    IL: 'Israel',
    CI: 'Ivory Coast',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea South',
    KP: 'Korea North',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LV: 'Latvia',
    LA: 'Laos',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    MS: 'Montserrat',
    ME: 'Montenegro',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar, {Burma}',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    SH: 'Saint Helena',
    KN: 'St Kitts & Nevis',
    LC: 'St Lucia',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent & the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome & Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SS: 'South Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'East Timor',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },

  COMPANY_SIZE_TEXT: {
    A: 'Self-employed',
    B: '1-10 employees',
    C: '11-50 employees',
    D: '51-200 employees',
    E: '201-500 employees',
    F: '501-1000 employees',
    G: '1001-5000 employees',
    H: '5001-10,000 employees',
    I: '10,001+ employees',
  },

  LP_TYPES: {
    ASSET_MANAGER :'Asset Manager',
    CORPORATION: 'Corporation',
    FAMILY_OFFICE: 'Family Office',
    FINANCIAL_INSTITUTION: 'Financial Institution',
    FOUNDATION: 'Foundation',
    ENDOWMENT: 'Endowment',
    FUND_OF_FUNDS: 'Fund of Funds',
    GOVERNMENTAL_INSTITUTION :'Governmental Institution',
    INSURANCE: 'Insurance',
    PENSION_FUND: 'Pension Fund',
    SOVEREIGN_WEALTH_FUND: 'Sovereign Wealth Fund',
    OTHER: 'Other',
  },

  REGION_TEXT: {
    ASIA_PACIFIC:'APAC',
    CENTRAL_EASTERN_EUROPE:'CEE',
    LATIN_AMERICA:'LATAM',
    MIDDLE_EAST_AFRICA:'MEA',
    NORTH_AMERICA:'North America',
    WESTERN_EUROPE:'Western Europe',
    OTHER: 'Other',
  },

  INVESTMENT_STRATEGY: {
    BUYOUT: 'Buyout',
    DISTRESSED: 'Distressed',
    FUND_OF_FUNDS: 'Fund of Funds',
    MEZZANINE: 'Mezzanine',
    OTHER: 'Other',
    SECONDARIES: 'Secondaries',
    VENTURE_CAPITAL: 'Venture Capital',
    CO_INVESTMENTS: 'Co-investments',
    DEBT: 'Debt',
    TURNAROUND: 'Turnaround',
  },

  STRIPE: {
    PK: process.env.STRIPE_PK,
    PLAN_IDS: {
      MONTHLY: process.env.STRIPE_MONTHLY_ID,
      MONTHLY_VAT: process.env.STRIPE_MONTHLY_ID_VAT,
      ANNUAL: process.env.STRIPE_ANNUAL_ID,
      ANNUAL_VAT: process.env.STRIPE_ANNUAL_ID_VAT,

      DISCOUNTED_MONTHLY: process.env.STRIPE_DISCOUNTED_MONTHLY_ID,
      DISCOUNTED_MONTHLY_VAT: process.env.STRIPE_DISCOUNTED_MONTHLY_ID_VAT,
      DISCOUNTED_ANNUAL: process.env.STRIPE_DISCOUNTED_ANNUAL_ID,
      DISCOUNTED_ANNUAL_VAT: process.env.STRIPE_DISCOUNTED_ANNUAL_ID_VAT,
    }
  },
}
