import history from 'utils/history'

export default (transformUrl, path) =>
  (url) => ({
    path: url + path,
    exact: true,
    component: ({location}) => {
      history.replace(transformUrl(location.pathname).replace('//', '/'))
      return null
    },
  })
