import React from 'react'
import {Column, Flex} from 'components/layout/styled'
import {Headline} from 'components/typography'
import styled, {keyframes} from 'styled-components'
import PropTypes from 'prop-types'

const TabsWrapper = styled(Flex)`
  position: relative;
`

const Wrapper = styled.div`
  background-color: ${props => props.secondary ? props.theme.colors.grey1 : props.theme.colors.white};
`

const Tab = styled(Column).attrs(({secondary, navBar}) => ({
  height: (navBar || secondary) ? 35 : 50,
  pt: navBar && 5,
  justifyContent: navBar ? 'flex-start' : 'center',
}))`
  cursor: pointer;
`

const grow = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

const Stripe = styled.div`
  background-color: transparent;
  width: ${props => props.width}px;
  height: 2px;
  position: absolute;
  left: ${props => props.leftPosition}px;
  bottom: 0;
  transition: all 0.2s linear;
  pointer-events: none;
`

const StripeFill = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${props => props.theme.colors.primary};
  height: 100%;
  width: 100%;
  animation: ${grow} 200ms ease-out;
`

const PlaceholderText = styled(Headline)`
  opacity: 0;
  height: 0;
  overflow-y: hidden;
  pointer-events: none;
`

const ShowTabPure = React.forwardRef(
  ({tab, selectTab, hoverTab, resetHoverTab, selected, navBar, color, secondary}, ref) =>
    <div ref={ref}>
      <Tab
        selected={selected}
        alignItems="center"
        navBar={navBar}
        secondary={secondary}
        px={20}
        data-key={tab.key}
        onClick={selectTab}
        onMouseEnter={hoverTab}
        onMouseLeave={resetHoverTab}
      >
        <PlaceholderText textAlign="center" fontWeight={600}>{tab.name}</PlaceholderText>
        <Headline
          nowrap
          textAlign="center"
          color={color || (selected ? 'primary' : 'grey7')}
          fontWeight={selected ? 600 : 500}
        >
          {tab.name}
        </Headline>
      </Tab>
    </div>)

ShowTabPure.propTypes = {
  tab: PropTypes.object.isRequired,
  selectTab: PropTypes.func.isRequired,
  hoverTab: PropTypes.func.isRequired,
  resetHoverTab: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  navBar: PropTypes.bool,
  secondary: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

const ShowTab = React.memo(ShowTabPure)

class TabBar extends React.PureComponent {
  tabRefs = this.props.tabs.reduce((accumulator, tab) => {
    accumulator[tab.key] = React.createRef()
    return accumulator
  }, {})

  appearTimeout
  state = {
    hovered: null,
    appearStripe: false,
  }

  componentDidMount() {
    this.appearTimeout = setTimeout(() => this.setState({
      appearStripe: true,
    }), 500)
  }

  componentWillUnmount() {
    clearTimeout(this.appearTimeout)
  }

  hoverTab = (key) => {
    this.setState({hovered: key})
  }

  resetHoverTab = () => {
    this.setState({hovered: null})
  }

  render() {
    const {hovered, appearStripe} = this.state
    const {navBar, tabState, tabs, tabBarStyle, secondary} = this.props
    const selected = tabState?.selected
    const hoveredTab = hovered ? this.tabRefs[hovered]?.current : this.tabRefs[selected]?.current
    return (
      <Wrapper style={tabBarStyle} secondary={secondary}>
        <TabsWrapper justifyContent="flex-start">
          {tabs.map(tab =>
            <ShowTab
              key={tab.key}
              tab={tab}
              color={tab.color}
              ref={this.tabRefs[tab.key]}
              hoverTab={() => this.hoverTab(tab.key)}
              resetHoverTab={this.resetHoverTab}
              selectTab={() => tabState.change(tab.key)}
              selected={tab.key === tabState?.selected}
              navBar={navBar}
              secondary={secondary}
            />,
          )}
          {appearStripe
          && <Stripe
            leftPosition={hoveredTab?.offsetLeft + 5 || 0}
            width={hoveredTab?.offsetWidth - 10 || 0}
          >
            <StripeFill />
          </Stripe>
          }
        </TabsWrapper>
      </Wrapper>
    )
  }
}

TabBar.propTypes = {
  navBar: PropTypes.bool,
  secondary: PropTypes.bool,
  tabState: PropTypes.object,
  tabBarStyle: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default TabBar
