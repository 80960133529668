import React from 'react'
import {omitProps} from 'utils/props'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AbsolutePositionRenderer from 'components/layout/AbsolutePositionRenderer'
import {Div} from 'components/layout/styled'

const blacklist = ['position', 'offset', 'watchOutsideClick']

const Box = styled(omitProps(blacklist, Div))`
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;
  flex-shrink: 0;
  ${props => props.theme.darkerShadow}
`

const Dropdown = ({children, deletePopUp, onResize, ...props}) => {
  return (
    <Box {...props}>
      {children instanceof Function ? children({deletePopUp}) : children}
    </Box>
  )
}

Dropdown.propTypes = {
  deletePopUp: PropTypes.func,
  arrowPosition: PropTypes.object,
  children: PropTypes.any,
  onResize: PropTypes.func,
}

export default AbsolutePositionRenderer(Dropdown, {defaultPosition: 'bottom'})
