import React from 'react'
import styled from 'styled-components'

const NotificationLabel = styled(({children, ...props}) => <div {...props}><div>{children}</div></div>)`
  background: ${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.alertRed};
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.white};
  position: absolute;
  border-radius: 30px;
  top: 0;
  padding: 0 2px;
  right: 0;
  transform: translate3d(40%,-40%,0);
  min-width: 12px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 600;
  line-height: 1;
  > div {
    text-indent: -.05em;
  }
`

export default NotificationLabel
