// We only need to import the modules necessary for initial render
import FullLayout from 'layouts/FullLayout'

import Login from './Login'
import PasswordReset from './PasswordReset'
import SignUp from './SignUp'
import PasswordResetChange from './PasswordResetChange'
import {createSubroutes} from './createSubroutes'

const createAnonymousRoutes = () => createSubroutes('/', [
  {
    path        : '',
    component   : FullLayout,
    routes      : [
      PasswordReset,
      PasswordResetChange,
      SignUp,
      Login,
    ],
  },
])

export default createAnonymousRoutes
