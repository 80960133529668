import {hot, setConfig} from 'react-hot-loader'
import React from 'react'
// import mouseflow from 'mouseflow'
import {ThemeProvider} from 'styled-components'
import PropTypes from 'prop-types'
import {ApolloProvider} from 'react-apollo'
import client from 'apollo'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import GlobalStyle from 'styles/globalStyle'
import theme from 'styles/styled'
import {Normalize} from 'styled-normalize'

setConfig({
  pureSFC: true,
  logLevel: 'error',
})

export const Main = ({App, ...props}) => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <Normalize />
        <App {...props} />
        <GlobalStyle />
      </ErrorBoundary>
    </ApolloProvider>
  </ThemeProvider>
)

Main.propTypes = {
  App: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
}

/*
if (__PROD__) {
  mouseflow()
}
*/

export default hot(module)(Main)
