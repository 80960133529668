import React from 'react'
import PropTypes from 'prop-types'
import {Column} from 'components/layout/styled'
import Dropdown from 'components/layout/Dropdown'
import ThreeDotsButton from 'components/buttons/ThreeDotsButton'

const StyledThreeDotsButtonWithDropdown = ({
  menuContent,
  width,
  height,
  defaultPosition,
  preferredArrowPosition,
  onClick,
  setOpen,
  ...props
}) => {
  return (<Dropdown
    py={10}
    px={20}
    offset={((defaultPosition === 'bottom') || (defaultPosition === 'top')) && (-((height - 30) / 2))}
    defaultPosition={defaultPosition}
    preferredArrowPosition={preferredArrowPosition}
    watchOutsideClick
    onToggle={open => setOpen?.(open)}
    target={({createPopUp, deletePopUp, targetRef, isOpen}) =>
      <ThreeDotsButton
        width={width}
        height={height}
        ref={targetRef}
        onClick={() => {
          if (typeof onClick === 'function') onClick()
          isOpen ? deletePopUp() : createPopUp()
        }}
        {...props}
      />
    }>
    <Column>
      {menuContent}
    </Column>
  </Dropdown>)
}

StyledThreeDotsButtonWithDropdown.propTypes = {
  menuContent: PropTypes.any,
  height: PropTypes.number,
  width: PropTypes.number,
  hover: PropTypes.bool,
  defaultPosition: PropTypes.string,
  preferredArrowPosition: PropTypes.string,
  onClick: PropTypes.func,
  setOpen: PropTypes.func,
}

StyledThreeDotsButtonWithDropdown.defaultProps = {
  height: 50,
  width: 50,
  defaultPosition: 'bottom',
}

export default StyledThreeDotsButtonWithDropdown
