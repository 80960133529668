import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DefaultInputStyle from 'components/form/DefaultInputStyle'
import CalendarIcon from 'static/svg/ico/ico_ui_calendar.svg'

const StyledInput = styled.input`
  width: ${props => props.year ? 50 : 30}px;
  color: ${props => props.theme.colors.grey8};
  text-align: center;
  ${props => props.theme.hollowInputStyle}
`

const Icon = styled(CalendarIcon)`
  width: 15px;
  fill: ${props => props.theme.colors.grey5};
  height: auto;
`

const yearBetween = (value, start, end) => {
  const numberYear = parseInt(value)
  const length = value.length
  const divider = Math.pow(10, 4 - length)
  return (numberYear >= Math.floor(start / divider) && numberYear <= Math.floor(end / divider))
}

class DateInput extends React.Component {
  state = {
    firstSlashIndex: 0,
    secondSlashIndex: 0,
    error: false,
  }

  first = React.createRef()
  second = React.createRef()
  third = React.createRef()

  componentDidMount() {
    this.props.onMount(this)
    const firstSlashIndex = this.props.value.indexOf('/')
    const secondSlashIndex = this.props.value.indexOf('/', firstSlashIndex + 1)
    this.setState({
      firstSlashIndex,
      secondSlashIndex,
    })
  }

  componentWillUnmount() {
    this.props.onMount(undefined)
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && prevProps.value !== this.props.value) {
      const firstSlashIndex = this.props.value.indexOf('/')
      const secondSlashIndex = this.props.value.indexOf('/', firstSlashIndex + 1)
      this.setState({
        firstSlashIndex,
        secondSlashIndex,
      })
    }

    if (!prevProps.focused && this.props.focused) {
      this.props.createPopUp('top', 'center')
    }
    if (prevProps.focused && !this.props.focused) {
      this.props.deletePopUp()
    }
  }

  mergeDate = () => {
    return `${this.first.current.value}/${this.second.current.value}/${this.third.current.value}`
  }

  handleKeyPress = (event) => {
    // only if enter, backspace, or tab
    if (![8, 13, 9].includes(event.keyCode)) return
    if (event.keyCode === 8) {
      // handle backspace
      if (event.target.selectionStart !== 0) return
      if (event.target.selectionStart !== event.target.selectionEnd) return
      if (event.target === this.third.current) {
        this.second.current.focus()
      }
      if (event.target === this.second.current) {
        this.first.current.focus()
      }
    } else {
      // handle enter
      if (event.target === this.first.current) {
        event.preventDefault()
        this.second.current.focus()
      }
      if (event.target === this.second.current) {
        event.preventDefault()
        this.third.current.focus()
      }
      if (event.target === this.third.current) {
        this.third.current.blur()
        this.props.closeCalendar()
      }
    }
  }

  onBlur = (event) => {
    const numericValue = parseInt(event.target.value)
    if (event.target === this.third) {
      if (numericValue < 1800) {
        event.target.value = ''
        this.onChange(event, true)
      }
    } else {
      if (numericValue === 0) {
        event.target.value = ''
        this.onChange(event, true)
      }
    }
    if (event.target.value.length === 1) {
      event.target.value = ('0').concat(event.target.value)
      this.onChange(event, true)
    }
  }

  onFocus = (event) => {
    event.persist()
    setTimeout(() => event.target.setSelectionRange(0, event.target.value.length), 0)
    this.props.onFocus(event)
  }

  triggerError = () => {
    this.setState({error: true}, () => setTimeout(() => this.setState({error: false}), 200))
  }

  focusNext = (target) => {
    if (target === this.first.current && target.value.length === 2) {
      this.second.current.focus()
    }
    if (target === this.second.current && target.value.length === 2) {
      this.third.current.focus()
    }
    if (target === this.third.current && target.value.length === 4) {
      this.first.current.focus()
    }
  }

  onChange = (event, onBlur = false) => {
    if (!event.target.value.match(/^[0-9]*$/g)) {
      this.triggerError()
      return
    }
    const numericValue = parseInt(event.target.value)
    const changedArray = this.props.changedArray
    if (event.target === this.first.current) {
      const regex = /^[4-9]$/g
      if (event.target.value.match(regex)) event.target.value = ('0').concat(event.target.value)
      if (numericValue > 3 && numericValue <= 9) event.target.value = '0'.concat(numericValue)
      if (event.target.value.length >= 2) {
        if (numericValue > 0 && numericValue <= 31) {
          event.target.value = event.target.value.substring(0, 2)
        } else {
          this.triggerError()
          return
        }
      }
      changedArray[0] = true
      this.props.onChange(this.mergeDate())
    }
    if (event.target === this.second.current) {
      const regex = /^[2-9]$/g
      if (event.target.value.match(regex)) event.target.value = ('0').concat(event.target.value)
      if (event.target.value.length >= 2) {
        if (numericValue > 0 && numericValue <= 12) {
          event.target.value = event.target.value.substring(0, 2)
        } else {
          this.triggerError()
          return
        }
      }
      changedArray[1] = true
      this.props.onChange(this.mergeDate())
    }
    if (event.target === this.third.current) {
      if (!yearBetween(event.target.value, 1800, 2050) && event.target.value) {
        this.triggerError()
        return
      }
      if (event.target.value.length >= 4) {
        event.target.value = event.target.value.substring(0, 4)
      }
      changedArray[2] = true
      this.props.onChange(this.mergeDate())
    }
    if (
      !changedArray.includes(false)
      && (
        this.first.current.value.length === 2
        && this.second.current.value.length === 2
        && this.third.current.value.length === 4
      )
    ) {
      event.target.blur()
    } else if (!onBlur) {
      this.focusNext(event.target)
    }
    event.persist()
  }

  render() {
    const focus = (event) => {
      if (
        event.target !== this.first.current
        && event.target !== this.second.current
        && event.target !== this.third.current) {
        this.first.current.focus()
      }
    }
    return (
      <DefaultInputStyle
        style={{cursor: 'pointer', paddingLeft: 10}}
        tabIndex={this.props.tabIndex || 0}
        ref={this.props.forwardRef}
        alignItems="center"
        justifyContent="space-between"
        valid={this.props.isValid}
        active={this.props.focused}
        error={this.state.error || this.props.error}
        onFocus={focus}
        onClick={focus}
        small={this.props.small}
      >
        <div>
          <StyledInput
            tabIndex={-1}
            onKeyDown={this.handleKeyPress}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={this.props.value?.substring(0, this.state.firstSlashIndex) || ''}
            ref={this.first}
            placeholder="DD"
          />/
          <StyledInput
            tabIndex={-1}
            onKeyDown={this.handleKeyPress}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={this.props.value?.substring(this.state.firstSlashIndex + 1, this.state.secondSlashIndex) || ''}
            ref={this.second}
            placeholder="MM"
          />/
          <StyledInput
            tabIndex={-1}
            onKeyDown={this.handleKeyPress}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={this.props.value?.substring(this.state.secondSlashIndex + 1, this.props.value.length) || ''}
            ref={this.third}
            year
            placeholder="YYYY"
          />
        </div>
        <Icon />
      </DefaultInputStyle>
    )
  }
}

DateInput.propTypes = {
  error: PropTypes.any,
  changedArray: PropTypes.array,
  isValid: PropTypes.bool,
  closeCalendar: PropTypes.func,
  forwardRef: PropTypes.func,
  deletePopUp: PropTypes.func,
  createPopUp: PropTypes.func,
  tabIndex: PropTypes.number,
  small: PropTypes.bool,
  focused: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onMount: PropTypes.func,
}

export default DateInput
