import styled, {keyframes, css} from 'styled-components'
import {space} from 'styled-system'
import {omitProps} from 'utils/props'
import {Flex} from 'components/layout/styled'

const glow = keyframes`
    50% {
      box-shadow: 0 0 0 1px ${props => props.theme.colors.alertGreen};
    }
    0%, 100% {
      box-shadow: 0 0 0 0 ${props => props.theme.colors.alertGreen};
    }
`

const shake = keyframes`
  8%, 41% {
    transform: translateX(-3px);
  }
  25%, 58% {
    transform: translateX(3px);
  }
  75% {
    transform: translateX(-1px);
  }
  92% {
    transform: translateX(1px);
  }
  0%, 100% {
    transform: translateX(0);
  }
`

const DefaultInputStyle = styled(omitProps(['active', 'error', 'valid', 'warning', 'small', 'noBorder', 'autoHeight'], Flex))`
  position: relative;
  outline: none !important;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.grey8};
  height: ${props => props.small ? 30 : 36}px;
  ${props => props.autoHeight && css`
    height: auto;
  `};
  font-size: ${props => props.small ? 12 : 14}px;
  border-radius: 4px;
  box-sizing: content-box;
  padding-left: 15px;
  padding-right: 15px;
  transition: border-color 100ms linear, box-shadow 100ms linear;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.grey5};
  width: auto;
  &:hover {
    border-color: ${props => props.theme.colors.grey7};
  }
  ${props => props.active && css`
    border-color: ${props.theme.colors.primaryLight} !important;
    box-shadow: 0 0 0 1px ${props.theme.colors.primaryLight};
  `}
  ${props => props.disabled && css`
    background-color: ${props.theme.colors.grey2};
    pointer-events: none;
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
  `}
  ${props => props.valid && css`
    animation: ${glow} 0.2s linear;
  `}
  ${props => props.warning && css`
    border-color: ${props.theme.colors.alertYellow} !important;
    box-shadow: 0 0 4px 1px rgba(215,150,56,0.6);
    animation: ${shake} 0.1s linear;
  `}
  ${props => props.error && css`
    border-color: ${props.theme.colors.alertRed} !important;
    box-shadow: 0 0 0 1px ${props.theme.colors.alertRed};
    animation: ${shake} 0.2s linear;
  `}
  ${props => props.noBorder && css`
    border: 0;
    box-shadow: none;
  `}
  ${space}
`

export default DefaultInputStyle
