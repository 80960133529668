import {css} from 'styled-components'
import createGlobalStyle from './createGlobalStyle'

const GothamFonts = css`
  /* Font: Gotham, Style: normal */

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Thin.woff2') format('woff2'),
          url('/static/font/Gotham-Thin.woff') format('woff'),
          url('/static/font/Gotham-Thin.ttf') format('truetype'),
          url('/static/font/Gotham-Thin.svg#Gotham-Thin') format('svg');
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Black.woff2') format('woff2'),
          url('/static/font/Gotham-Black.woff') format('woff'),
          url('/static/font/Gotham-Black.ttf') format('truetype'),
          url('/static/font/Gotham-Black.svg#Gotham-Black') format('svg');
      font-weight: 900;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Ultra.woff2') format('woff2'),
          url('/static/font/Gotham-Ultra.woff') format('woff'),
          url('/static/font/Gotham-Ultra.ttf') format('truetype'),
          url('/static/font/Gotham-Ultra.svg#Gotham-Ultra') format('svg');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Light.woff2') format('woff2'),
          url('/static/font/Gotham-Light.woff') format('woff'),
          url('/static/font/Gotham-Light.ttf') format('truetype'),
          url('/static/font/Gotham-Light.svg#Gotham-Light') format('svg');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Medium.woff2') format('woff2'),
          url('/static/font/Gotham-Medium.woff') format('woff'),
          url('/static/font/Gotham-Medium.ttf') format('truetype'),
          url('/static/font/Gotham-Medium.svg#Gotham-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Book.woff2') format('woff2'),
          url('/static/font/Gotham-Book.woff') format('woff'),
          url('/static/font/Gotham-Book.ttf') format('truetype'),
          url('/static/font/Gotham-Book.svg#Gotham-Book') format('svg');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/static/font/Gotham-Bold.woff2') format('woff2'),
          url('/static/font/Gotham-Bold.woff') format('woff'),
          url('/static/font/Gotham-Bold.ttf') format('truetype'),
          url('/static/font/Gotham-Bold.svg#Gotham-Bold') format('svg');
      font-weight: bold;
      font-style: normal;
  }


  /* Font: Gotham Narrow, Style: normal */

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Medium.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Medium.woff') format('woff'),
          url('/static/font/GothamXNarrow-Medium.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Medium.svg#GothamXNarrow-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Ultra.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Ultra.woff') format('woff'),
          url('/static/font/GothamXNarrow-Ultra.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Ultra.svg#GothamXNarrow-Ultra') format('svg');
      font-weight: 900;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-ExtraLight.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-ExtraLight.woff') format('woff'),
          url('/static/font/GothamXNarrow-ExtraLight.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-ExtraLight.svg#GothamXNarrow-ExtraLight') format('svg');
      font-weight: 200;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Thin.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Thin.woff') format('woff'),
          url('/static/font/GothamXNarrow-Thin.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Thin.svg#GothamXNarrow-Thin') format('svg');
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Book.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Book.woff') format('woff'),
          url('/static/font/GothamXNarrow-Book.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Book.svg#GothamXNarrow-Book') format('svg');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Bold.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Bold.woff') format('woff'),
          url('/static/font/GothamXNarrow-Bold.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Bold.svg#GothamXNarrow-Bold') format('svg');
      font-weight: bold;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Light.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Light.woff') format('woff'),
          url('/static/font/GothamXNarrow-Light.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Light.svg#GothamXNarrow-Light') format('svg');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Gotham Narrow';
      src: url('/static/font/GothamXNarrow-Black.woff2') format('woff2'),
          url('/static/font/GothamXNarrow-Black.woff') format('woff'),
          url('/static/font/GothamXNarrow-Black.ttf') format('truetype'),
          url('/static/font/GothamXNarrow-Black.svg#GothamXNarrow-Black') format('svg');
      font-weight: 900;
      font-style: normal;
  }

`

const FontSmoothing = css`
  html {background: #f1f4fa;}
  html, body {
    /* Adjust font size */

    font-size: 100%;
    -webkit-text-size-adjust: 100%;

    /* Font variant */

    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;

    /* Smoothing */

    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

    font-family: 'Gotham', Verdana, Geneva, Arial, Helvetica, sans-serif;
    text-decoration: none;
  }
  ::-moz-selection { background: #ffed55; color: #000; }
  ::selection { background: #ffed55; color: #000; }
  a {
    text-decoration: none;
  }
  @media print {
    html {
      background: none !important;
    }
  }
`

const RootStyles = css`
  #root {
    position: relative;
    z-index: 0;
  }
`

const ReactGridLayoutExtraStyle = css`
  .react-grid-item.react-grid-placeholder {
    background: #b9c3cd !important;
  }
`

export const BodyOverflowHidden = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const GlobalStyle = createGlobalStyle`
  ${GothamFonts}
  ${FontSmoothing}
  ${ReactGridLayoutExtraStyle}
  ${RootStyles}`

export default GlobalStyle
