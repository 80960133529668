import styled from 'styled-components'
import EmptyIcon from 'static/svg/ico/ico_ui_empty.svg'

const StyledEmptyIcon = styled(EmptyIcon)`
  width: 150px;
  height: auto;
  fill: ${props => props.theme.colors.grey5};
`

export default StyledEmptyIcon
