import {createBrowserHistory} from 'history'

const history = createBrowserHistory()

history.pushOrNewTab = (e, to) => {
  if (
    e.metaKey
    || e.ctrlKey
    || (e.which === 2 || e.button === 4)
  ) {
    window.open(to)
  } else {
    history.push(to)
  }
}

export default history
