import React from 'react'
import PropTypes from 'prop-types'
import ErrorComponent from 'components/errors/SomethingWentWrongError'
import compose from 'compose'
import {graphql} from 'react-apollo'
import gql from 'graphql-tag'

const LOG_ERROR = gql`
  mutation logError ($input: ErrorInput!) {
    logError (input: $input)
  }
`

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    info: null,
    prevChildren: this.props.children,
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true, error, info})
    this.props.logError({
      variables: {
        input: {
          name: error?.name || 'Unknown error name.',
          message: error?.message || 'Unknown error message.',
          stack: `${error.stack}\n${info.componentStack}` || "Stack isn't available.",
          url: window.location.href,
        },
      },
    })
    if (this.props.onError) {
      this.props.onError(error, info)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.prevChildren !== nextProps.children && nextProps.resetOnChildrenChange) {
      return {
        hasError: false,
        prevChildren: nextProps.children,
      }
    }

    return {
      prevChildren: nextProps.children,
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent
          error={this.state.error}
          info={this.state.info}
        />
      )
    }
    return this.props.children
  }

  static propTypes = {
    children: PropTypes.any.isRequired,
    logError: PropTypes.func.isRequired,
    onError: PropTypes.func,
    resetOnChildrenChange: PropTypes.bool,
  }

  static defaultProps = {
    resetOnChildrenChange: true,
  }
}

export default compose(
  graphql(LOG_ERROR, {
    name: 'logError',
    options: {
      update: (cache, {data}) =>
        console.log(data?.logError ? 'Error message has been successfully logged' : 'Error message failed to log'),
    },
  }),
)(ErrorBoundary)
