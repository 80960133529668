import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import AddToBottom from 'components/AddToBottom'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import {CSSTransition} from 'react-transition-group'
import isDescendant from 'utils/isDescendant'

const PAGE_MARGIN = 10

const getOrder = (defaultPosition, allowedPositions = ['top', 'right', 'bottom', 'left']) => {
  const defaultOrder = ['top', 'right', 'bottom', 'left'].filter(x => allowedPositions.includes(x))

  if (!defaultOrder.length) {
    throw new Error('No positions are allowed')
  }

  const index = defaultOrder.indexOf(defaultPosition)

  if (index === -1) {
    throw new Error('Bad position as default position')
  }

  return defaultOrder.slice(index).concat(defaultOrder.slice(0, index))
}

const fitTop = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    height: popUpHeight,
  } = popUp
  const {
    top: targetTop,
  } = target

  if (targetTop - (offset + popUpHeight) < 0 + pageMargin) {
    return false
  }

  return fitArrowVertical(page, target, popUp, offset, pageMargin)
}

const fitBottom = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    height: popUpHeight,
  } = popUp
  const {
    bottom: targetBottom,
  } = target
  const {
    height: pageHeight,
  } = page

  if (pageHeight - pageMargin < popUpHeight + offset + targetBottom) {
    return false
  }

  return fitArrowVertical(page, target, popUp, offset, pageMargin)
}

const fitRight = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    width: popUpWidth,
  } = popUp
  const {
    right: targetRight,
  } = target
  const {
    width: pageWidth,
  } = page

  if (pageWidth - pageMargin < popUpWidth + offset + targetRight) {
    return false
  }

  return fitArrowHorizontal(page, target, popUp, offset, pageMargin)
}

const fitLeft = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    width: popUpWidth,
  } = popUp
  const {
    left: targetLeft,
  } = target

  if (targetLeft - (popUpWidth + offset) < 0 + pageMargin) {
    return false
  }

  return fitArrowHorizontal(page, target, popUp, offset, pageMargin)
}

const fitArrowHorizontal = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    arrowTop,
  } = calculateHorizontalTopPositions(page, target, popUp, offset, pageMargin)

  return (arrowTop > offset) // check arrow space
    && (arrowTop < popUp.height - offset)
}

const fitArrowVertical = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    arrowLeft,
  } = calculateVerticalLeftPositions(page, target, popUp, offset, pageMargin)

  return (arrowLeft > offset) // check arrow space
    && (arrowLeft < popUp.width - offset)
}

const fitMap = {
  right: fitRight,
  left: fitLeft,
  bottom: fitBottom,
  top: fitTop,
}

const leftOpts = {
  scrollPos: 'scrollLeft',
  size: 'width',
  start: 'left',
  end: 'right',
}

const calculateVerticalLeftPositions = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    arrow: arrowLeft,
    position: left,
  } = calculatePositions(page, target, popUp, offset, pageMargin, leftOpts)

  return {arrowLeft, left}
}

const topOpts = {
  scrollPos: 'scrollTop',
  size: 'height',
  start: 'top',
  end: 'bottom',
}

const calculateHorizontalTopPositions = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {
    arrow: arrowTop,
    position: top,
  } = calculatePositions(page, target, popUp, offset, pageMargin, topOpts)

  return {arrowTop, top}
}

const calculatePositions = (page, target, popUp, offset, pageMargin, {scrollPos, size, start, end}) => {
  const popUpHalf = Math.floor((popUp[size] + 1) / 2) // half of popUp width
  const targetHalf = Math.floor((target[size] + 1) / 2) // half of target width

  let position = page[scrollPos] + target[start] + targetHalf - popUpHalf
  const minPosition = page[scrollPos] + 0 + pageMargin
  const maxPosition = page[scrollPos] + page[size] - popUp[size] - pageMargin

  const pageSizeWitoutMargin = page[size] - 2 * pageMargin
  let positionEnd = position + popUp[size]
  const borderEnd = page[scrollPos] + page[size] - pageMargin
  const borderStart = page[scrollPos] + 0 + pageMargin

  if (popUp[size] > pageSizeWitoutMargin) { // We don't fit anywhere
    position = Math.floor((minPosition + maxPosition) / 2)
  } else if (positionEnd > borderEnd) { // We don't fit at the end
    position = maxPosition
  } else if (position < borderStart) { // We don't fit at the beginning
    position = minPosition
  }

  positionEnd = position + popUp[size] // adjust positionEnd
  const realTargetRight = target[end] + page[scrollPos] // adjusted target positionEnd
  const realTargetPosition = target[start] + page[scrollPos] // adjusted target position

  const arrowPositionBoundary = Math.max(position, realTargetPosition)
  const arrowPositionEnd = Math.min(positionEnd, realTargetRight)
  const arrowCenter = Math.floor(arrowPositionBoundary + arrowPositionEnd) / 2

  const arrow = arrowCenter - position

  return {arrow, position}
}

const getPositionTop = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {left, arrowLeft} = calculateVerticalLeftPositions(page, target, popUp, offset, pageMargin)

  return {
    id: 'top',
    top: page.scrollTop + target.top - offset - popUp.height,
    left,
    arrowPosition: {
      arrowTop: '100%',
      arrowLeft: `${arrowLeft}px`,
    },
  }
}

const getPositionRight = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {top, arrowTop} = calculateHorizontalTopPositions(page, target, popUp, offset, pageMargin)

  return {
    id: 'right',
    top,
    left: page.scrollLeft + target.left + target.width + offset,
    arrowPosition: {
      arrowTop: `${arrowTop}px`,
      arrowLeft: 0,
    },
  }
}

const getPositionBottom = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {left, arrowLeft} = calculateVerticalLeftPositions(page, target, popUp, offset, pageMargin)

  return {
    id: 'bottom',
    top: page.scrollTop + target.top + target.height + offset,
    left,
    arrowPosition: {
      arrowTop: 0,
      arrowLeft: `${arrowLeft}px`,
    },
  }
}

const getPositionLeft = (page, target, popUp, offset, pageMargin = PAGE_MARGIN) => {
  const {top, arrowTop} = calculateHorizontalTopPositions(page, target, popUp, offset, pageMargin)

  return {
    id: 'left',
    top,
    left: page.scrollLeft + target.left - offset - popUp.width,
    arrowPosition: {
      arrowTop: `${arrowTop}px`,
      arrowLeft: '100%',
    },
  }
}

const getPositionMap = {
  top: getPositionTop,
  right: getPositionRight,
  bottom: getPositionBottom,
  left: getPositionLeft,
}

const AbsolutePositionRenderer = (Component, {defaultPosition = 'top', allowedPositions, timeout = 0} = {}) => {
  const order = getOrder(defaultPosition, allowedPositions)
  const defaultPositionObject = {
    id: defaultPosition,
    top: -1000,
    left: -1000,
    arrowPosition: {
      arrowTop: '100%',
      arrowLeft: 0,
    },
  }

  const AbsolutePositionedDiv = styled.div`
    position: absolute;
    ${props => props.noPointerEvents && css`pointer-events: none;`};
  `

  return class AbsolutePosition extends React.Component {
    static defaultProps = {
      offset: 10,
      defaultPosition,
      preferredArrowPosition: 'middle',
      onToggle: () => {},
    }

    static propTypes = {
      preferredArrowPosition: PropTypes.oneOf(['start', 'end', 'middle']),
      offset: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
      ]),
      watchOutsideClick: PropTypes.bool,
      defaultPosition: PropTypes.oneOf(order),
      target: PropTypes.any,
      noPointerEvents: PropTypes.bool,
      onToggle: PropTypes.func,
    }

    state = {
      position: defaultPositionObject,
      open: false,
    }

    target = null
    popUp = null
    umounted = false

    componentWillUnmount() {
      window.removeEventListener('mousedown', this.watchClick)
      window.removeEventListener('scroll', this.onResize)
      this.umounted = true
    }

    getPositionDetails = (position) => {
      if (!this.target || !this.popUp) {
        return {
          ...defaultPositionObject,
          id: position,
        }
      }
      const target = this.target.getBoundingClientRect()
      const page = {
        scrollTop: window.pageYOffset || document.documentElement.scrollTop,
        scrollLeft: window.pageXOffset || document.documentElement.scrollLeft,
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth,
      }

      return getPositionMap[position](
        page,
        target,
        this.popUp.getBoundingClientRect(),
        this.props.offset)
    }

    deletePopUp = () => {
      if (this.umounted) return
      this.setState({
        open: false,
      })
      window.removeEventListener('scroll', this.onResize)
    }

    createPopUp = (position) => {
      if (this.umounted) return

      window.addEventListener('scroll', this.onResize, true)
      this.setState({
        position: this.getPositionDetails(this.props.defaultPosition, 0),
        open: true,
      })
    }

    innerClick = false

    watchClick = (event) => {
      if (isDescendant(this.target, event.target)) return
      if (!this.innerClick) {
        this.deletePopUp()
      }
      this.innerClick = false
    }

    watchInnerClick = (event) => {
      if (this.state.position && this.props.watchOutsideClick) {
        this.innerClick = true
      }
    }

    registerOutsideListener(prevOpen) {
      if (prevOpen !== this.state.open) {
        this.props.onToggle(this.state.open)
        if (this.state.open) {
          window.addEventListener('mousedown', this.watchClick)
        } else {
          window.removeEventListener('mousedown', this.watchClick)
        }
      }
    }

    changePosition() {
      if (!this.target || !this.popUp) {
        return
      }

      const popUp = this.popUp.getBoundingClientRect()
      const target = this.target.getBoundingClientRect()
      const page = {
        scrollTop: window.pageYOffset || document.documentElement.scrollTop,
        scrollLeft: window.pageXOffset || document.documentElement.scrollLeft,
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth,
      }

      const {position: newPosition, pageMargin} = (() => {
        let position = order.find((position) =>
          fitMap[position](page, target, popUp, this.props.offset))

        if (position) {
          return {position, pageMargin: PAGE_MARGIN}
        }

        position = order.find((position) =>
          fitMap[position](page, target, popUp, this.props.offset, 1))
        || defaultPosition

        return {position, pageMargin: 1}
      })()

      const newPositionObject = getPositionMap[newPosition](
        page,
        target,
        popUp,
        this.props.offset,
        pageMargin)

      if (
        newPosition === this.state.position?.id
        && newPositionObject.left === this.state.position?.left
        && newPositionObject.top === this.state.position?.top) {
        return
      }
      this.setState({position: newPositionObject})
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.watchOutsideClick) {
        this.registerOutsideListener(prevState.open)
      }
      if (this.state.open) {
        this.changePosition()
      }
    }

    onResize = () => {
      this.changePosition()
    }

    createPopUpHandler = () => this.createPopUp(this.props.defaultPosition)
    setTargetRef = node => { this.target = node }
    setPopUpRef = node => {
      if (this.popUp !== node) {
        this.popUp = node
        this.changePosition()
      }
    }

    render() {
      const {defaultPosition, target, noPointerEvents, ...props} = this.props
      const position = this.state.position
      return (
        <>
          {target instanceof Function ? target({
            deletePopUp: this.deletePopUp,
            createPopUp: this.createPopUpHandler,
            isOpen: this.state.open,
            target: this.target,
            targetRef: this.setTargetRef,
          }) : target}
          <CSSTransition
            onMouseDown={this.watchInnerClick}
            timeout={timeout}
            mountOnEnter
            unmountOnExit
            classNames="popup"
            in={this.state.open}
          >
            <AddToBottom>
              <ErrorBoundary>
                <AbsolutePositionedDiv
                  noPointerEvents={noPointerEvents}
                  ref={this.setPopUpRef}
                  style={{
                    left: position.left,
                    top: position.top,
                  }}>
                  <Component
                    deletePopUp={this.deletePopUp}
                    onResize={this.onResize}
                    position={position.id}
                    target={this.target}
                    {...props}
                    arrowPosition={position.arrowPosition}
                  />
                </AbsolutePositionedDiv>
              </ErrorBoundary>
            </AddToBottom>
          </CSSTransition>
        </>
      )
    }
  }
}

export default AbsolutePositionRenderer
