import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {PageHeadline, Paragraph} from 'components/typography'
import {Column, Flex} from 'components/layout/styled'

const Holder = styled(Flex)`
  position: relative;
  height: 120px;
  span {
     color: ${props => props.theme.colors.alertBlue};
     font-weight: 700;
  }
  ${props => !props.preventHover && css`
    :hover {
      span {
         text-decoration: underline;
      }
    }`
}
`

const StyledInput = styled.input`
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const Area = styled(Column).attrs(() => ({px: 10}))`
  min-width: 0;
  background-color: ${props => props.theme.colors.grey2};
  border: ${props => props.draggedOver
    ? `2px solid ${props.theme.colors.alertBlue}`
    : `1px dashed ${props.theme.colors.grey4}`};
  border-radius: 4px;
  height: 120px;
`

class FileInput extends React.PureComponent {
  state = {
    draggedOver: false,
  }

  render() {
    const {onChange, inputRef, name, multi, ...props} = this.props
    return (
      <Holder preventHover={this.state.draggedOver} height={120} {...props}>
        <StyledInput
          onDragEnter={() => this.setState({draggedOver: true})}
          onDragLeave={() => this.setState({draggedOver: false})}
          onDrop={() => this.setState({draggedOver: false})}
          id="file-upload"
          ref={inputRef}
          type="file"
          multiple={multi}
          onChange={onChange}
        />
        <Area draggedOver={this.state.draggedOver} flex={1} alignItems="center" justifyContent="center">
          {name && <PageHeadline ellipses nowrap color="grey8">{name}</PageHeadline>}
          <Paragraph>Drag and drop the file here or <span>browse to upload</span></Paragraph>
        </Area>
      </Holder>
    )
  }
}

FileInput.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  multi: PropTypes.any,
}

export default FileInput
