import {Paragraph} from 'components/typography'
import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Flex} from 'components/layout/styled'
import AnimatedCheck from 'components/form/AnimatedCheck'

const CheckSquare = styled(Flex)`
  height: ${props => props.small ? 10 : 16}px;
  width: ${props => props.small ? 10 : 16}px;
  position: relative;
  border: ${props => props.small ? 1 : 2}px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.grey6};
  margin-right: 10px;
  cursor: pointer;
`

const InnerSquare = styled.div`
  height: ${props => props.small ? 6 : 8}px;
  width: ${props => props.small ? 6 : 8}px;;
  border: ${props => props.small ? 1 : 2}px ${props => props.theme.colors.white} solid;
  background-color: ${props => props.disabled ? props.theme.colors.grey6 : props.theme.colors.primary};
`

const PositionedAnimatedCheck = styled(AnimatedCheck)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
`

const CheckBoxWrapper = styled(Flex)`
  cursor: ${props => (props.disabled) ? 'default' : 'pointer'};
  flex-shrink: 0;
  border-radius: ${props => props.theme.borderRadius};
  ${props => props.disabled && css`
    ${CheckSquare} {
      border: ${props.small ? 1 : 2}px solid ${props.theme.colors.grey6} !important;
    }
  `};
  :hover {
    ${CheckSquare} {
      border: ${props => props.small ? 1 : 2}px solid ${props => props.theme.colors.primary};
    }
  }
`

const CheckBox = ({
  disabled,
  onClick,
  selected,
  halfSelected,
  text,
  nowrap,
  bold,
  small,
  ...props
}) => {
  const click = (disabled || (onClick == null)) ? () => null : () => onClick(selected)
  return <CheckBoxWrapper
    small={small}
    disabled={disabled}
    alignItems="center"
    onClick={click}
    {...props}
  >
    <CheckSquare
      mt={small && 3}
      small={small}
      noShrink
      selected={selected || halfSelected}
    >
      {selected && <PositionedAnimatedCheck
        animationOff
        height={small ? 2 : 3}
        width={small ? 5 : 7}
        lineWidth={small ? 1 : 2}
        color={disabled ? 'grey6' : 'primary'}
      />}
      {halfSelected && <InnerSquare disabled small={small} />}
    </CheckSquare>
    {text
    && <Paragraph
      fontWeight={bold ? 700 : 400}
      ellipses={nowrap}
      nowrap={nowrap}
      disableSelect
      fontSize={small ? 12 : 14}
      pt={1}
      color={disabled ? 'grey6' : 'grey8'}
    >
      {text}
    </Paragraph>}
  </CheckBoxWrapper>
}

CheckBox.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.any,
  halfSelected: PropTypes.any,
  text: PropTypes.node,
  nowrap: PropTypes.bool,
  bold: PropTypes.bool,
  small: PropTypes.bool,
}

export default CheckBox
