import React from 'react'
import PropTypes from 'prop-types'
import styled, {keyframes} from 'styled-components'
import {Text} from 'components/typography'
import Tooltip, {TooltipIcon} from 'components/layout/Tooltip'
import AnimatedCheck from 'components/form/AnimatedCheck'
import {Column, Flex} from 'components/layout/styled'

import {spacePropsSeparation} from 'utils/props'

const come = keyframes`
  from {
    padding-top: 0;
    transform: translate(0, 0%);
  }
  to {
    padding-top: 5px;
    transform: translate(0, 100%);
  }
`

export const BasicMessage = styled(Text).attrs(() => ({pt: 2}))`
  position: absolute;
  bottom: 0;
  padding-top: 3px;
  transform: translate(0, 100%);
  animation: ${come} 200ms ease-in;
`

export const WarningMessage = styled(BasicMessage)`
  color: ${props => props.theme.colors.alertYellow}
`

export const ErrorMessage = styled(BasicMessage)`
  color: ${props => props.theme.colors.alertRed}
`
BasicMessage.defaultProps = {
  fontSize: 10,
}

const Wrapper = styled(Column)`
  position: relative;
`

const showError = (meta, error) => error
  ? <ErrorMessage>{error}</ErrorMessage>
  : null
const showWarning = (warning) =>
  warning
    ? <WarningMessage>{warning}</WarningMessage>
    : null

class ComponentWrapperBody extends React.PureComponent {
  static propTypes = {
    Component: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.string,
    ]),
    fill: PropTypes.bool,
    forwardedRef: PropTypes.func,
    autoFocus: PropTypes.bool,
  }

  wrapperRef = React.createRef()

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => this.wrapperRef.current
        ?.querySelector('[href], input, select, textarea, [tabindex]:not([tabindex="-1"])')?.focus(), 600)
    }
  }

  render() {
    const {Component, forwardedRef, error, fill, ...props} = this.props
    return (
      <Wrapper fill={fill} ref={this.wrapperRef}>
        {showError(props.meta, error) || showWarning(props.warning)}
        <Component ref={forwardedRef} error={error} {...props} />
      </Wrapper>
    )
  }
}

const ComponentWrapper = React.forwardRef((props, ref) => {
  return <ComponentWrapperBody {...props} forwardedRef={ref} />
})

const ShowLabel = React.memo(({isValid, label, hint}) =>
  <Flex mb={5} justifyContent="space-between" alignItems="center">
    <Flex alignItems="center">
      <Text mr={5} fontWeight={500} color="grey8">{label}</Text>
      {isValid && <AnimatedCheck style={{margin: '0 0 3px 2px'}} color="alertGreen" />}
    </Flex>
    {hint && <Tooltip
      hint={hint}
      target={({createPopUp, deletePopUp, targetRef}) => <div
        onMouseEnter={createPopUp}
        onMouseLeave={deletePopUp}
        ref={targetRef}
      >
        <TooltipIcon />
      </div>}
    />}
  </Flex>,
)

ShowLabel.propTypes = {
  hint: PropTypes.node,
  label: PropTypes.string,
  isValid: PropTypes.bool,
}

const withInputWrapper = Component => {
  const InputWrapper = React.forwardRef(
    ({
      withWrapper,
      label,
      hint,
      datePickerField,
      fill,
      className,
      noValidation,
      ...props
    }, ref) => {
      let errorMessage
      let isValid
      let onSubmitError
      let error
      if (props.meta && props.input) {
        if (typeof props.meta.error === 'object' && props.meta.error[props.input.name]) {
          errorMessage = props.meta.error[props.input.name]
        } else if (props.meta.error) {
          errorMessage = props.meta.error
        }
        onSubmitError = !props.meta.dirtySinceLastSubmit && !props.meta.active && props.meta.submitError
        if (datePickerField && props.input.value.length === 10) {
          error = errorMessage
        } else {
          error = (props.meta.touched && !props.meta.active && errorMessage)
        }
        isValid = !noValidation && !(error || onSubmitError)
          && props.meta.touched && !props.meta.active && props.input.value && props.meta.dirty
      }
      if (withWrapper) {
        const {spaceProps, otherProps} = spacePropsSeparation(props)
        return (
          <Column className={(error || onSubmitError) && 'error'} {...spaceProps} fill={fill} pb={20}>
            <ShowLabel isValid={!!isValid} label={label} hint={hint} />
            <ComponentWrapper
              ref={ref}
              error={error || onSubmitError}
              Component={Component}
              isValid={!!isValid}
              className={className}
              {...otherProps}
            />
          </Column>
        )
      } else {
        return (
          <ComponentWrapper
            ref={ref}
            fill={fill}
            error={error || onSubmitError}
            Component={Component}
            isValid={!!isValid}
            {...props}
            className={`${className}${(error || onSubmitError) && ' error'}`}
          />)
      }
    })

  InputWrapper.propTypes = {
    withWrapper: PropTypes.bool,
    label: PropTypes.string,
    hint: PropTypes.node,
    datePickerField: PropTypes.bool,
    fill: PropTypes.bool,
    meta: PropTypes.object,
    input: PropTypes.object,
    className: PropTypes.string,
    noValidation: PropTypes.bool,
  }

  return InputWrapper
}

export default withInputWrapper
