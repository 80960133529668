import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Box} from 'components/layout/styled'

const SafariScrollableBox = ({whatToWatch, children, ...props}) => {
  const [boxHeight, setBoxHeight] = useState(null)
  const boxRef = useRef(null)
  const updateHeight = () => {
    if (boxRef.current?.firstChild.clientHeight > document.getElementById('scrollable-content').clientHeight - 94) {
      setBoxHeight(document.getElementById('scrollable-content').clientHeight - 94)
    }
  }
  useEffect(() => {
    updateHeight()
  }, whatToWatch)
  useEffect(() => {
    window.addEventListener('resize', updateHeight)
    return () => {
      setBoxHeight(null)
      window.removeEventListener('resize', updateHeight)
    }
  }, [])
  return <Box p={0} ref={boxRef} {...props} style={{height: boxHeight, flexShrink: 0, ...props.style}}>
    {children}
  </Box>
}

SafariScrollableBox.propTypes = {
  whatToWatch: PropTypes.array,
  children: PropTypes.any,
  style: PropTypes.object,
}

export default SafariScrollableBox
