import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'

import {spacePropsSeparation} from 'utils/props'
import {Text} from 'components/typography'
import DefaultInputStyle from 'components/form/DefaultInputStyle'
import withInputWrapper from 'components/form/withInputWrapper'
import {Div} from 'components/layout/styled'
import {BlankButton} from 'components/buttons/Button'
import SearchIcon from 'static/svg/ico/ico_ui_search.svg'

const InputWrapper = styled(DefaultInputStyle)`
  cursor: text;
`

const StyledInput = styled.input`
  flex: 1;
  max-width: 100%;
  ${props => props.theme.hollowInputStyle}
  ${props => props.small && css`
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  `}
`

const InputButton = styled(BlankButton)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate3d(0,-50%,0);
  font-size: 10px;
  border-radius: .2em;
  font-weight: 600;
  background: ${props => props.theme.colors.grey5};
  color: ${props => props.theme.colors.white};
  transition: background-color .2s ease;
  padding: 3px;
  &:hover {
    background: ${props => props.theme.colors.primary};
  }
  ${props => props.active ? `
    background: ${props.theme.colors.primary};
  ` : ''}
`

class Input extends React.Component {
  static propTypes = {
    maxLength: PropTypes.number,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    additionalOnChange: PropTypes.bool,
    suffix: PropTypes.string,
    prefix: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    isValid: PropTypes.bool,
    tabIndex: PropTypes.number,
  }

  state = {
    error: false,
    focused: false,
  }

  inputElement = React.createRef()
  errorTimeout

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.focused && this.state.focused) {
      if (this.props.input) this.props.input.onFocus()
    }
    if (prevState.focused && !this.state.focused) {
      if (this.props.input) this.props.input.onBlur()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.errorTimeout)
  }

  triggerError = () => {
    clearTimeout(this.errorTimeout)
    this.setState({error: true}, () => {
      this.errorTimeout = setTimeout(() => this.setState({error: false}), 200)
    })
  }

  onKeyPress = (event) => {
    const charCode = String.fromCharCode((typeof event.which === 'undefined') ? event.keyCode : event.which)
    if (!charCode.match(this.props.validValues)) {
      this.triggerError()
      event.preventDefault()
    }
  }

  onChange = (event) => {
    if (this.props.maxLength) {
      if (event.target.value?.length > this.props.maxLength) {
        this.triggerError()
        return
      }
    }
    this.props.input && this.props.input.onChange(event.target?.value || event.value)
    this.props.additionalOnChange && this.props.additionalOnChange(event.target?.value || event.value)
  }

  render() {
    const {
      type,
      formattedNumber,
      suffix,
      isValid,
      tabIndex,
      error,
      placeholder,
      disabled,
      small,
      prefix,
      forcedValue,
      input,
      name,
      ...props
    } = this.props
    return (
      <InputWrapper
        error={this.state?.error || error}
        active={this.state.focused}
        valid={isValid}
        disabled={disabled}
        alignItems="center"
        onClick={(event) => this.inputElement.current.focus(event)}
        small={small}
        {...props}>
        {prefix && <Text lineHeight={1} fontSize={15} color="grey6" mr={5} nowrap>{prefix}</Text>}
        <StyledInput
          type={type || input?.type}
          autoComplete="off"
          onFocus={() => this.setState({focused: true})}
          onBlur={() => this.setState({focused: false})}
          disabled={disabled}
          value={forcedValue ?? input?.value ?? props.value}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          ref={this.inputElement}
          tabIndex={tabIndex || 0}
          placeholder={placeholder}
          small={small}
          name={input?.name ?? name}
        />
        {suffix && <Text lineHeight={1} fontSize={15} color="grey6">{suffix}</Text>}
      </InputWrapper>
    )
  }
}

Input.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  validValues: PropTypes.any,
  formattedNumber: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  forcedValue: PropTypes.any,
  name: PropTypes.string,
}

Input.defaultProps = {
  value: '',
}

export const TextInput = withInputWrapper(
  (props) => (
    <Input
      validValues={/.*/g}
      {...props}
    />
  ),
)

export const NumberInput = withInputWrapper(
  (props) => (
    <Input
      validValues={/^([0-9\\.\\-])$/g}
      formattedNumber
      {...props}
    />
  ),
)

export const IntegerInput = withInputWrapper(
  (props) => (
    <Input
      validValues={/^([0-9\\-])$/g}
      formattedNumber
      {...props}
    />
  ),
)

export const PercentInput = withInputWrapper(
  (props) => (
    <Input
      validValues={/^([0-9\\.])$/g}
      formattedNumber
      maxValue={100}
      suffix="%"
      {...props}
    />
  ),
)

export const MoneyInput = withInputWrapper(
  (props) => (
    <Input
      validValues={/^([0-9\\.\\-])$/g}
      formattedNumber
      suffix="EUR"
      {...props}
    />
  ),
)

export const PasswordInput = withInputWrapper(
  (props) => {
    const [hidden, setHidden] = useState(true)
    const {spaceProps, otherProps} = spacePropsSeparation(props)

    return (
      <Div {...spaceProps}>
        <Input
          validValues={/.*/g}
          type={hidden ? 'password' : 'text'}
          {...otherProps}
        />
        <InputButton
          type="button"
          active={!hidden}
          onClick={() => setHidden(!hidden)}
        >
          {hidden ? 'show' : 'hide'}
        </InputButton>
      </Div>
    )
  },
)

const RelativeDiv = styled(Div)`
  position: relative;
`

const StyledSearchIcon = styled(({small, ...rest}) => <SearchIcon {...rest} />)`
  position: absolute;
  top: ${props => props.small ? 9 : 12}px;
  left: ${props => props.small ? 9 : 15}px;
  height: ${props => props.small ? 13 : 16}px;
  fill: ${props => props.theme.colors.grey5};
`

export const SearchInput = withInputWrapper(
  (props) => {
    const {spaceProps, otherProps} = spacePropsSeparation(props)
    return (
      <RelativeDiv {...spaceProps}>
        <Input
          pl={otherProps.small ? 25 : 40}
          validValues={/.*/g}
          type="search"
          {...otherProps}
        />
        <StyledSearchIcon small={otherProps.small} />
      </RelativeDiv>
    )
  },
)
