import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {LargeValue, Paragraph} from 'components/typography'
import {Column} from 'components/layout/styled'
import ErrorICon from 'static/svg/ico/ico_ui_error.svg'

const StyledErrorIcon = styled(ErrorICon)`
  width: 100px;
  height: 100px;
  fill: ${props => props.theme.colors.grey5};
`

const ErrorHolder = styled(Column)`
  width: 100%;
  max-width: 550px;
`

export const SomethingWentWrongError = ({error, info}) => {
  console.error(error, info)
  return (
    <Column flex={1} alignItems="center" justifyContent="center" height="100%">
      <ErrorHolder alignItems="center" px={10} py={20}>
        <StyledErrorIcon />
        <LargeValue mt={20} fontWeight="bold" textAlign="center" color="grey5">Something went wrong, sorry</LargeValue>
        <Paragraph mt={10} color="grey5" textAlign="center">
          This section can not be displayed. Our team was notified and is working hard
          to solve this issue. If the problem persists, contact our support, please.
        </Paragraph>
      </ErrorHolder>
    </Column>
  )
}

SomethingWentWrongError.propTypes = {
  error: PropTypes.any,
  info: PropTypes.any,
}

export default SomethingWentWrongError
