import React from 'react'
import ReactDOM from 'react-dom'

import createRoutes from 'routes/createRoutes'
import createAnonymousRoutes from 'routes/createAnonymousRoutes'

// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root')
const supportedTypes = ['anonymous', 'GP', 'need_to_verify']

const routes = createRoutes()
const anonymousRoutes = createAnonymousRoutes()

let render = () => {
  const App = require('components/App').default
  const InvestBridge = require('InvestBridge').default

  ReactDOM.render(
    <InvestBridge
      App={App}
      routes={routes}
      anonymousRoutes={anonymousRoutes}
      supportedTypes={supportedTypes}
    />,
    MOUNT_NODE
  )
}

// Development Tools
// ------------------------------------
if (__DEV__) {
  const renderApp = render

  if (module.hot) {
    module.hot.accept()
  }

  render = () => {
    try {
      renderApp()
    } catch (e) {
      console.error(e)
    }
  }
}

render()
