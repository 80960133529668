import gql from 'graphql-tag'
import {GET_GLOBAL_VARIABLES, GET_NETWORK_STATUS, GET_SERVER_VERSION} from 'graphql/client'

const getIsConnected = cache => cache.readQuery({query: GET_NETWORK_STATUS}).networkStatus
const getServerVersion = cache => cache.readQuery({query: GET_SERVER_VERSION}).serverVersion
const getGlobalVariables = cache => cache.readQuery({query: GET_GLOBAL_VARIABLES}).globalVariables

export const typeDefs = gql`
  type NetworkStatus {
    isConnected: Boolean!,
  }

  type ServerVersion {
    version: String,
  }

  type GlobalVariables {
    unconfirmed: Boolean!,
  }

  extend type Query {
    networkStatus: NetworkStatus!,
    serverVersion: ServerVersion!,
    globalVariables: GlobalVariables!,
  }

  input GlobalVariablesInput {
    unconfirmed: Boolean,
  }

  extend type Mutation {
    updateNetworkStatus(isConnected: Boolean!): NetworkStatus!,
    updateServerVersion(version: String!): ServerVersion!,
    updateGlobalVariables(input: GlobalVariablesInput!): GlobalVariables!,
  }
`

export const resolvers = {
  Mutation: {
    updateNetworkStatus: (_, {isConnected}, {cache}) => {
      writeNetworkStatusCache(cache, isConnected)
      return getIsConnected(cache)
    },
    updateServerVersion: (_, {version}, {cache}) => {
      writeServerVersionCache(cache, version)
      return getServerVersion(cache)
    },
    updateGlobalVariables: (_, {input}, {cache}) => {
      writeGlobalVariablesCache(cache, input)
      return getGlobalVariables(cache)
    },
  },
  Query: {
    networkStatus: (_, __, {cache}) => {
      return getIsConnected(cache)
    },
    serverVersion: (_, __, {cache}) => {
      return getServerVersion(cache)
    },
    globalVariables: (_, __, {cache}) => {
      return getGlobalVariables(cache)
    },
  },
  NetworkStatus: () => {},
  ServerVersion: () => {},
  GlobalVariables: () => {},
}

const writeNetworkStatusCache = (cache, isConnected) =>
  cache.writeData({
    data: {
      networkStatus: {
        __typename: 'NetworkStatus',
        isConnected,
      },
    },
  })

const writeServerVersionCache = (cache, version) =>
  cache.writeData({
    data: {
      serverVersion: {
        __typename: 'ServerVersion',
        version: version,
      },
    },
  })

const writeGlobalVariablesCache = (cache, data) => {
  cache.writeData({
    data: {
      globalVariables: {
        ...data,
        __typename: 'GlobalVariables',
      },
    },
  })
}

export const initGlobalState = cache => {
  writeNetworkStatusCache(cache, true)
  writeServerVersionCache(cache, null)
  writeGlobalVariablesCache(cache, {unconfirmed: false})
}

export const updateIsConnected = (cache, isConnected) => {
  if (isConnected !== getIsConnected(cache)) {
    writeNetworkStatusCache(cache, isConnected)
  }
}

export const updateServerVersion = (cache, version) => {
  if (version !== getServerVersion(cache)) {
    writeServerVersionCache(cache, version)
  }
}

export const updateGlobalVariables = (cache, data) => {
  const oldData = getGlobalVariables(cache)

  if (Object.keys(data).some(key => oldData[key] !== data[key])) {
    writeGlobalVariablesCache(cache, data)
  }
}
