import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Paragraph, SmallTitle, Text} from 'components/typography'
import {Column, Flex} from 'components/layout/styled'
import Dropdown from 'components/layout/Dropdown'
import Arrow from 'components/buttons/Arrow'
import ArrowButton from 'components/buttons/ArrowButton'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const Month = styled(Paragraph)`
   width: 40px;
   line-height: 40px;
   position: relative;
   text-align: center;
   border-radius: 50%;
   cursor: pointer;
   user-select: none;
   transition: color 0ms linear 100ms;
   z-index: 1;
   &:hover {
    background-color: ${props => props.selected ? 'none' : props.theme.colors.grey1};
   }
   :active {
    transform: scale(0.9);
   }
   ${props => props.disabled ? `
     cursor: not-allowed;
     color: ${props.theme.colors.grey4};
   ` : ''}
`

const SelectionCircle = styled.span`
  position: absolute;
  height: ${props => props.selected ? 100 : 0}%;
  width: ${props => props.selected ? 100 : 0}%;
  border-radius: 50%;
  transition: all 200ms;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background-color: ${props => props.theme.colors.primary};
`

const ControlArrow = styled(ArrowButton).attrs(() => ({buttonColor: 'grey2', color: 'grey6', size: 'medium'}))`
  position: absolute;
  ${props => props.direction === 'left' ? 'left: 30px;' : 'right: 30px;'}
`

const stringifyDate = (month, year) => {
  const realMonth = `${month + 1}`
  if (realMonth.length < 2) {
    return `0${realMonth}/${year}`
  }
  return `${realMonth}/${year}`
}

export default class MonthPicker extends React.Component {
  updateTimeout
  state = {
    optimisticYear: this.props.activeYear,
  }

  componentWillUnmount() {
    clearTimeout(this.updateTimeout)
  }

  setOptimisticYear = (val) => {
    const {limits} = this.props
    const limited = typeof limits !== 'undefined'
    let tmpVal = val
    if (limited) {
      if (tmpVal > limits.year.end) {
        tmpVal = limits.year.end
      }
      if (tmpVal < limits.year.start) {
        tmpVal = limits.year.start
      }
    }
    this.setState({optimisticYear: tmpVal})
    clearTimeout(this.updateTimeout)
    this.updateTimeout = setTimeout(() => {
      this.props.setYear(tmpVal)
    }, 600)
  }

  render() {
    const {activeMonth, setMonth, color, limits} = this.props
    const {optimisticYear} = this.state
    const limited = typeof limits !== 'undefined'
    return (
      <Dropdown
        defaultPosition="bottom"
        offset={10}
        watchOutsideClick
        target={({createPopUp, deletePopUp, targetRef, isOpen}) =>
          <Flex
            style={{userSelect: 'none', cursor: 'pointer'}}
            alignItems="center"
            onClick={() => isOpen ? deletePopUp() : createPopUp()}
            ref={targetRef}
          >
            <SmallTitle lineHeight={1} color={color}>
              {stringifyDate(activeMonth, optimisticYear)}
            </SmallTitle>
            <Arrow direction={isOpen ? 'up' : 'down'} ml={5} color={color} size="medium" />
          </Flex>}
      >
        {({deletePopUp}) => {
          return (
            <Column px={20} py={10} height={300} width={260}>
              <Flex justifyContent="center" alignItems="center" fill>
                <Text
                  pt={2}
                  lineHeight={1}
                  fontSize={36}
                  color="grey6"
                  fontWeight={900}
                  style={{userSelect: 'none'}}
                >
                  {optimisticYear}
                </Text>
                <ControlArrow
                  direction="left"
                  onClick={() => this.setOptimisticYear(optimisticYear - 1)}
                />
                <ControlArrow
                  direction="right"
                  onClick={() => this.setOptimisticYear(optimisticYear + 1)}
                />
              </Flex>
              {[0, 1, 2, 3].map((row, i) => (
                <Flex justifyContent="space-around" key={row}>
                  {months.slice(3 * i, (3 * i) + 3).map(month => {
                    const monthIndex = months.indexOf(month)
                    const selected = monthIndex === activeMonth
                    const outOfGame = !limited ? false : (
                      (
                        optimisticYear === limits.year.end
                        && monthIndex > limits.month.end
                      )
                      || (optimisticYear === limits.year.start
                        && monthIndex < limits.month.start
                      )
                    )
                    const select = () => {
                      if (limited) {
                        if (
                          optimisticYear === limits.year.end
                          && monthIndex > limits.month.end
                        ) {
                          setMonth(limits.year.end)
                        } else if (
                          optimisticYear === limits.year.start
                          && monthIndex < limits.month.start
                        ) {
                          setMonth(limits.year.start)
                        } else {
                          setMonth(monthIndex)
                          setTimeout(deletePopUp, 400)
                        }
                      } else {
                        setMonth(monthIndex)
                        setTimeout(deletePopUp, 400)
                      }
                    }
                    return (
                      <Month
                        onClick={!outOfGame ? select : null}
                        selected={selected}
                        disabled={outOfGame}
                        title={outOfGame ? 'no reports in this month' : ''}
                        color={selected ? 'white' : 'grey7'}
                        m={2}
                        key={month}
                      >
                        <SelectionCircle selected={selected} />
                        {month}
                      </Month>
                    )
                  })}
                </Flex>
              ))}
            </Column>
          )
        }}
      </Dropdown>
    )
  }
}

MonthPicker.propTypes = {
  activeMonth: PropTypes.number.isRequired,
  activeYear: PropTypes.number.isRequired,
  setMonth: PropTypes.func.isRequired,
  setYear: PropTypes.func.isRequired,
  color: PropTypes.string,
  limits: PropTypes.object,
}

MonthPicker.defaultProps = {
  color: 'primary',
}
