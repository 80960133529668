export const colors = {
  transparent         : 'transparent',
  white               : '#ffffff',
  primarySuperLight   : '#13eba2', // #00d479
  primaryLight        : '#09d48b',
  primary             : '#06ac72', // #19A747
  primaryDarker       : '#046744',
  gradientAqua        : '#30b756',
  gradientBlue        : '#016642',
  alertBlue           : '#40a9ff',
  alertBlueInactive   : '#b1cfe8',
  alertGreen          : '#17c671',
  alertYellow         : '#ffb400',
  alertYellowDarker   : '#d9980f',
  alertRed            : '#d70d38',
  alertRedDarker      : '#b30c30',
  timeMachine         : '#6000c5',
  grey1               : '#f7f9fc', // backgrounds of headers primarily
  grey2               : '#f1f4fa', // table background
  grey3               : '#ebeef7', // main background
  grey4               : '#dcdfe9', // top menu, borders (also substitute for old color #ccd8e0)
  grey5               : '#b9c3cd',
  grey6               : '#9babb6', // was #9babb6
  grey7               : '#70818a',
  grey8               : '#394c52', // was #394c52 dark caption text
  grey9               : '#2a2d33', // left menu
  graph: {
    teal: '#21c1bb',
    blue: '#1693da',
    purple: '#8b48d3',
    pink: '#ef5681',
    orange: '#ff8a5b',
    yellow: '#fbc919',
    green: '#5cd06d',
    grey: '#dbb7a0',
    tealDark: '#0d7380',
    blueDark:'#1644c5',
    purpleDark:'#7200ba',
    pinkDark:'#be2853',
    orangeDark: '#c65b2f',
    yellowDark: '#cea000',
    greenDark: '#518d59',
    greyDark: '#7c604e',
  },
}

export const graphColors = Object.values(colors.graph)
