import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Div} from 'components/layout/styled'
import Divider from 'components/layout/Divider'
import {CSSTransition} from 'react-transition-group'
import {spacePropsSeparation} from 'utils/props'

const ExpandingPartWrapper = styled.div`
  height: 0;
  overflow: hidden;
  background-color: ${props => props.theme.colors.grey1};
`

const WithLeftBorder = styled.div`
  border-left: 5px solid ${props => props.theme.colors.primary};
`

const TransitionWrapper = styled.div`
  & > ${ExpandingPartWrapper} {
    &.expandable-enter-active, &.expandable-enter-done, &.expanded-on-mount  {
      height: ${props => props.height}px;
    }
    transition: height 300ms ease;
  }
`

const ExpandingPart = ({content, forwardRef, className}) => (
  <ExpandingPartWrapper className={className}>
    <Divider />
    <WithLeftBorder ref={forwardRef}>
      {content}
    </WithLeftBorder>
  </ExpandingPartWrapper>
)

ExpandingPart.propTypes = {
  content: PropTypes.any,
  forwardRef: PropTypes.any,
  className: PropTypes.string,
}

class Accordion extends React.Component {
  expandable = React.createRef()
  accordion = React.createRef()
  state = {
    expandedBy: this.props.expandedBy || 0,
  }

  updateAccordion(type, value) {
    if (this.props.updateAccordion) {
      this.props.updateAccordion(type, value)
    }
  }

  componentDidMount() {
    this.updateAccordion('height', this.accordion.current.clientHeight + 20)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.expandedBy !== this.state.expandedBy) {
      this.props.customOnUpdateFunction
      && this.props.customOnUpdateFunction(this.state.expandedBy - prevState.expandedBy)
    }
    if (this.props.expandedBy !== this.state.expandedBy) {
      if (prevState.expandedBy !== this.state.expandedBy) {
        this.updateAccordion('expandedBy', this.state.expandedBy)
      } else if (prevProps.expandedBy !== this.props.expandedBy) {
        this.setState({expandedBy: this.props.expandedBy})
      }
    }
  }

  updateWithDifference = (difference) => {
    this.setState(({expandedBy}) => ({
      expandedBy: expandedBy + difference - 1,
    }))
  }

  update = () => {
    this.setState({
      expandedBy: this.expandable.current.clientHeight + 1,
    })
  }

  render() {
    const {hoverable} = this.props
    const {spaceProps, otherProps} = spacePropsSeparation(this.props)
    return (
      <Div p={0} hoverable={hoverable} {...spaceProps} style={otherProps.style}>
        <div ref={this.accordion}>
          {otherProps.upperContent(
            () => this.setState({expandedBy: this.state.expandedBy ? 0 : 1}),
            this.state.expandedBy,
          )}
        </div>
        <TransitionWrapper height={this.state.expandedBy}>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            classNames="expandable"
            in={!!this.state.expandedBy}
            timeout={300}
            onEntering={this.update}
          >
            <ExpandingPart
              forwardRef={this.expandable}
              content={typeof otherProps.hiddenContent === 'function'
                ? otherProps.hiddenContent({update: this.update, updateWithDifference: this.updateWithDifference})
                : otherProps.hiddenContent}
              className={otherProps.expandedBy ? 'expanded-on-mount' : ''}
            />
          </CSSTransition>
        </TransitionWrapper>
      </Div>
    )
  }
}

Accordion.propTypes = {
  upperContent: PropTypes.any,
  hiddenContent: PropTypes.any,
  updateAccordion: PropTypes.func,
  expandedBy: PropTypes.number,
  hoverable: PropTypes.bool,
  customOnUpdateFunction: PropTypes.func,
}

export default Accordion
