import {createSubroutes} from 'routes/createSubroutes'
import AppLayout from 'layouts/AppLayout'
import FullLayout from 'layouts/FullLayout'
import Redirect from 'utils/redirect'
import NotFound from 'routes/NotFound'

import Search from './Search'
import {ProfileRoute, SubscriptionRoute, InvoiceRoute} from './UserProfile'
import Contacts from './SavedContacts'
import {LpList, LpDetail} from './SavedLPs'

const createRoutes = () => createSubroutes('/', [
  {
    path: '/subscription/invoice',
    component: FullLayout,
    routes: [
      InvoiceRoute,
    ],
  },
  {
    path: '/',
    component: AppLayout,
    routes: [
      Contacts,
      LpDetail,
      LpList,
      Search,
      Search,
      ProfileRoute,
      SubscriptionRoute,
      Redirect(pathname => '/', '/sign-up'),
      Redirect(pathname => `${pathname}/search`, '/'),
      NotFound,
    ].filter(Boolean),
  },
])

export default createRoutes
