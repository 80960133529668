import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CompanySvg from 'static/svg/ico/company.svg'

const Icon = styled(CompanySvg)`
  width: ${props => props.width}px;
  path {
    fill: ${props => props.theme.colors.grey4};
  }
`

const Img = styled.img`
  width: ${props => props.width}px;
`

const CompanyImage = ({src, width = 200}) => {
  if (!src) return <Icon width={width} />
  return <Img src={src} width={width} />
}

CompanyImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
}

export default CompanyImage
