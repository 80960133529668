import React from 'react'
import PropTypes from 'prop-types'
import styled, {keyframes, css} from 'styled-components'
import {Column} from 'components/layout/styled'
import OptionsIcon from 'static/svg/ico/ico_ui_options.svg'
import {omitProps} from 'utils/props'

const jump = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0);
  }
`

const Icon = styled(omitProps(['vertical'], OptionsIcon))`
  fill: ${props => props.theme.colors.grey7};
  width: 18px;
  flex-shrink: 0;
  transform: rotate(${props => props.vertical ? 90 : 0}deg);
  ${props => props.circle && css`
    border: 1px solid ${props.theme.colors.grey3};
    padding: 6px;
    border-radius: 50%;
  `}
`

const Button = styled(Column).attrs(() => ({justifyContent: 'center', alignItems: 'center'}))`
  width: ${props => props.width || 50}px;
  height: ${props => props.height || 30}px;
  cursor: pointer;
  &:hover {
    .dot1, .dot2, .dot3 {
      animation: ${jump} 300ms linear;
    }
    .dot2 {
      animation-delay: 100ms;
    }
    .dot3 {
      animation-delay: 200ms;
    }
    ${Icon} {
      border-color: ${props => props.theme.colors.grey4};
      fill: ${props => props.theme.colors.primaryLight};
    }
  }
`

Icon.defaultProps = {
  title: '',
  alt: '',
}

const ThreeDotsButton = React.forwardRef(({vertical, circle, ...props}, ref) =>
  <Button ref={ref} {...props}>
    <Icon vertical={vertical} circle={circle} />
  </Button>)

ThreeDotsButton.propTypes = {
  passRef: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  vertical: PropTypes.bool,
  circle: PropTypes.bool,
}

export default ThreeDotsButton
