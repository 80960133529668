import React from 'react'
import PropTypes from 'prop-types'
import styled, {keyframes} from 'styled-components'
import {Box} from 'components/layout/styled'
import {Transition} from 'react-transition-group'

const footerAppear = keyframes`
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
`

const FooterBox = styled(Box).attrs(() => ({m: 0}))`
  width: 100%;
  animation: ${footerAppear} 200ms ease-out;
  transition: height 200ms ease-in 100ms;
  height: ${props => props.visible ? 100 : 0}px;
  overflow: hidden;
  pointer-events: auto;
`

const TransitionFooter = ({children, visible}) => {
  return (
    <Transition
      mountOnEnter
      unmountOnExit
      in={visible}
      timeout={200}
    >
      {state => <FooterBox visible={state === 'entering' || state === 'entered'}>
        {children}
      </FooterBox>}
    </Transition>
  )
}

TransitionFooter.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
}

export default TransitionFooter
