import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import NumberWithSpaces from 'components/formatters/NumberWithSpaces'
import {DATE_FORMAT} from 'config/constants'

const ValueText = styled.span`
  word-wrap: nowrap;
  white-space: nowrap;
  ${
    props => props['data-suffix'] && css`
      ::after {
        word-wrap: nowrap;
        white-space: nowrap;
        display: inline;
        content: attr(data-suffix);
      }
    `
  }
`

const Value = React.memo(({value, type, currency, utc, customDateFormat, ...props}) => {
  if (value == null || value === 'NaN' || value.length === 0 || (typeof value === 'number' && isNaN(value))) return 'N/A'
  switch (type) {
    case 'number':
      return (
        <ValueText>
          <NumberWithSpaces {...props} value={value} />
        </ValueText>
      )
    case 'text':
      return value
    case 'percentage':
      return (
        <ValueText data-suffix="%">
          <NumberWithSpaces {...props} value={value} />
        </ValueText>
      )
    case 'multiple':
      return (
        <ValueText data-suffix="x">
          <NumberWithSpaces {...props} value={value} />
        </ValueText>
      )
    case 'amount':
      return (
        <ValueText data-suffix={` ${currency}`}>
          <NumberWithSpaces trim="allOrNone" {...props} value={value} />
        </ValueText>
      )
    case 'date':
      if (utc) {
        return (
          <ValueText>
            {moment.utc(value).format(customDateFormat || DATE_FORMAT)}
          </ValueText>
        )
      }
      return (
        <ValueText>
          {moment(value).format(customDateFormat || DATE_FORMAT)}
        </ValueText>
      )
    case 'boolean':
      if (!value) return 'no'
      return 'yes'
    default:
      return value
  }
})

Value.propTypes = {
  value: PropTypes.any,
  type: PropTypes.oneOf([
    'number',
    'text',
    'amount',
    'percentage',
    'multiple',
    'date',
    'boolean',
  ]).isRequired,
  currency: PropTypes.string,
  customDateFormat: PropTypes.string,
  utc: PropTypes.bool,
}

const ValueContainer = ({type, ...props}) => <Value type={type?.toLowerCase()} {...props} />

ValueContainer.defaultProps = {
  type: 'text',
}

ValueContainer.propTypes = {
  type: PropTypes.string,
}

export default ValueContainer
