export const rgba = (hex, opacity) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity ?? 1})`
  }
  throw new Error('Bad Hex')
}

export const lightenDarkenColor = function (hex, percent) {
  hex = hex.replace(/^\s*#|\s*$/g, '')
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1')
  }
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)

  return `#${Math.round(256 + (255 * percent + r * (100 - percent)) / 100).toString(16).substr(1)}${
    (Math.round(256 + (255 * percent + g * (100 - percent)) / 100).toString(16)).substr(1)}${
    (Math.round(256 + (255 * percent + b * (100 - percent)) / 100).toString(16)).substr(1)
  }`
}

export const isColorDark = (hexColor) => {
  if (!hexColor) return true
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
  return yiq >= 128
}
