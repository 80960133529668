import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import ArrowIcon from 'static/svg/ico/ico_ui_arrow.svg'
import {space} from 'styled-system'

const calculateDegrees = (direction, isClockWise) => {
  switch (direction) {
    case 'up':
      return isClockWise ? 180 : -180
    case 'right':
      return isClockWise ? 270 : -90
    case 'down':
      return 0
    case 'left':
      return isClockWise ? 90 : -270
    default:
      return 0
  }
}

const calculateSpace = (size) => {
  switch (size) {
    case 'small':
      return 8
    case 'medium':
      return 12
    case 'large':
      return 14
    default:
      return 'small'
  }
}

const Arrow = styled(ArrowIcon)`
  ${props => {
    const isClockWise = props.move === 'clockwise'
    const degrees = calculateDegrees(props.direction, isClockWise)
    const pixels = calculateSpace(props.size)
    return (
      css`
      width: ${pixels}px;
      height: ${pixels}px;
      fill: ${props.theme.colors[props.color]};
      transform: rotate(${degrees}deg);
      transition: transform 100ms;
      user-select: none;
      ${space}
    `)
  }}
`

Arrow.poropTypes = {
  direction: PropTypes.oneOf(['up, right, down, left']),
  color: PropTypes.string,
  move: PropTypes.oneOf(['clockwise', 'counterClockwise']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

Arrow.defaultProps = {
  direction: 'down',
  color: 'grey8',
  size: 'small',
  move: 'clockwise',
}

export default Arrow
