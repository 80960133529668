import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import styled, {css} from 'styled-components'
import AbsolutePositionRenderer from 'components/layout/AbsolutePositionRenderer'
import DefaultInputStyle from 'components/form/DefaultInputStyle'
import {Paragraph, Inline} from 'components/typography'
import Arrow from 'components/buttons/Arrow'
import Tooltip from 'components/layout/Tooltip'
import {Div} from 'components/layout/styled'
import SearchIcon from 'static/svg/ico/ico_ui_search.svg'
import QuestionMarkIcon from 'static/svg/ico/ico_ui_questionmark.svg'
import withInputWrapper from 'components/form/withInputWrapper'
import {omitProps} from 'utils/props'

const SelectWrapper = styled(DefaultInputStyle)`
  cursor: pointer;
  position: relative;
  user-select: none;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  ${props => props.small && css`
    ${Paragraph} {
     font-size: 12px;
  }`}
`

const StyledArrow = styled(omitProps(['small'], Arrow))`
  position: absolute;
  top: ${props => props.small ? 9 : 14}px;
  right: ${props => props.small ? 10 : 15}px;
`

const DropdownWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey5};
  ${props => props.theme.darkerShadow}
`

const ScrollableContent = styled.div`
  overflow: auto;
  width: ${props => props.width}px;
  max-height: 300px;
  scroll-behavior: smooth;
`

const ItemsWrapper = styled.div`
  position: relative;
`

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${props => props.theme.colors.grey5};
  height: 16px;
  width: auto;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
`

const SearchInput = styled.input`
  ${props => props.theme.hollowInputStyle};
  padding-left: 32px;
  padding-top: 4px;
  margin-bottom: 2px;
  border-bottom: 1px solid ${props => props.theme.colors.grey4};
  box-sizing: border-box;
  height: 40px;
  width: 100%;
`

const TooltipIcon = styled(QuestionMarkIcon)`
 fill: ${props => props.theme.colors.white};
 background-color: ${props => props.theme.colors.primary};
 height: 13px;
 width: 13px;
 padding: 3px;
 border-radius: 50%;
 box-sizing: border-box;
`

const TooltipHolder = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledItem = styled(Div)`
  position: absolute;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  background-color: ${props => props.hovered ? props.theme.colors.grey2 : props.theme.colors.white};
  ${props => props.hovered && css`
    ${TooltipHolder} {
      display: block;
    }`}
`

const SearchParagraph = styled(Paragraph)`
  word-wrap: break-word;
  color: ${props => props.theme.colors.grey8};
`

const Button = styled.button`
  overflow: hidden;
  border-radius: 0;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 50px;
  background-color: ${props => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
  width: calc(100% + 2px);
  margin: 0 -1px -1px -1px;
  color: ${props => props.theme.colors.white};
  :hover {
    background-color: ${props => props.theme.colors.primaryDarker};
  }
  :active, :focus {
    outline: none;
  }
`

const SearchedText = ({text, searchedValue}) => {
  if (searchedValue && searchedValue.length > 0) {
    const index = text.toLowerCase().indexOf(searchedValue.toLowerCase())
    const partBefore = text.substring(0, index)
    const boldPart = text.substring(index, index + searchedValue.length)
    const partAfter = text.substring(index + searchedValue.length, text.length)
    return <SearchParagraph>
      {partBefore}<span style={{fontWeight: 700}}>{boldPart}</span>{partAfter}
    </SearchParagraph>
  } else {
    return <SearchParagraph>{text}</SearchParagraph>
  }
}

const Item = React.memo((
  {id, name, hint, hovered, onHover, onMouseDown, registerHeight, calculateTop, searchedValue},
) => {
  return (
    <StyledItem
      onMouseDown={() => onMouseDown(id)}
      ref={node => registerHeight(node, id)}
      style={{top: calculateTop(id)}}
      onMouseEnter={() => onHover(id)}
      hovered={hovered}
      py={10}
      pr={hint ? 30 : 20}
      pl={20}
    >
      <SearchedText
        text={name}
        searchedValue={searchedValue}
      />
      {hint && <TooltipHolder>
        <Tooltip
          target={({createPopUp, deletePopUp, targetRef}) => <div
            onMouseEnter={createPopUp}
            onMouseLeave={deletePopUp}
            ref={targetRef}
          >
            <TooltipIcon />
          </div>}
          defaultPosition="right"
          hint={hint}
        />
      </TooltipHolder>}
    </StyledItem>
  )
})

Item.propTypes = {
  id: PropTypes.string,
  name: PropTypes.node,
  searchedValue: PropTypes.string,
  hovered: PropTypes.bool,
  onHover: PropTypes.func,
  onMouseDown: PropTypes.func,
  registerHeight: PropTypes.func,
  calculateTop: PropTypes.func,
  hint: PropTypes.string,
}

class Dropdown extends React.Component {
  cachedItemTops = {}
  wrapper
  state = {
    cachedItemHeights: null,
    itemsInView: this.props.items?.slice(0, 20) || [],
    searchedValue: null,
  }

  updateItems = ({target}) => {
    const firstId = Object.keys(this.cachedItemTops).find(key =>
      (target.scrollTop >= this.cachedItemTops[key])
      && (target.scrollTop < (this.cachedItemTops[key] + this.state.cachedItemHeights[key])),
    )
    let firstToShowIndex = this.props.items.findIndex(val => `${val.id}` === `${firstId}`)
    if (firstToShowIndex - 5 >= 0) firstToShowIndex -= 5
    this.setState({itemsInView: this.props.items.slice(firstToShowIndex, firstToShowIndex + 20)})
  }

  registerHeight = (node, id) => {
    if (!this.props.items.length) return
    if (node) {
      if (this.props.items[0].id === id && node?.clientHeight && !this.state.cachedItemHeights) {
        const cachedItemHeights = {}
        this.props.items.forEach((val, index) => {
          cachedItemHeights[val.id] = node.clientHeight
          this.cachedItemTops[val.id] = node.clientHeight * index
        })
        this.setState({cachedItemHeights}, this.props.onResize)
      }
      if (this.state.cachedItemHeights && (this.state.cachedItemHeights[id] !== node.clientHeight)) {
        this.state.cachedItemHeights[id] = node.clientHeight
        this.setState({cachedItemHeights: {...this.state.cachedItemHeights, [id]: node.clientHeight}})
      }
    }
  }

  calculateTop = (id) => {
    if (!this.state.cachedItemHeights) return 0
    let calculatedHeight = 0
    const index = this.props.items.findIndex(val => `${val.id}` === `${id}`)
    Object.keys(this.state.cachedItemHeights).slice(0, index).forEach(id => {
      calculatedHeight += this.state.cachedItemHeights[id]
    })
    if (this.cachedItemTops[id] == null || this.cachedItemTops[id] !== calculatedHeight) {
      this.cachedItemTops[id] = calculatedHeight
    }
    return calculatedHeight
  }

  componentDidUpdate(prevProps, prevState) {
    const {props: {hovered, items, searchInput}, wrapper, cachedItemTops, state: {cachedItemHeights}} = this
    if (!prevProps.searchInput && searchInput) {
      setTimeout(() => searchInput.focus(), 100)
    }

    if (!isEqual(prevProps.items, items)) {
      this.setState({
        cachedItemHeights: null,
        itemsInView: items.slice(0, 20),
      })
    }

    if (hovered != null && prevProps.hovered !== hovered) {
      if (cachedItemTops[hovered] + cachedItemHeights[hovered] > (wrapper.scrollTop + wrapper.clientHeight)) {
        wrapper.scrollTop = cachedItemTops[hovered] + cachedItemHeights[hovered] - wrapper.clientHeight
      }
      if (cachedItemTops[hovered] < wrapper.scrollTop) {
        wrapper.scrollTop = cachedItemTops[hovered]
      }
    }
  }

  componentWillUnmount() {
    this.props.filterItems('')
    this.props.onHover(null)
  }

  handleMouseDown = (id) => this.props.onSelect(id)

  render() {
    const {
      target,
      onHover,
      getSearchRef,
      setFocused,
      searchInput,
      search,
      button,
      filterItems,
      hovered,
      customWidth,
    } = this.props
    const {cachedItemHeights, itemsInView, searchedValue} = this.state
    const calculatedHeight = Object.values(cachedItemHeights || {}).reduce((acc, cur) => acc + cur, 0)

    return (
      <DropdownWrapper onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}>
        {search && <div style={{position: 'relative'}}>
          <SearchInput
            ref={getSearchRef}
            onMouseDown={() => searchInput.focus()}
            onChange={(e) => {
              this.setState({searchedValue: e.target.value},
                () => filterItems(this.state.searchedValue))
            }}
            onBlur={() => setTimeout(
              () => document.activeElement !== target && setFocused(false),
              200)}
          />
          <StyledSearchIcon />
        </div>}
        <ScrollableContent
          onScroll={this.updateItems}
          width={customWidth || target.clientWidth || 0}
          onMouseLeave={() => onHover(null)}
          ref={node => {
            this.wrapper = node
          }}
        >
          {itemsInView.length
            ? <ItemsWrapper style={{height: calculatedHeight}}>
              {itemsInView.map(item => (
                <Item
                  key={item.id}
                  name={item.name}
                  hint={item.hint}
                  id={item.id}
                  onMouseDown={this.handleMouseDown}
                  registerHeight={this.registerHeight}
                  calculateTop={this.calculateTop}
                  onHover={onHover}
                  hovered={hovered === item.id}
                  rerender={calculatedHeight || (() => null)}
                  searchedValue={searchedValue}
                />
              ))}
            </ItemsWrapper>
            : <Paragraph lineHeight="40px" textAlign="center" color="grey8">No items to select</Paragraph>}
        </ScrollableContent>
        {!!button && <Button onClick={button.onClick}>itemsInView
          {button.name}
        </Button>}
      </DropdownWrapper>
    )
  }
}

Dropdown.propTypes = {
  items: PropTypes.array,
  target: PropTypes.object,
  hovered: PropTypes.string,
  onResize: PropTypes.func,
  onSelect: PropTypes.func,
  onHover: PropTypes.func,
  searchInput: PropTypes.object,
  getSearchRef: PropTypes.func,
  setFocused: PropTypes.func,
  filterItems: PropTypes.func,
  search: PropTypes.bool,
  button: PropTypes.bool,
  customWidth: PropTypes.any,
}

const AbsoluteDropdown = AbsolutePositionRenderer(
  Dropdown,
  {defaultPosition: 'bottom', allowedPositions: ['top', 'bottom']},
)

class Select extends React.Component {
  items
  createPopUp
  deletePopUp
  isOpen
  target
  searchInput
  searchKey = ''
  searchKeyTimeout = null
  lastFilter = ''

  unifyItemsFormat() {
    this.items = this.props.items?.map(item => item.id ? item : ({id: item.value, name: item.name}))
  }

  constructor(props) {
    super(props)
    this.unifyItemsFormat()
    this.state = {
      selected: this.props.input?.value || this.props.value,
      hovered: null,
      focused: false,
      filteredItems: this.items,
    }
  }

  reFilter() {
    return this.filterItems(this.lastFilter)
  }

  filterItems = (searchedValue) => {
    this.lastFilter = searchedValue.toLowerCase()

    const filteredItems = this.items?.filter(item => (
      typeof item.name !== 'string' // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! instanceof String is not working !!!
        || item.name.toLowerCase().includes(searchedValue.toLowerCase())
    )) ?? []

    this.setState({filteredItems})
  }

  handleKeyDown = (e) => {
    const keyCode = e.which || e.keyCode
    if (this.items.length < 50 && e.key.match(/^[a-z0-9 ]$/)) {
      this.searchKey += e.key
      const hovered = this.items?.find(item => item.name.toLowerCase().startsWith(this.searchKey))?.id
      if (hovered == null) {
        return
      }
      this.setState({hovered})
      clearTimeout(this.searchKeyTimeout)
      this.searchKeyTimeout = setTimeout(() => {
        this.searchKeyTimeout = null
        this.searchKey = ''
      }, 1000)
    }
    if (keyCode === 9) {
      if (this.isOpen) {
        e.preventDefault()
        if (document.activeElement === this.searchInput) {
          this.target.focus()
        }
        this.deletePopUp()
      }
    }
    if (keyCode === 38 || keyCode === 40) {
      e.preventDefault()
      const selectedIndex = this.state.filteredItems.findIndex(val => val.id === this.state.selected)
      const hoveredIndex = this.state.filteredItems.findIndex(val => val.id === this.state.hovered)
      if (keyCode === 38 && hoveredIndex <= 0) return
      if (keyCode === 40 && hoveredIndex === this.state.filteredItems.length - 1) return
      if (this.isOpen) {
        if (keyCode === 38) {
          this.setState({hovered: this.state.filteredItems[hoveredIndex - 1].id})
        }
        if (keyCode === 40) {
          this.setState({hovered: this.state.filteredItems[hoveredIndex + 1].id})
        }
      } else {
        if (keyCode === 38) {
          this.setState({selected: this.state.filteredItems[selectedIndex - 1].id})
        }
        if (keyCode === 40) {
          this.setState({selected: this.state.filteredItems[selectedIndex + 1].id})
        }
      }
    }
    if (event.keyCode === 13) {
      e.preventDefault()
      if (!this.isOpen) return
      if (this.state.hovered != null) {
        this.select(this.state.hovered)
      } else {
        this.deletePopUp()
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.items !== this.props.items) {
      this.unifyItemsFormat()
      this.forceUpdate()
    }
    if (!prevState.focused && this.state.focused) {
      if (this.props.input) {
        this.props.input.onFocus()
      }
      this.createPopUp()
      document.addEventListener('keydown', this.handleKeyDown)
    }
    if (prevState.focused && !this.state.focused) {
      if (this.props.input) {
        this.props.input.onBlur()
      }
      this.deletePopUp()
      document.removeEventListener('keydown', this.handleKeyDown)
    }
    if (this.state.selected !== prevState.selected) {
      if (this.props.input) {
        this.props.input.onChange(this.state.selected)
      }
      if (this.props.additionalOnChange) {
        this.props.additionalOnChange(this.state.selected)
      }
    } else if (this.props.input && this.state.selected !== this.props.input.value) {
      this.setState({selected: this.props.input.value})
    }

    if (this.props.items !== prevProps.items) {
      this.reFilter()
    }
  }

  select = (selected) => {
    this.target.focus()
    this.setState({selected}, () => setTimeout(this.deletePopUp, 100))
  }

  setSearch = (node) => {
    if (this.searchInput !== node) {
      this.searchInput = node
      this.forceUpdate()
    }
  }

  blurTimeout

  onBlur = () => document.activeElement !== this.searchInput && this.setState({focused: false})

  componentWillUnmount() {
    clearTimeout(this.blurTimeout)
  }

  render() {
    return (
      <AbsoluteDropdown
        items={this.state.filteredItems}
        hovered={this.state.hovered}
        customWidth={this.props.customWidth}
        offset={5}
        watchOutsideClick
        onSelect={this.select}
        onHover={hovered => this.setState({hovered})}
        getSearchRef={this.setSearch}
        filterItems={this.filterItems}
        searchInput={this.searchInput}
        search={this.items?.length >= 10}
        button={this.props.button}
        setFocused={focused => this.setState({focused})}
        target={({createPopUp, deletePopUp, targetRef, isOpen, target}) => {
          this.createPopUp = createPopUp
          this.deletePopUp = deletePopUp
          this.isOpen = isOpen
          this.target = target
          if (this.props.customTarget) {
            const CustomTarget = this.props.customTarget
            return <CustomTarget
              disabled={this.props.disabled}
              error={this.props.meta?.touched && (this.props.error || this.props.meta?.error)}
              tabIndex={this.props.disabled ? -1 : 0}
              ref={targetRef}
              active={this.state.focused}
              onFocus={() => this.setState({focused: true})}
              onBlur={() => {
                this.blurTimeout = setTimeout(this.onBlur, 200)
              }}
              onMouseDown={() => this.state.focused && isOpen ? deletePopUp() : createPopUp()}
              name={this.props.input?.name ?? this.props.name}
              selected={this.state.selected}
              isOpen={isOpen}
              items={this.items}
              {...this.props}
            />
          }
          return (
            <SelectWrapper
              small={this.props.small}
              valid={this.props.isValid}
              disabled={this.props.disabled}
              error={this.props.meta?.touched && (this.props.error || this.props.meta?.error)}
              tabIndex={this.props.disabled ? -1 : 0}
              alignItems="center"
              ref={targetRef}
              style={this.props.style}
              active={this.state.focused}
              onFocus={() => this.setState({focused: true})}
              onBlur={() => {
                this.blurTimeout = setTimeout(this.onBlur, 200)
              }}
              onMouseDown={() => this.state.focused && isOpen ? deletePopUp() : createPopUp()}
              name={this.props.input?.name ?? this.props.name}
            >
              <Paragraph color="grey8" ellipses pr={15}>
                {this.state.selected == null
                  ? <Inline color="grey6">{this.props.placeHolder || ''}</Inline>
                  : this.items?.find(item => item.id === this.state.selected)?.name}
              </Paragraph>
              <StyledArrow
                small={this.props.small}
                size="medium"
                color="grey6"
                direction={isOpen ? 'up' : 'down'}
              />
            </SelectWrapper>
          )
        }}
      />
    )
  }
}

Select.propTypes = {
  items: PropTypes.array,
  input: PropTypes.object,
  meta: PropTypes.object,
  error: PropTypes.any,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  button: PropTypes.object,
  style: PropTypes.object,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  additionalOnChange: PropTypes.func,
  name: PropTypes.string,
  small: PropTypes.bool,
  customTarget: PropTypes.any,
  customWidth: PropTypes.any,
}

export default withInputWrapper(Select)
