import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Img = styled.img`
  width: ${props => props.width}px;
`

const UserImage = ({src = '/static/img/profile_default.jpg', width = 200}) => {
  return <Img src={src} width={width} />
}

UserImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
}

export default UserImage
