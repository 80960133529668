import Loading from 'components/loaders/Loading'

export const ProfileRoute = (url) => ({
  path: `${url}/my-profile`,
  component: Loading(() => import(/* webpackChunkName: "userProfile" */'./containers/ProfileContainer')),
})

export const SubscriptionRoute = (url) => ({
  path: `${url}/subscription`,
  component: Loading(() => import(/* webpackChunkName: "subscription" */'./containers/SubscriptionContainer')),
  exact: true,
})

export const InvoiceRoute = (url) => ({
  path: `${url}/:id`,
  component: Loading(() => import(/* webpackChunkName: "subscriptionInvoice" */'./containers/InvoiceContainer')),
  exact: true,
})
