import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {Text} from 'components/typography'
import {Column} from './styled'

import EmptySvg from 'static/svg/ico/empty.svg'

const EmptyIco = styled(EmptySvg)`
  width: 100px;
  height: 100px;
  fill: ${props => props.theme.colors.grey5}
`

const EmptyScreen = ({text, children}) => <Column flex={1} alignItems="center" justifyContent="center">
  <EmptyIco />
  <Text mt={20} fontWeight={500} fontSize={24} color="grey6">{text}</Text>
  {children
  && <Column alignItems="center" mt={5}>
    {children}
  </Column>
  }
</Column>

EmptyScreen.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
}

export default EmptyScreen
