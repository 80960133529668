import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

const Link = styled(RouterLink)`
  outline: none;
  :focus, :active, :hover {
    outline: none !important;
  }
`

export default Link
