import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DefaultInputStyle from 'components/form/DefaultInputStyle'
import withInputWrapper from 'components/form/withInputWrapper'
import DurationIcon from 'static/svg/ico/ico_ui_duration.svg'

const StyledInput = styled.input`
  padding-top: 3px;
  width: 50px;
  color: ${props => props.theme.colors.grey8};
  text-align: center;
  height: 100%;
  ${props => props.theme.hollowInputStyle}
`

const Icon = styled(DurationIcon)`
  width: 20px;
  height: auto;
  fill: ${props => props.theme.colors.grey6};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`

export const convertDurationObjectToSeconds = (duration) => {
  const {days, hours, minutes, seconds} = duration
  return (Number(days) * 24 * 60 * 60)
    + (Number(hours) * 60 * 60)
    + (Number(minutes) * 60)
    + (Number(seconds))
}

export const convertSecondsToDurationObject = (duration) => {
  return {
    days: Math.floor(Number(duration) / (24 * 60 * 60)),
    hours: Math.floor(Number(duration) / (60 * 60)) % 24,
    minutes: Math.floor(Number(duration) / 60) % (60),
    seconds: Number(duration) % (60),
  }
}

class DurationInput extends React.Component {
  static propTypes = {
    meta: PropTypes.object,
    input: PropTypes.object,
    isValid: PropTypes.bool,
  }

  daysInput = React.createRef()
  hoursInput = React.createRef()
  minutesInput = React.createRef()
  secondsInput = React.createRef()

  state = {
    error: false,
    isFocused: false,
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
  }

  componentDidMount() {
    const {input} = this.props
    if (input.value) {
      this.setState(convertSecondsToDurationObject(Number(input.value)))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState(convertSecondsToDurationObject(Number(this.props.input.value)))
    }
    if (
      (prevState.days !== this.state.days)
      || (prevState.hours !== this.state.hours)
      || (prevState.minutes !== this.state.minutes)
      || (prevState.seconds !== this.state.seconds)
    ) {
      const {days, hours, minutes, seconds} = this.state
      this.props.input.onChange(convertDurationObjectToSeconds({days, hours, minutes, seconds}).toString())
    }
  }

  timeoutBlur = () => setTimeout(() => !this.state.isFocused && this.props.input.onBlur(), 0)

  triggerError = () => {
    this.setState({error: true}, () => setTimeout(() => this.setState({error: false}), 200))
  }

  onKeyPress = (event) => {
    const charCode = String.fromCharCode((typeof event.which === 'undefined') ? event.keyCode : event.which)
    if (!charCode.match(/^[0-9]*$/g)) {
      this.triggerError()
      event.preventDefault()
    }
  }

  handleBackSpace = (event) => {
    // TODO handle backspace correctly
    if (event.keyCode === 8) {
      if (event.target.selectionStart !== 0) return
      if (event.target.selectionStart !== event.target.selectionEnd) return
      if (event.target === this.secondsInput.current) {
        this.minutesInput.current.focus()
        event.preventDefault()
      }
      if (event.target === this.minutesInput.current) {
        this.hoursInput.focus()
        event.preventDefault()
      }
      if (event.target === this.hoursInput.current) {
        this.daysInput.current.focus()
        event.preventDefault()
      }
    }
  }

  onFocus = (event) => {
    event.persist()
    this.setState({isFocused: true}, () => {
      event.target.setSelectionRange(0, event.target.value.length)
      this.props.input.onFocus(event)
    })
  }

  render() {
    const {input, meta} = this.props
    const {days, hours, minutes, seconds} = this.state
    return (
      <DefaultInputStyle
        active={meta.active}
        valid={this.props.isValid}
        error={this.state.error || meta.error}
        alignItems="center"
      >
        <StyledInput
          autoComplete="off"
          {...input}
          ref={this.daysInput}
          value={days}
          onFocus={this.onFocus}
          onBlur={() => this.setState({isFocused: false}, this.timeoutBlur)}
          onChange={(e) => this.setState({days: e.target.value})}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.handleBackSpace}
          placeholder="Days"
        />:
        <StyledInput
          autoComplete="off"
          {...input}
          ref={this.hoursInput}
          value={hours}
          onFocus={this.onFocus}
          onBlur={() => this.setState({isFocused: false}, this.timeoutBlur)}
          onChange={(e) => this.setState({hours: e.target.value})}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.handleBackSpace}
          placeholder="Hrs"
        />:
        <StyledInput
          autoComplete="off"
          {...input}
          ref={this.minutesInput}
          value={minutes}
          onFocus={this.onFocus}
          onBlur={() => this.setState({isFocused: false}, this.timeoutBlur)}
          onChange={(e) => this.setState({minutes: e.target.value})}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.handleBackSpace}
          placeholder="Mins"
        />:
        <StyledInput
          autoComplete="off"
          {...input}
          ref={this.secondsInput}
          value={seconds}
          onFocus={this.onFocus}
          onBlur={() => this.setState({isFocused: false}, this.timeoutBlur)}
          onChange={(e) => this.setState({seconds: e.target.value})}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.handleBackSpace}
          placeholder="Secs"
        />
        <Icon />
      </DefaultInputStyle>
    )
  }
}

export default withInputWrapper(DurationInput)
