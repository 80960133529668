export const setToken = (jwt) => {
  if (jwt) {
    window.localStorage?.setItem('token', jwt)
  } else {
    console.log('token error')
  }
}

export const removeToken = () => {
  window.localStorage?.removeItem('token')
}
