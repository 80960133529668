import React from 'react'
import PropTypes from 'prop-types'
import {Column, Flex} from 'components/layout/styled'
import {Paragraph, Text, Title} from 'components/typography'
import {ProfilePic} from 'components/images/ProfilePic'

export const Media = ({title, subTitle, size, src}) => (
  <Flex alignItems="center">
    <ProfilePic src={src} size={size || 60} />
    <Column ml={20} flex={1} justifyContent="center" style={{minWidth: 0}}>
      {typeof title === 'string'
        ? <Title ellipses lineHeight={1.2}>{title}</Title>
        : title}
      {typeof subTitle === 'string'
        ? <Paragraph mt={5} lineHeight={1.4} ellipses nowrap color="grey6">{subTitle}</Paragraph>
        : subTitle}
    </Column>
  </Flex>
)

Media.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  size: PropTypes.number,
  src: PropTypes.string,
}

export const MediaVertical = ({title, subTitle, size, src}) => (
  <Column alignItems="center">
    <ProfilePic src={src} size={size} squareTop />
    <Column mt={20} alignItems="center" style={{minWidth: 0, maxWidth: '100%'}}>
      {typeof title === 'string'
        ? <Text fontSize={18} fontWeight={700} ellipses textAlign="center" lineHeight={1.2}>{title}</Text>
        : title}
      {typeof subTitle === 'string'
      && <Paragraph
        lineHeight={1.4}
        fontWeight={600}
        textAlign="center"
        ellipses
        nowrap
        color="grey7"
      >
        {subTitle}
      </Paragraph>
      }
      {Array.isArray(subTitle) && subTitle.map((oneSubTile, idx) =>
        <Paragraph
          lineHeight={1.4}
          mt={5}
          textAlign="center"
          ellipses
          nowrap
          key={idx}
          color="grey6"
        >
          {oneSubTile}
        </Paragraph>,
      )}
      {typeof subTitle !== 'string' && !Array.isArray(subTitle) && subTitle}
    </Column>
  </Column>
)

MediaVertical.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  size: PropTypes.number,
  src: PropTypes.string,
}
