import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Text} from 'components/typography'
import AbsolutePositionRenderer from 'components/layout/AbsolutePositionRenderer'
import {rgba} from 'utils/colors'
import QuestionMarkIcon from 'static/svg/ico/ico_ui_questionmark.svg'
import ErrorBoundary from 'components/errors/ErrorBoundary'

export const TooltipIcon = styled(QuestionMarkIcon)`
  fill: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  height: 13px;
  width: 13px;
  padding: 3px;
  border-radius: 50%;
  float: left;
  box-sizing: border-box;
  cursor: help;
  transition: background-color .1s, fill .1s;
  &:hover {
    fill: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }
`

const TooltipWrapper = styled.div`
  .popup-enter & {
    opacity: 0;
  }
  .popup-enter-active & {
    opacity: 1;
    transition: opacity 50ms;
  }
  .popup-exit & {
    opacity: 1;
  }
  .popup-exit-active & {
    opacity: 0;
    transition: opacity 50ms;
  }
  backdrop-filter: blur(5px);
  border-radius: 3px;
  flex-shrink: 0;
  width: ${props => props.width}px;
  background-color: ${props => rgba(props.theme.colors.grey9, 0.7)};
  & .arrow {
    position: absolute;
    width: 0;
    height: 0;
    ${props => {
    switch (props.position) {
      case 'top':
        return css`
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid ${props => rgba(props.theme.colors.grey9, 0.7)};
          transform: translate(-50%, 0);
        `
      case 'right':
        return css`
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid ${props => rgba(props.theme.colors.grey9, 0.7)};
          transform: translate(-100%, -50%);
        `
      case 'bottom':
        return css`
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid ${props => rgba(props.theme.colors.grey9, 0.7)};
          transform: translate(-50%, -100%);
        `
      case 'left':
        return css`
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid ${props => rgba(props.theme.colors.grey9, 0.7)};
          transform: translate(0, -50%);
        `
    }
  }}
  }
`

class Tooltip extends React.Component {
  state = {
    box: null,
  }

  render() {
    let width = 'auto'
    if (typeof this.props.hint === 'string') {
      width = ((this.state.box?.offsetWidth > 200) ? 201 : (this.state.box?.offsetWidth + 1)) || 201
    }
    const {hint, position, arrowPosition, onResize} = this.props

    return (
      <TooltipWrapper
        width={width}
        position={position}
      >
        <ErrorBoundary resetOnChildrenChange={false} onError={onResize}>
          <div
            style={{display: 'inline-block', padding: 10}}
            ref={node => {
              if (!this.state.box?.offsetWidth) {
                this.setState({box: node})
              }
            }}
          >
            {typeof hint === 'string'
              ? <Text color="white" line-height={1.5}>{hint}</Text>
              : hint
            }
          </div>
        </ErrorBoundary>
        {!this.props.noArrow
        && <div
          className="arrow"
          style={{
            top: arrowPosition.arrowTop,
            left: arrowPosition.arrowLeft,
          }}
        />
        }
      </TooltipWrapper>
    )
  }
}

Tooltip.propTypes = {
  noArrow: PropTypes.bool,
  arrowPosition: PropTypes.object,
  hint: PropTypes.node,
  position: PropTypes.string,
  onResize: PropTypes.func.isRequired,
}

const TooltipAbsolute = AbsolutePositionRenderer(Tooltip, {timeout: 50})

export const TooltipWithIconWrapper = ({hint}) => <TooltipAbsolute
  hint={hint}
  target={({createPopUp, deletePopUp, targetRef}) => <div
    onMouseEnter={createPopUp}
    onMouseLeave={deletePopUp}
    ref={targetRef}
  >
    <TooltipIcon />
  </div>}
/>

TooltipWithIconWrapper.propTypes = {
  hint: PropTypes.node,
}

export const TooltipWithIcon = TooltipWithIconWrapper

export default TooltipAbsolute
