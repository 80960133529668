import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Column} from 'components/layout/styled'
import {spin} from 'components/Effects'
import {layout} from 'styled-system'

const LoaderWheel = styled.div`
  width: ${props => props.size || 16}px;
  height: ${props => props.size || 16}px;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  border-top:
    ${props => (props.size / 16) || 1}px
    solid
    ${props => props.theme.colors[props.color] || props.theme.colors.grey3};
  border-bottom:
    ${props => (props.size / 16) || 1}px
    solid
    ${props => props.theme.colors[props.color] || props.theme.colors.grey3};
  border-left:
    ${props => (props.size / 16) || 1}px
    solid
    ${props => props.theme.colors[props.color] || props.theme.colors.grey3};
  border-right:
    ${props => (props.size / 16) || 1}px
    solid
    ${props => props.theme.colors[props.spinColor] || props.theme.colors.primary};
  background-color: transparent;
  animation-name: ${spin};
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  ${layout}
`

const Loader = ({center, noDelay, size, ...props}) => {
  const [waiting, setWaiting] = useState(!noDelay)
  let timer = null

  useEffect(() => {
    timer = setTimeout(() => {
      setWaiting(false)
    }, noDelay ? 0 : 500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (waiting) return null
  return <Column
    alignItems="center"
    justifyContent="center"
    flex={!!center && 1}
    {...props}
  >
    <LoaderWheel size={size} />
  </Column>
}
Loader.defaultProps = {
  size: 16,
}
Loader.propTypes = {
  center: PropTypes.bool,
  noDelay: PropTypes.bool,
  size: PropTypes.number,
  height: PropTypes.string,
}

export default Loader
