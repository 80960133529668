import React from 'react'
import {Column} from 'components/layout/styled'
import PropTypes from 'prop-types'
import CheckBox from 'components/form/CheckBox'
import withInputWrapper from 'components/form/withInputWrapper'

const CheckList = ({
  input,
  meta,
  items,
  row,
  defaultValue,
  disabled,
  nowrap,
  noSelectAll,
  small,
  additionalOnChange,
  value,
  ...props
}) => {
  const itemsToUse = items.map(item => typeof item === 'string' ? {id: item, name: item} : item)
  const usedValues = (input?.value || value)
  const allSelected = usedValues?.length === itemsToUse.length

  const changeInput = (val) => {
    if (input?.onChange) {
      input.onChange(val)
    }
    if (additionalOnChange) {
      additionalOnChange(val)
    }
  }

  const handleClick = (clickedValue) => {
    if (Array.isArray(usedValues)) {
      if (!usedValues.includes(clickedValue)) {
        changeInput([...usedValues, clickedValue])
      } else {
        changeInput(usedValues.filter(e => e !== clickedValue))
      }
    } else {
      changeInput([clickedValue])
    }
  }

  const handleChangeAll = () => {
    if (allSelected) {
      changeInput([])
    } else {
      changeInput(itemsToUse.map(item => item.value || item.id))
    }
  }

  const checkItems = itemsToUse.map(item => <CheckBox
    key={item.value || item.id}
    small={small}
    py={10}
    pr={10}
    onClick={() => handleClick(item.value || item.id)}
    disabled={disabled}
    text={item.name}
    selected={Array.isArray(usedValues) && usedValues.includes(item.value || item.id)}
    nowrap={nowrap}
  />)

  const selectAll = !noSelectAll
    ? <CheckBox
      key="selectAll"
      small={small}
      py={10}
      pr={10}
      onClick={handleChangeAll}
      disabled={disabled}
      text="Select All"
      selected={allSelected}
      halfSelected={usedValues?.length > 0 && !allSelected}
      nowrap={nowrap}
      bold
    />
    : null

  return <Column {...props}>
    <Column
      flexDirection={row ? 'row' : 'column'}
      flexWrap={row ? 'wrap' : 'nowrap'}
    >
      {!noSelectAll && selectAll}
      {checkItems}
    </Column>
  </Column>
}

CheckList.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  items: PropTypes.array,
  row: PropTypes.bool,
  nowrap: PropTypes.bool,
  disabled: PropTypes.bool,
  noSelectAll: PropTypes.bool,
  defaultValue: PropTypes.string,
  small: PropTypes.bool,
  additionalOnChange: PropTypes.func,
  value: PropTypes.func,
}

CheckList.defaultProps = {
  additionalOnChange: () => {
  },
}

export default withInputWrapper((props) => (
  <CheckList {...props} />
))
