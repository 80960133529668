import styled from 'styled-components'
import {space} from 'styled-system'
import {Paragraph} from 'components/typography'

export default styled.table`
  border-collapse: collapse;
  max-width: 100%;
  th {
    text-align: left;
    white-space: nowrap;
    vertical-align: top;
    height: 30px;
  }
  td {
    box-sizing: border-box;
    padding: 10px 20px;
    vertical-align: top;
    &:nth-last-child(2) {
      white-space: nowrap;
      width: auto;
    }
    &:last-child {
      width: 100%;
    }
    &.clickable {
      cursor: pointer;
      &:hover {
        ${Paragraph} {
          font-weight: 500;
        }
      }
    }
  }
  td:first-child {
    padding: 10px 20px 10px 0px;
    width: auto;
  }
  ${space}
`
