import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Column} from 'components/layout/styled'
import ThreeDotsButtonWithDropdown from 'components/buttons/ThreeDotsButtonWithDropdown'

const StyledThreeDotsButtonWithDropdown = styled(ThreeDotsButtonWithDropdown)`
  position: absolute;
  right: 0;
  top: 0;
  height: ${props => props.open ? props.height : (props.hover ? 0 : props.height)}px;
  overflow: hidden;
  transition: height .15s ease;
  z-index: 100;
`

const Wrapper = styled(Column)`
  position: relative;
  &:hover {
    ${StyledThreeDotsButtonWithDropdown} {
      height: ${props => props.buttonHeight}px;
    }
  }
`

const WrapperWithOptions = React.forwardRef(({
  menuContent,
  children,
  hover,
  defaultPosition,
  buttonWidth,
  buttonHeight,
  preferredArrowPosition,
  buttonStyle,
  onMenuButtonClick,
  circle,
  ...props
}, ref) => {
  const [open, setOpen] = useState(false)
  return (
    <Wrapper buttonHeight={buttonHeight} ref={ref} {...props}>
      {children}
      {menuContent && <StyledThreeDotsButtonWithDropdown
        defaultPosition={defaultPosition}
        height={buttonHeight}
        width={buttonWidth}
        hover={hover}
        circle={circle}
        menuContent={menuContent}
        preferredArrowPosition={preferredArrowPosition}
        style={buttonStyle}
        onClick={onMenuButtonClick}
        open={open}
        setOpen={setOpen}
      />}
    </Wrapper>
  )
})

WrapperWithOptions.propTypes = {
  menuContent: PropTypes.any,
  children: PropTypes.any,
  buttonHeight: PropTypes.number,
  buttonWidth: PropTypes.number,
  hover: PropTypes.bool,
  defaultPosition: PropTypes.string,
  preferredArrowPosition: PropTypes.string,
  buttonStyle: PropTypes.object,
  onMenuButtonClick: PropTypes.func,
  circle: PropTypes.bool,
}

export default WrapperWithOptions
