import React from 'react'
import PropTypes from 'prop-types'
import styled, {keyframes} from 'styled-components'
import omit from 'lodash/omit'
import {Div, Flex} from 'components/layout/styled'

const fading = () => keyframes`
  0% {
    opacity: .1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .1;
  }
`

const LoadingChild = styled(Div)`
  user-select: none;
  pointer-events: none;
  color: transparent;
  background-color: ${props => props.theme.colors.grey6};
  border-radius: 4px;
  opacity: .1;
  animation: ${fading} 1s infinite;
  * {
    visibility: hidden;
  }
`

const LoadingPostAnimation = ({children: child, fill}) => {
  if (child.props.children && typeof child.props.children !== 'string') {
    const Wrapper = (props) => ({...child, props: {...omit(child.props, ['children']), ...props}})
    const children = Array.isArray(child.props.children) ? child.props.children : [child.props.children]
    return (<Wrapper>
      {children.map((child, i) => (<Flex fill={fill} key={i}>
        <LoadingChild mb={2} {...child.props} lineHeight={1} />
      </Flex>))}
    </Wrapper>)
  } else if (Array.isArray(child)) {
    return (
      <>
        {child.map((oneChild, i) => (<Flex fill={fill} key={i}>
          <LoadingChild mb={2} {...child.props} lineHeight={1} />
        </Flex>))}
      </>
    )
  } else {
    return <Flex fill={fill}>
      <LoadingChild mb={2} {...child.props} lineHeight={1} />
    </Flex>
  }
}

LoadingPostAnimation.propTypes = {
  children: PropTypes.element,
  fill: PropTypes.bool,
}

export default LoadingPostAnimation
