import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import Arrow from 'components/buttons/Arrow'
import {Div} from 'components/layout/styled'

const Button = styled(Div)`
  height: 30px;
  width: 30px;
  position: relative;
  border-radius: 50%;
  transition: all 100ms;
  cursor: pointer;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  :hover {
    background-color: ${props => props.theme.colors[props.color] || props.theme.colors.grey3};
    & > div {
      transform: translate(-50%, -50%) scale(1.2);
    }
    ${Arrow} {
      fill: ${props => props.theme.colors[props.arrowHoverColor] || props.theme.colors.arrowColor};
    }
  }
  ${props => props.hovered && css`
    background-color: ${props => props.theme.colors[props.color] || props.theme.colors.grey3};
    & > div {
      transform: translate(-50%, -50%) scale(1.2);
    }
    ${Arrow} {
      fill: ${props => props.theme.colors[props.arrowHoverColor] || props.theme.colors.arrowColor};
    }
  `}
  :active {
    & > div {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  ${Arrow} {
    display: block !important;
  }
`

const ArrowButton = ({onClick, color, hoverColor, size, direction, move, buttonColor, hovered, ...props}) => (
  <Button
    color={buttonColor}
    arrowColor={color}
    arrowHoverColor={hoverColor}
    hovered={hovered}
    onClick={onClick}
    {...props}
  >
    <div>
      <Arrow color={color} size={size} direction={direction} move={move} />
    </div>
  </Button>
)

ArrowButton.propTypes = {
  buttonColor: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
  move: PropTypes.string,
  hoverColor: PropTypes.string,
  hovered: PropTypes.bool,
}

ArrowButton.defaultProps = {
  buttonColor: 'grey3',
}

export default ArrowButton
