import React, {Suspense} from 'react'
import LoadingCircle from 'components/loaders/Loader'
import {Column} from 'components'

export default (Component, Loader) => {
  const MyComponent = React.lazy(Component)
  const fallback = Loader ? <Loader />
    : <Column height="100vh" alignItems="center" justifyContent="center">
      <LoadingCircle size={32} />
    </Column>
  return (props) =>
    <Suspense fallback={fallback}>
      <MyComponent {...props} />
    </Suspense>
}
