import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {flexbox, space, width} from 'styled-system'
import history from 'utils/history'
import {omitProps} from 'utils/props'
import {rgba} from 'utils/colors'

export const BlankButton = styled.button.attrs(({type = 'button'}) => ({type}))`
  padding: 0;
  border: none;
  line-height: 1;
  background: transparent;
  cursor: pointer;
  &:hover, &:focus, &:active {
    outline: none;
  }
`
const a = omitProps(['disabled', 'loading', 'loaderColor', 'spinColor', 'narrow', 'small', 'nativeClick', 'loadingTextColor'], 'a')
const button = omitProps(['disabled', 'loading', 'loaderColor', 'spinColor', 'narrow', 'small', 'loadingTextColor'], 'button')

const Button = styled('button')
  .attrs(props => ({
    as: props.href ? a : button,
    onClick: ((props.disabled && (() => null))
    || props.onClick
    || (props.href && ((e) => {
      if (!props.nativeClick) {
        e.preventDefault()
        history.push(props.href)
      }
    }))),
  }))`
  min-height: ${props => props.small ? 30 : 38}px;
  ${props => props.narrow && css`min-height: 34px;`};
  font-size: ${props => props.small ? 12 : 14}px;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  padding: 0px 20px;
  line-height: 1;
  align-items: center;
  box-sizing: border-box;
  transition: background-color .1s ease;
  text-align: center;
  :focus {
    outline: none;
  }
  :active {
    background-color: ${props => props.theme.colors.primaryLight};
  }
  justify-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  cursor: pointer;
  ${flexbox}
  ${space}
  ${width}
  ${props => props.disabled && css`
    background-color: ${props => props.theme.colors.grey5} !important;
    color: ${props => props.theme.colors.white} !important;
    font-weight: 700 !important;
    pointer-events: none !important;
    cursor: default !important;
    border: none !important;
  `}
  ${props => props.loading && css`
      position: relative;
      pointer-events: none !important;
      color: ${props => props.theme.colors[props.loadingTextColor]} !important;
      &:before {
        content: '';
        z-index: 10;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border-top: 1px solid ${props => props.theme.colors[props.loaderColor]};
        border-bottom: 1px solid ${props => props.theme.colors[props.loaderColor]};
        border-left: 1px solid ${props => props.theme.colors[props.loaderColor]};
        border-right: 1px solid ${props => props.theme.colors[props.spinColor]};
        background-color: transparent;
        animation-name: spinCenter;
        animation-duration: 600ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        @keyframes spinCenter {
          from {transform: translate3d(-50%,-50%,0) rotate(0deg);}
          to {transform: translate3d(-50%,-50%,0) rotate(360deg);}
        }
      }
  `}
`

class ButtonWrapper extends React.Component {
  state = {
    loading: false,
  }

  loadingTimeout

  componentDidUpdate(prevProps) {
    if (!prevProps.loading && this.props.loading) {
      this.setState({loading: true})
    }
    if (prevProps.loading && !this.props.loading) {
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = setTimeout(() => this.setState({loading: false}), 200)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout)
  }

  render() {
    const {loading, forwardedRef, ...props} = this.props
    return <Button ref={forwardedRef} loading={this.state.loading} {...props} />
  }
}

ButtonWrapper.propTypes = {
  loading: PropTypes.bool,
  forwardedRef: PropTypes.any,
}

const ButtonWrapperWithRef = React.forwardRef((props, ref) => <ButtonWrapper {...props} forwardedRef={ref} />)

export const PrimaryButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'primary', loaderColor: 'white', loadingTextColor: 'primary'}))`
  background-color: ${props => props.theme.colors.primary};
  font-weight: 700;
  color: ${props => props.theme.colors.white};
  transition: color .2s ease, border-color .2s ease, box-shadow .3s ease, background-color .2s ease;
  :hover {
    background-color: ${props => props.theme.colors.primaryDarker};
    box-shadow: 0 0 5px 0 ${props => rgba(props.theme.colors.primarySuperLight, 0.4)};
  }
  :active {
    box-shadow: none;
    background-color: ${props => props.theme.colors.primaryDarker};
  }
  :focus {
    box-shadow: none;
  }
`

export const RedButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'alertRed', loaderColor: 'white', loadingTextColor: 'alertRed'}))`
  background-color: ${props => props.theme.colors.alertRed};
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  :hover, :active {
    background-color: ${props => props.theme.colors.alertRedDarker};
  }
`

export const YellowButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'alertYellow', loaderColor: 'white', loadingTextColor: 'alertYellow'}))`
  background-color: ${props => props.theme.colors.alertYellow};
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  :hover, :active {
    background-color: ${props => props.theme.colors.alertYellowDarker};
  }
`

export const GreyButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'grey6', loaderColor: 'white', loadingTextColor: 'grey6'}))`
  background-color: ${props => props.theme.colors.grey6};
  color: ${props => props.theme.colors.white};
  transition: background-color .15s ease;
  font-weight: 700;
  :hover, :active { {
    background-color: ${props => props.theme.colors.grey7};
  }
`

export const WhiteButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'white', loaderColor: 'grey6', loadingTextColor: 'white'}))`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.grey6};
  color: ${props => props.theme.colors.grey8};
  transition: color .2s ease, border-color .2s ease, box-shadow .3s ease;
  :hover {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 8px 0 ${props => rgba(props.theme.colors.primary, 0.2)};
  }
  :active {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.primaryLight};
  }
  :focus {
    border: 2px solid ${props => props.theme.colors.primaryLight};
  }
`

export const WhiteBgButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'white', loaderColor: 'grey6', loadingTextColor: 'white'}))`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.grey8};
  :hover {
    border: 1px solid ${props => props.theme.colors.grey2};
    background-color: ${props => props.theme.colors.grey2};
  }
  :active {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.grey3};
    background-color: ${props => props.theme.colors.grey3};
  }
  :focus {
    padding-left: 19px;
    padding-right: 19px;
    border: 2px solid ${props => props.theme.colors.grey2};
    background-color: ${props => props.theme.colors.grey2};
  }
`

export const TransparentWhiteButton = styled(ButtonWrapperWithRef)
  .attrs(() => ({spinColor: 'white', loaderColor: 'grey6', loadingTextColor: 'white'}))`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  transition: color .2s ease, border-color .2s ease, box-shadow .3s ease;
  :hover {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 8px 0 ${props => rgba(props.theme.colors.primary, 0.2)};
  }
  :active {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
  :focus {
    padding-left: 19px;
    padding-right: 19px;
    border: 2px solid ${props => props.theme.colors.primaryLight};
    color: ${props => props.theme.colors.primary};
  }
`
