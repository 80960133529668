import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {shorten} from 'utils/formatters'

const Part = styled.span`
  &:before {
    display: inline;
    content: '${props => props.separator}';
  }
  &:first-of-type:before {
    content: '';
    display: none;
  }
`

const convertToString = (value, decimalDigits) => {
  if (typeof value === 'number') {
    return value.toFixed(decimalDigits)
  }
  if (typeof value === 'string') {
    return parseFloat(value).toFixed(decimalDigits)
  }
  throw new Error('Unknown type: ', typeof value)
}

export const trimNumber = (value, trim) => {
  if (trim === 'none' || !value.includes('.')) {
    return value
  }

  const trimmedValue = value.match(/^(.*?)\.?0*$/)[1]
  if (trim === 'max') {
    return trimmedValue
  }

  if (trimmedValue.includes('.')) {
    return value
  }

  return trimmedValue
}

export const NumberWithSpaces = ({value, decimalDigits, trim, short}) => {
  const separateThousands = (parts, separator) =>
    parts.map((p, i) => (
      <Fragment key={i}>
        <Part separator={separator}>{p}</Part>
      </Fragment>))

  let suffix
  if (short) {
    [value, suffix] = shorten(Number(value))
  }

  const tmpValue = convertToString(value, decimalDigits)
  const trimmedValue = trimNumber(tmpValue, trim)
  const parts = trimmedValue.split('.')

  if (parts.length === 1 && parts[0] === '-0') {
    return <span>0</span>
  }

  const formatSetting = {thousandSeparator: ' ', decimalSeparator: ','}
  return <>
    <span>
      {
        separateThousands(
          parts[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, 'x')
            .split('x'),
                formatSetting?.thousandSeparator,
        )
      }
    </span>
    {
      (parts.length > 1) && (
        <>
          <span>
            {formatSetting?.decimalSeparator}
          </span>
          <span>
            {parts[1]}
          </span>
        </>
      )
    }
    {
      suffix && (
        <span>
          {suffix}
        </span>
      )
    }
  </>
}

NumberWithSpaces.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  short: PropTypes.bool,
  decimalDigits: PropTypes.number,
  trim: PropTypes.oneOf(['max', 'none', 'allOrNone']).isRequired,
}

NumberWithSpaces.defaultProps = {
  decimalDigits: 2,
  trim: 'max',
}

export default NumberWithSpaces
