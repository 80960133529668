import {keyframes} from 'styled-components'

export const shakeAnimation = keyframes`
  8%, 41% {
    transform: translateX(-3px);
  }
  25%, 58% {
    transform: translateX(3px);
  }
  75% {
    transform: translateX(-1px);
  }
  92% {
    transform: translateX(1px);
  }
  0%, 100% {
    transform: translateX(0);
  }
`

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const hideAndShow = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
