import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import {Box, Column, Div, Flex} from 'components/layout/styled'
import {LinkText, Text} from 'components/typography'
import ArrowButton from 'components/buttons/ArrowButton'
import DateInput from 'components/form/datePicker/DateInput'
import withInputWrapper from 'components/form/withInputWrapper'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import {omitProps, spacePropsSeparation} from 'utils/props'
import isDescendant from 'utils/isDescendant'
import AbsolutePositionRenderer from 'components/layout/AbsolutePositionRenderer'
import {DATE_FORMAT_SLASH} from 'config/constants'

moment.locale('en')

const Wrapper = styled(Div)`
  position: relative;
`

const CalendarWrapper = styled(Box)`
  border-radius: 3px;
`

const HoverHighlight = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.colors.grey1};
  border-radius: 50%;
  position: absolute;
`

const DayCell = styled.td`
  position: relative;
  width: 40px;
  height: ${props => props.header ? 60 : 40}px;
  vertical-align: middle;
  user-select: none;
  cursor: ${props => (props.header || props.disabled) ? 'default' : 'pointer'};

  ${HoverHighlight} {
    height: 0px;
    width: 0px;
  }

  &:hover {
    ${HoverHighlight} {
      height: 30px;
      width: 30px;
    }
  }
`

const ControlsWrapper = styled(Flex)`
  position: relative;
  width: 100%;
`

const Highlight = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${props => props.chosen ? 40 : 0}px;
  width: ${props => props.chosen ? 40 : 0}px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  position: absolute;
  transition: all 100ms linear;
`

const AbsoluteText = styled(omitProps(['chosen', 'oneLine'], Text))`
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: all 50ms linear;
  line-height: 1;
  ${props => props.chosen && `color: ${props.theme.colors.white} !important;
  font-weight: 700 !important`}
`

const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

const ControlArrow = styled(ArrowButton).attrs(() => ({buttonColor: 'grey2', color: 'grey6', size: 'medium'}))`
  position: absolute;
  ${props => props.direction === 'left' ? 'left: 60px;' : 'right: 60px;'}
`

class DatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    onChange: PropTypes.func,
    meta: PropTypes.object,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.string,
    ]),
    value: PropTypes.string,
    tabIndex: PropTypes.number,
    isValid: PropTypes.bool,
    additionalOnChange: PropTypes.func,
    blockDateCheck: PropTypes.func,
  }

  state = {
    month: moment().startOf('month'),
    chosenDay: null,
    changed: [false, false, false],
    value: this.props.input?.value || this.props.value || '',
    focused: this.props.meta?.active || false,
    defaultValue: null,
  }

  input = {}
  inputComponent = {}
  wrapper = {}

  static getDerivedStateFromProps(nextProps, state) {
    if (state.defaultValue !== nextProps.value) {
      if (nextProps.value) {
        return {
          value: nextProps.value,
          defaultValue: nextProps.value,
        }
      }
    }
    if (nextProps.input) {
      return {
        value: nextProps.input.value,
        focused: nextProps.meta.active,
      }
    }
    return {}
  }

  componentDidMount() {
    if (this.state.value && this.state.value.length === 10) {
      if (this.props.error) return
      this.chooseDay(
        moment(this.state.value, DATE_FORMAT_SLASH).startOf('day'),
        !this.state.changed.includes(false),
      )
    }
  }

  watchClicks = (event) => {
    if (!isDescendant(this.wrapper, event.target) && !isDescendant(this.input, event.target)) {
      this.props.input
        ? this.props.input.onBlur()
        : this.setState({focused: false})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.focused && !prevState.focused) {
      this.setState({changed: [false, false, false]})
    }
    if (prevState.value !== this.state.value && this.state.value?.length === 10) {
      if (this.props.error) return
      if (!this.props.input && this.props.onChange) this.props.onChange(this.state.value)
      this.chooseDay(
        moment(this.state.value, 'DD/MM/YYYY').startOf('day'),
        !this.state.changed.includes(false),
      )
    }
    if (prevState.value && this.state.value === '//') {
      if (this.props.input) {
        this.props.input.onChange(null)
      } else {
        this.setState({value: null})
      }
    }
    if (prevState.value && !this.state.value) {
      if (!this.props.input && this.props.onChange) this.props.onChange(null)
      this.setState({chosenDay: null})
    }
    if (prevState.focused !== this.state.focused) {
      if (this.state.focused) {
        setTimeout(() => window.addEventListener('click', this.watchClicks), 30)
      } else {
        setTimeout(() => window.removeEventListener('click', this.watchClicks), 30)
      }
    }

    if ((prevState.chosenDay && !this.state.chosenDay)
      || (!prevState.chosenDay && this.state.chosenDay)
      || ((prevState.chosenDay && this.state.chosenDay) && prevState.chosenDay.diff(this.state.chosenDay) !== 0)) {
      this.props.input
        ? this.props.input.onChange(this.state.chosenDay?.format('DD/MM/YYYY') || null)
        : this.setState({value: this.state.chosenDay?.format('DD/MM/YYYY') || null})
      if (this.props.additionalOnChange) {
        this.props.additionalOnChange(this.state.chosenDay?.format('DD/MM/YYYY') || null)
      }
    }
  }

  nextMonth = () => {
    this.setState({month: this.state.month.clone().add(1, 'M')})
  }

  prevMonth = () => {
    this.setState({month: this.state.month.clone().subtract(1, 'M')})
  }

  nextYear = () => {
    this.setState({month: this.state.month.clone().add(1, 'Y')})
  }

  prevYear = () => {
    this.setState({month: this.state.month.clone().subtract(1, 'Y')})
  }

  closeCalendar = (delay = 0) => {
    setTimeout(this.props.input?.onBlur || (() => this.setState({focused: false})), delay)
  }

  chooseDay = (day, close = false) => {
    if (this.props.blockDateCheck?.(day) || !day.isValid()) {
      if (typeof this.inputComponent?.triggerError === 'function') {
        this.inputComponent.triggerError()
      }
      this.setState({changed: [false, false, false]})
      this.props.input
        ? this.props.input.onChange(this.state.chosenDay?.format('DD/MM/YYYY') || null)
        : this.setState({value: this.state.chosenDay?.format('DD/MM/YYYY') || null})
      if (this.props.additionalOnChange) {
        this.props.additionalOnChange(this.state.chosenDay?.format('DD/MM/YYYY') || null)
      }
      return
    }
    if (day.clone().startOf('month').diff(this.state.month, 'minutes')) {
      this.setState({month: day.clone().startOf('month')})
      setTimeout(() => this.setState({chosenDay: day.clone().startOf('day')}), 100)
      close && this.closeCalendar(100)
    } else {
      this.setState({chosenDay: day.clone().startOf('day')})
      close && this.closeCalendar(200)
    }
  }

  setToday = () => {
    this.chooseDay(moment(), true)
  }

  thisMonth = (day) => day.month() === this.state.month.month()

  getWeeks = (momentObj) => {
    const monthMoment = momentObj.clone().startOf('month')
    const daysToDisplay = momentObj.daysInMonth() + (monthMoment.day() + 6) % 7
    monthMoment.startOf('isoWeek')
    return new Array(Math.ceil(daysToDisplay / 7)).fill(0).map((_, week) => week + monthMoment.week())
  }

  selectDay = (event) => {
    const target = event.currentTarget
    if (!target.dataset?.day) return
    const day = moment(target.dataset.day, 'D/MM/YYYY')
    if (this.thisMonth(day) && !this.props.blockDateCheck?.(day)) {
      this.chooseDay(day, true)
    }
  }

  static RenderDays = React.memo(() =>
    <thead>
      <tr>
        {days.map(day => (
          <DayCell key={day} header>
            <Text fontWeight={700} textAlign="center">{day}</Text>
          </DayCell>
        ))}
      </tr>
    </thead>)

  static ShowArrows = React.memo(({prev, next}) =>
    <>
      <ControlArrow direction="left" onClick={prev} />
      <ControlArrow direction="right" onClick={next} />
    </>)

  static ShowYearControls = ({nextYear, prevYear, year}) =>
    <ControlsWrapper alignItems="center" justifyContent="center">
      <Text pt={2} lineHeight={1} fontSize={36} color="grey6" fontWeight={900} style={{userSelect: 'none'}}>
        {year}
      </Text>
      <DatePicker.ShowArrows prev={prevYear} next={nextYear} />
    </ControlsWrapper>

  static ShowMonthControls = ({prevMonth, nextMonth, month}) =>
    <ControlsWrapper alignItems="center" mt={15} justifyContent="center">
      <Text pt={2} lineHeight={1} fontSize={20} color="grey6" style={{userSelect: 'none'}}>
        {month.format('MMMM')}
      </Text>
      <DatePicker.ShowArrows prev={prevMonth} next={nextMonth} />
    </ControlsWrapper>

  static ShowControls = React.memo(({month, prevMonth, nextMonth, prevYear, nextYear}) =>
    <Column mb={30} fill alignItems="center">
      <DatePicker.ShowYearControls
        nextYear={nextYear}
        prevYear={prevYear}
        year={month.format('YYYY')}
      />
      <DatePicker.ShowMonthControls
        nextMonth={nextMonth}
        prevMonth={prevMonth}
        month={month}
      />
    </Column>)

  static ShowDay = React.memo(({date, chosenDay, selectDay, day, thisMonth, blocked}) =>
    <DayCell
      onClick={selectDay}
      data-day={date}
      disabled={!thisMonth || blocked}
    >
      <HoverHighlight />
      <Highlight
        chosen={chosenDay}
      />
      <AbsoluteText
        chosen={chosenDay}
        oneLine
        color={(thisMonth && !blocked) ? 'grey7' : 'grey5'}
      >
        {day}
      </AbsoluteText>
    </DayCell>,
  )

  static ShowCalendar = React.memo(({calendar, thisMonth, chosenDay, blockDateCheck, ...props}) =>
    calendar.map(week =>
      <tr key={week.week}>
        {week.days.map(day => {
          const date = `${day.day}/${day.month}/${day.year}`
          const blocked = blockDateCheck?.(moment(date, 'D/MM/YYYY'))
          return <DatePicker.ShowDay
            key={date}
            date={date}
            thisMonth={day.month === thisMonth}
            day={day.day}
            chosenDay={chosenDay === date}
            blocked={blocked}
            {...props} />
        })}
      </tr>,
    ))

  render() {
    const begin = this.state.month.clone().startOf('isoWeek')
    const calendar = this.getWeeks(this.state.month).map((week) => ({
      week,
      days: Array(7).fill(0).map(() => {
        const date = {
          month: begin.format('MM'),
          day: begin.format('D'),
          year: begin.format('YYYY'),
        }
        begin.add(1, 'day')
        return date
      }),
    }))
    const {spaceProps, otherProps} = spacePropsSeparation(this.props)
    const chosenDay = this.state?.chosenDay?.format('D/MM/YYYY')
    return (
      <Wrapper {...spaceProps}>
        <AbsoluteCalendar
          calendar={calendar}
          chosenDay={chosenDay}
          month={this.state.month}
          nextMonth={this.nextMonth}
          nextYear={this.nextYear}
          prevMonth={this.prevMonth}
          prevYear={this.prevYear}
          selectDay={this.selectDay}
          thisMonth={this.state.month?.format('MM')}
          setToday={this.setToday}
          blockDateCheck={this.props.blockDateCheck}
          wrapperRef={node => {
            this.wrapper = node
          }}
          target={({targetRef, ...props}) => {
            if (this.props.alternativeTarget) {
              const AlternativeTarget = this.props.alternativeTarget
              return (<AlternativeTarget
                forwardRef={node => {
                  this.input = node
                  targetRef(node)
                }}
                {...otherProps}
                {...props}
                onMount={(component) => { this.inputComponent = component }}
                changedArray={this.state.changed}
                closeCalendar={this.closeCalendar}
                value={this.state.value}
                onFocus={this.props.input ? this.props.input.onFocus : () => this.setState({focused: true})}
                onBlur={this.props.input ? this.props.input.onBlur : () => this.setState({focused: false})}
                onChange={this.props.input ? this.props.input.onChange : (value) => this.setState({value})}
                focused={this.state.focused}
              />)
            } else {
              return (<DateInput
                forwardRef={node => {
                  this.input = node
                  targetRef(node)
                }}
                {...otherProps}
                {...props}
                onMount={(component) => { this.inputComponent = component }}
                changedArray={this.state.changed}
                closeCalendar={this.closeCalendar}
                value={this.state.value}
                onFocus={this.props.input ? this.props.input.onFocus : () => this.setState({focused: true})}
                onChange={this.props.input ? this.props.input.onChange : (value) => this.setState({value})}
                focused={this.state.focused}
              />)
            }
          }}
        />
      </Wrapper>
    )
  }
}

const Calendar = ({
  calendar,
  chosenDay,
  month,
  nextMonth,
  nextYear,
  prevMonth,
  prevYear,
  selectDay,
  thisMonth,
  setToday,
  wrapperRef,
  blockDateCheck,
}) =>
  <CalendarWrapper
    ref={wrapperRef}
    width={350}
    pt={40}
    pb={25}
    px={0}
    m={0}
  >
    <DatePicker.ShowControls
      month={month}
      nextMonth={nextMonth}
      nextYear={nextYear}
      prevMonth={prevMonth}
      prevYear={prevYear}
    />
    <Column alignItems="center">
      <Flex mb={20} justifyContent="center" height={320}>
        <table>
          <DatePicker.RenderDays />
          <tbody>
            <ErrorBoundary>
              <DatePicker.ShowCalendar
                calendar={calendar}
                selectDay={selectDay}
                chosenDay={chosenDay}
                thisMonth={thisMonth}
                blockDateCheck={blockDateCheck}
              />
            </ErrorBoundary>
          </tbody>
        </table>
      </Flex>
      <LinkText
        onClick={setToday}
        fontSize={10}
        color="alertBlue"
      >
        Set Today's Date
      </LinkText>
    </Column>
  </CalendarWrapper>

Calendar.propTypes = {
  calendar: PropTypes.array.isRequired,
  chosenDay: PropTypes.string,
  month: PropTypes.object.isRequired,
  nextMonth: PropTypes.func.isRequired,
  nextYear: PropTypes.func.isRequired,
  prevMonth: PropTypes.func.isRequired,
  prevYear: PropTypes.func.isRequired,
  selectDay: PropTypes.func.isRequired,
  thisMonth: PropTypes.string.isRequired,
  setToday: PropTypes.func.isRequired,
  wrapperRef: PropTypes.any,
  blockDateCheck: PropTypes.func,
}

const AbsoluteCalendar = AbsolutePositionRenderer(Calendar)

DatePicker.ShowCalendar.propTypes = {
  calendar: PropTypes.array,
  thisMonth: PropTypes.string,
  chosenDay: PropTypes.string,
  alternativeTarget: PropTypes.any,
  blockDateCheck: PropTypes.func,
}

DatePicker.ShowArrows.propTypes = {
  prev: PropTypes.func,
  next: PropTypes.func,
}

DatePicker.ShowControls.propTypes = {
  month: PropTypes.object,
  prevMonth: PropTypes.func,
  nextMonth: PropTypes.func,
  prevYear: PropTypes.func,
  nextYear: PropTypes.func,
}

DatePicker.ShowDay.propTypes = {
  date: PropTypes.string.isRequired,
  chosenDay: PropTypes.bool,
  selectDay: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  thisMonth: PropTypes.bool,
  blocked: PropTypes.bool,
}

export default withInputWrapper(DatePicker)
