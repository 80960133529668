import React from 'react'
import {Field} from 'react-final-form'
import moment from 'moment'
import PropTypes from 'prop-types'
import DatePicker from 'components/form/datePicker/DatePicker'
import {DATE_FORMAT_SLASH} from 'config/constants'

const DatePickerField = ({name, dontValidate, ...props}) => {
  return <Field
    validate={(value) =>
      !dontValidate
      && (value?.length > 3 && (!moment.utc(value, DATE_FORMAT_SLASH).isValid() || value?.length !== 10))
      && {[name]: "This date isn't valid."}}
    datePickerField
    component={DatePicker}
    name={name}
    {...props}
  />
}
DatePickerField.propTypes = {
  name: PropTypes.string,
  dontValidate: PropTypes.bool,
}

export default DatePickerField
