import styled from 'styled-components'

export default styled.div`
  background-color: ${props => props.theme.colors.grey3};
  font-size: 10px;
  color: ${props => props.theme.colors.primary};
  padding: 3px 10px 2px;
  margin: 2px;
  border-radius: 50px;
`
